.input {
  display: flex;
}

.input input {
  width: 200px;
  margin-right: 12px;
}

.error {
  font-size: 0.9em;
  opacity: 0.5;
  font-style: italic;
}
