body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
img {
  border: none;
}
.mosaic-top-header {
  background-color: #fff;
}
html.fa-events-icons-failed .fa {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #a9a9a9;
}
body {
  padding: 0;
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  text-rendering: optimizeLegibility;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: subpixel-antialiased;
}
body.sidebar-opened {
  overflow: hidden;
}
text {
  font-family: 'Source Sans Pro', sans-serif !important;
}
button,
.ui-widget {
  font-family: 'Source Sans Pro', sans-serif;
}
button.far:before,
button.fas:before,
button.fal:before {
  font-family: 'Font Awesome 5 Pro';
}
.editor-theme-actions button {
  border: 2px solid #bbb;
}
g.subtitle text,
g.tick text {
  font-weight: 400;
}
#mainwrapper.loading {
  pointer-events: none;
  opacity: 0.8;
  background-color: #fff;
}
body.haspopup:before {
  content: ' ';
  position: fixed;
  width: 100%;
  height: 100%;
  background: #555;
  opacity: 0.75;
  z-index: 90;
  top: 0;
}
#dashboardwrapper {
  width: 1140px;
  margin: auto;
  min-height: 600px;
  position: relative;
}
#dashboardwrapper.transparent-background {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 3px;
  padding: 30px;
  margin-top: 25px;
  width: 1200px;
}
body.swing-stories #dashboardwrapper.transparent-background.sidebar {
  padding: 0;
}
#dashboardwrapper.sidebar {
  width: 1420px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.grid-section {
  display: flex;
  width: 100%;
}
body.swing-stories #dashboardheader,
body.swing-stories .mosaic-top-header {
  position: relative;
  z-index: 100;
}
body.swing-stories #dashboardwrapper.sidebar {
  width: 1140px;
}
.wrapper-sidebar.fixed {
  height: 100%;
}
#dashboardwrapper.sidebar .wrapper-sidebar:not(.fixed):not(.totop) {
  margin-top: 0;
  overflow: hidden;
  margin-top: 9px;
}
#dashboardwrapper.sidebar .sidebar-content {
  width: 260px;
  padding: 15px;
  margin-right: 20px;
  font-size: 15px;
  transition: width 0.1s ease-in-out;
  overflow-y: auto;
}
#dashboardwrapper.sidebar .wrapper-sidebar .sidebar-content {
  height: 100%;
}
#dashboardwrapper.sidebar .sidebar-content .js-themelist {
  margin-bottom: 0;
}
#dashboardwrapper.sidebar .wrapper-sidebar.expanded {
  width: 260px;
  position: absolute;
}
#dashboardwrapper.sidebar .wrapper-sidebar.expanded .sidebar-content {
  display: block;
}
#dashboardwrapper.sidebar .wrapper-main {
  width: 1140px;
}
body.swing-stories #dashboardwrapper.sidebar .wrapper-main {
  width: 860px;
}
#dashboardwrapper.sidebar .dashboard-header {
  margin-left: 280px;
}
#dashboardwrapper .wrapper-sidebar .sidebar-menu-bar {
  padding-top: 0;
  padding-right: 20px;
  width: 30px;
  height: 30px;
  transition: width 0.1s ease-in-out;
}
#dashboardwrapper .wrapper-sidebar.expanded .sidebar-menu-bar {
  width: 260px;
}
#dashboardwrapper .wrapper-sidebar .sidebar-menu-button {
  padding: 0;
  width: 30px;
  height: 30px;
  color: #fff;
}
.wrapper-sidebar .sidebar-icons {
  margin-top: 10px;
}
.wrapper-sidebar .themelist {
  margin-left: 24px;
  margin-top: 0;
}
.wrapper-sidebar .subtheme-list {
  margin-left: 20px;
  padding-left: 0;
  display: none;
}
.wrapper-sidebar .subtheme-list.-expanded {
  display: block;
}
.wrapper-sidebar .theme-link {
  padding: 5px 0;
  list-style: none;
  font-weight: 400;
}
.wrapper-sidebar .theme-link.home {
  margin-bottom: 5px;
}
.wrapper-sidebar .theme-link a {
  text-decoration: none;
  color: inherit;
}
.wrapper-sidebar .theme-link.selected-link > a,
a.headerinview {
  font-weight: 600;
  letter-spacing: -0.43px;
}
.grid-section:after {
  content: '';
  display: table;
  clear: both;
}
.fileloader {
  z-index: 90;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  padding-top: 6px;
  margin: -30px 0 0 -30px;
  opacity: 1;
}
.fileloader.detailview {
  z-index: 305;
}
body .fileloader {
  opacity: 1;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#dashboardviews {
  position: relative;
}
#dashboardView {
  padding-top: 9px;
}
#dashboardView-search {
  max-width: 600px;
  left: 0;
  right: 0;
  margin: auto;
  height: 60px;
  position: relative;
  z-index: 72;
}
body.noscroll #dashboardView-search {
  z-index: 65;
}
.mosaic-header.fixed {
  position: absolute;
  width: 100%;
  z-index: 90;
}
.mosaic-header.relative {
  position: relative;
  z-index: 90;
}
.mosaic-header,
.mosaic-header a:not(.sel-nav) {
  color: #343434;
}
.dashboard-header {
  margin-top: 20px;
}
.dashboard-header,
.dashboard-header a,
.dashboard-header button {
  color: inherit;
}
#seltool .dropdown-themelist {
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  z-index: 999;
  left: 0;
  height: 100%;
  min-width: 220px;
  max-width: 420px;
}
.dropdown-themelist-wrapper {
  position: absolute;
  top: 44px;
  min-width: 220px;
  max-width: 420px;
  box-sizing: border-box;
  overflow: hidden;
  box-shadow: 0 15px 20px -7px #70716c;
  margin-right: 10px;
  background: #fff;
  z-index: 999;
}
.dropdown-themelist-wrapper .mosaic-scroll-container {
  position: static;
  position: initial;
}
.dropdown-themelist .dropdown-subtheme {
  margin: 0;
  line-height: 30px;
  width: 25px;
  padding: 6px 5px;
  vertical-align: middle;
  position: absolute;
  right: 0;
}
.crumb-item .crumb-item-wrapper {
  border-bottom: solid thin #f0f0f0;
  display: inline-block;
  padding: 0 10px;
  margin: 0;
  width: 100%;
}
.dropdown-themelist .crumb-item {
  border-top: none;
  padding: 0;
}
.subtheme-dropdown-list .crumb-item {
  font-size: 15px;
  background: #fff;
  overflow: hidden;
}
li.crumb-item {
  position: relative;
}
.subtheme-dropdown-list {
  display: none;
  background: #fff;
  box-sizing: border-box;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}
.subtheme-dropdown-list.expanded {
  display: block;
}
.subtheme-dropdown-list.expandedsize {
  display: block;
  height: 0;
}
.subtheme-dropdown-list span.crumb-item-wrapper {
  padding-left: 20px;
}
.o-innerCell {
  vertical-align: middle;
  padding-left: 0;
}
.o-innerCell.sidebar-icons button {
  color: inherit;
}
.sidebar-region .o-innerCell {
  padding: 5px 0;
}
.sidebar-region .o-innerCell .button {
  min-width: 0;
  min-width: initial;
  font-size: 13px;
}
.theme-navigation-information {
  padding: 10px;
}
.theme-navigation-information p {
  margin: 0;
}
button.themenavbutton,
button.footernavbutton {
  cursor: pointer;
  position: absolute;
  z-index: 10;
  font-size: 40px;
  margin: 20px;
}
button.footernavbutton {
  font-size: 36px;
  background-color: transparent;
  border: none;
  color: #fff;
}
button.themenavbutton i {
  vertical-align: middle;
  border-radius: 50%;
  color: #fff;
  border-width: 2px;
  border-style: solid;
}
button.themenavbutton.dark i {
  color: #dcdcdc;
}
button.themenavbutton span {
  font-size: 20px;
  vertical-align: middle;
  padding: 0 10px;
}
.navbutton {
  display: table-cell;
  font-size: 20px;
  font-weight: normal;
  color: inherit;
}
.dashboard-header .theme-icon-wrapper {
  display: none;
  width: 46px;
  height: 46px;
  position: relative;
}
.dashboard-header .theme-icon {
  display: block;
  vertical-align: middle;
}
.dashboard-header .theme-icon img {
  max-height: 46px;
  display: inline;
  vertical-align: middle;
}
.navbutton {
  width: 46px;
  position: relative;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 46px;
}
.mosaic-search button.clean {
  line-height: 45px;
  padding: 0;
  margin: 0;
  width: 45px;
}
@-webkit-keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
.wrapper-sidebar .dashboard-utils,
.wrapper-sidebar .social-utils,
.wrapper-sidebar .display-utils {
  width: 40%;
  display: inline;
  margin: 4px;
}
.wrapper-sidebar .mosaic-utils {
  margin: 4px;
}
.dashboard-utils,
.social-utils,
.mosaic-utils,
.user-menu,
.display-utils {
  padding: 0;
  overflow: visible;
  vertical-align: top;
  position: relative;
}
.dashboard-utils button,
button.themenavbutton,
.social-utils button,
.mosaic-utils button {
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  color: inherit;
  width: 44px;
  line-height: 44px;
  padding: 0;
}
.mosaic-utils button.mosaic-utils-menu {
  width: auto;
  width: initial;
}
#mosaicUtils__items {
  height: 42px;
  line-height: 40px;
  width: 46px;
}
#dashboardUtils__items button,
.social-Utils__items a,
#mosaicUtils__items button,
.user-menu-items a {
  padding-left: 10px;
  line-height: inherit;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  margin: 0;
  color: inherit;
}
.social-Utils__items a {
  padding-left: 0;
}
#dashboardUtils__items,
.social-Utils__items,
#mosaicUtils__items,
.user-menu-items {
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  top: 40px;
  right: 0;
  margin: 0;
  margin-top: 6px;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 999;
  min-width: 200px;
  background: #fff;
  display: none;
  border: 1px solid #eee;
  border-top: none;
  box-shadow: 0 0 20px -7px #70716c;
}
div.sidebar-region #dashboardUtils__items,
div.sidebar-region .social-Utils__items,
div.sidebar-region #mosaicUtils__items,
div.sidebar-region .user-menu-items {
  left: 0;
}
#dashboardUtils__items.utilsexpanded,
.social-Utils__items.utilsexpanded,
#mosaicUtils__items.utilsexpanded,
.user-menu-items.user-menu-expanded {
  display: block;
}
.social-Utils__items .dashboardUtils__item {
  padding-left: 10px;
}
.utils-label {
  font-size: 15px;
  white-space: nowrap;
  padding-left: 10px;
  display: inline-block;
  width: 84%;
  text-decoration: none;
  color: #373737;
}
.utils-label.expire-warning {
  padding-left: 0;
  cursor: default;
}
.dashboardUtils__item,
.user-menu-item {
  line-height: 42px;
  border-top: 1px solid #888;
  text-align: left;
  cursor: pointer;
  padding-left: 10px;
  color: inherit;
  padding-left: 0;
  border-top: 1px solid #eee;
}
.dashboardUtils__item:hover,
.user-menu-item:hover {
  background: #eee;
}
.dashboardUtils__item button {
  padding-left: 0;
}
.dashboardUtils__item.warning {
  background: #ff0;
}
#changeIndicator.content {
  background: #737272;
}
ul#tileboard {
  list-style: none;
  margin: 0;
  padding: 0;
}
.editor-tile-actions,
.myTheme-tile-actions,
.tile-actions,
.tile-actions-inverse {
  opacity: 1;
  position: absolute;
  top: 10px;
  z-index: 10;
}
.tile-actions-inverse {
  color: transparent;
  font-size: 20px;
  line-height: 45px;
}
.tile-actions-inverse .is-mymosaic {
  color: #ffc600;
}
.tile-actions button,
.editor-tile-actions button,
.tile-actions a,
.myTheme-tile-actions button {
  opacity: 0;
}
.tile-actions button:focus,
.editor-tile-actions button:focus,
.myTheme-tile-actions button:focus,
body.touch .tile-actions button,
body.touch .editor-tile-actions button,
body.touch .myTheme-tile-actions button {
  opacity: 1;
}
div.tile-detail-wrapper tile-action-btn i,
div.tile-detail-wrapper tile-action-btn span {
  width: 1.28571429em;
  text-align: center;
}
section.hover > .editor-tile-actions button,
section.hover > .tile-actions button,
section.hover > .tile-actions a,
section:hover > .myTheme-tile-actions button,
body.touch > .editor-tile-actions button,
body.touch > .tile-actions button,
body.touch > .tile-actions a,
body.touch > .myTheme-tile-actions button,
.myTheme-tile-actions:hover button,
.tile-actions:hover button,
.tile-actions:hover a {
  opacity: 1;
}
.myTheme-tile-actions {
  width: 100%;
}
.editor-tile-actions.right .tile-action-btn:hover {
  background-color: #fff;
}
.myTheme-tile-actions .tile-action-btn {
  width: 40px;
  font-size: 18px;
}
button.clean {
  background-color: transparent;
  border: 0 none;
  cursor: pointer;
  margin: 0 0 10px 0;
  padding: 0;
  overflow: visible;
}
button.noshadow {
  box-shadow: none !important;
}
.tile-actions .tile-action-btn.clean span.in-mymosaic i,
.tile-action .tile-action-btn.clean span.in-mymosaic i {
  color: #ffc600;
}
.editor-tile-actions.right {
  right: 10px;
}
.tile-actions.right,
.myTheme-tile-actions,
.tile-actions-inverse {
  right: 0;
  width: 44px;
}
.tile-actions.right button,
.myTheme-tile-actions button.right,
.tile-actions.left button {
  font-size: 20px !important;
  width: 44px;
  height: 44px;
  margin-bottom: 0;
}
.tile-actions.right a {
  display: inline-flex;
  font-size: 18px !important;
  width: 44px;
  height: 44px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.tile-actions.right a:focus {
  outline: 2px solid #000;
  opacity: 1;
}
body.touch .tile-actions button,
body.touch .editor-tile-actions button,
body.touch .myTheme-tile-actions button.right,
body.touch .tile-actions a,
body.touch .editor-tile-actions a,
body.touch .myTheme-tile-actions a.right {
  margin: 0;
  height: 40px;
  font-size: 20px !important;
}
.editor-tile-actions.left,
.myTheme-tile-actions button.left,
.tile-actions.left {
  left: 10px;
}
section.tile.width1 + section.tile.width1 .editor-tile-actions.left {
  left: 20px;
}
section.tile.width1 .editor-tile-actions.right {
  right: 20px;
}
section.tile.width1 + section.tile.width1 .editor-tile-actions.right {
  right: 10px;
}
.tile-placeholder {
  height: 80px;
  width: 100%;
  background-color: #ccc;
  box-sizing: border-box;
  margin-bottom: 3px;
  float: left;
  border-bottom: 2px solid #fff;
}
.colLeft .tile-placeholder {
  border-right: 2px solid #fff;
}
.colRight .tile-placeholder {
  border-left: 2px solid #fff;
}
.o-adds {
  font-size: 0.8em;
  clear: both;
  padding: 0 10px;
  text-align: center;
}
.o-add {
  display: inline-block;
  padding: 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #3a3a3a;
}
.o-add a {
  text-decoration: none;
  color: #3a3a3a;
  font-weight: bold;
}
.o-add a:hover {
  text-decoration: underline;
}
.popup-dialog {
  position: fixed;
  z-index: 120;
}
.popup-wrapper {
  padding: 10px 20px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fff;
  color: #000;
}
.popup-dialog.popup-dialog--success .popup-wrapper {
  border-left: 15px solid #57a701;
}
.popup-dialog.popup-dialog--warning .popup-wrapper {
  border-left: 15px solid #cc9600;
}
.popup-dialog.popup-info--warning .popup-wrapper {
  border-left: 15px solid #1d7ad1;
}
.popup-header,
.popup-footer {
  width: 100%;
  text-align: right;
}
.popup-body-wrapper {
  width: 100%;
  display: flex;
}
.popup-icon {
  width: 10%;
  font-size: 2.5em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-dialog.popup-dialog--warning .popup-icon {
  color: #cc9600;
}
.popup-dialog.popup-dialog--success .popup-icon,
.copy-link__success {
  color: #448301;
}
.popup-dialog.popup-dialog--info .popup-icon {
  color: #1d7ad1;
}
.popup-dialog.popup-dialog--loading .popup-icon {
  color: #000;
}
.popup-body {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  padding: 5px;
}
.popup-body-header {
  width: 100%;
  margin: 5px 0;
  text-transform: uppercase;
}
.popup-message {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
  line-height: 1.4em;
}
.popup-dialog .popup-close-btn {
  padding: 5px 10px;
  border-radius: 5px;
}
.popup-dialog.warning .popup-close-btn {
  background: #cc9600;
}
body.screenshot .tile__rawdata,
body.screenshot .user-info,
body.screenshot .themenavbutton,
body.screenshot #seltool,
body.screenshot #container_menu,
body.screenshot .navbutton,
body.screenshot #utils-more-info span,
body.screenshot #presentation__meta,
body.screenshot #preview-config-bar,
body.screenshot #breadcrumb-container:not(.no-crumb),
body.screenshot .extrainfo,
body.screenshot .wrapper-sidebar,
body.screenshot .add-text-my-mosaic,
body.screenshot .lnkhome,
body.screenshot #searchArea,
body.screenshot .iframe_link {
  display: none !important;
}
body.screenshot .grid-section {
  display: table;
}
body.screenshot .dashblock.colRight,
body.screenshot .dashblock.colLeft,
body.screenshot .dashblock.colFull.single {
  display: block;
  float: left;
}
body.screenshot section.tile {
  page-break-inside: avoid;
  display: block;
  margin-bottom: 20px;
}
body.screenshot section.tile.width1 {
  width: 50%;
  display: inline-block;
}
body.screenshot section.tile.width1:nth-child(even) {
  padding-left: 10px;
}
body.screenshot section.tile.width1:nth-child(odd) {
  padding-right: 10px;
}
body.screenshot section.tile.height1 {
  height: 80px;
}
body.screenshot section.tile.height2 {
  height: 180px;
}
body.screenshot section.tile.height3 {
  height: 280px;
}
body.screenshot section.tile.height4 {
  height: 380px;
}
body.screenshot section.tile.height5 {
  height: 480px;
}
body.screenshot section.tile.height6 {
  height: 580px;
}
body.screenshot section.tile.height7 {
  height: 680px;
}
body.screenshot section.tile.height8 {
  height: 780px;
}
body.screenshot section.tile.height9 {
  height: 880px;
}
body.screenshot section.tile.height10 {
  height: 980px;
}
body.screenshot section.tile.height11 {
  height: 1080px;
}
body.screenshot section.tile.height12 {
  height: 1180px;
}
body.screenshot section.tile.height13 {
  height: 1280px;
}
body.screenshot section.tile.height14 {
  height: 1380px;
}
body.screenshot section.tile.height15 {
  height: 1480px;
}
body.screenshot section.tile.height16 {
  height: 1580px;
}
body.screenshot a.inner-tile,
body.screenshot div.inner-tile,
body.screenshot div.free-tile,
body.screenshot a.free-tile {
  float: left;
  page-break-inside: avoid;
}
body.screenshot.swing-stories section.tile.free.split,
body.screenshot.swing-stories section.tile.free.split div.free-tile {
  page-break-inside: auto;
  page-break-inside: initial;
}
body.screenshot.swing-stories .dashboard-header {
  margin-top: 0;
}
body.screenshot .u-pb-a {
  clear: both;
  position: relative;
  page-break-before: always;
  float: none;
  float: initial;
}
body.screenshot section.tile div.content {
  width: 99%;
  margin-left: 1px;
}
body.screenshot section.tile.Infographics div.content {
  bottom: 0;
  position: relative;
}
body.screenshot section.tile .wrapper {
  width: 99%;
  margin-left: 1px;
}
body.screenshot section.tile.chart.width1 div.content {
  width: 188px;
}
body.screenshot section.tile.chart.width2 div.content {
  width: 480px;
}
body.screenshot div.colFull section.tile.chart.width2 div.content {
  width: 1060px;
}
body.screenshot div.colFull section.tile.chart.width1 div.content {
  width: 480px;
}
body.screenshot section.tile .tile-actions-inverse .is-mymosaic {
  color: transparent;
}
body.screenshot #blocker,
body.screenshot #cookie-consent-control-root {
  display: none !important;
}
body.screenshot .tooltip-term {
  text-decoration: none;
}
.info pre {
  white-space: pre-line;
}
body.kbe button:focus {
  border: 2px solid #000;
}
button:focus {
  outline: 0 none;
}
.my-mosaic-dialog {
  position: absolute;
  font-size: 16px;
  padding: 4px;
  background-color: #737373;
  z-index: 300;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 1);
}
.my-mosaic-dialog ul {
  list-style-type: none;
  list-style: none;
  list-style-position: outside;
  margin: 0;
  padding: 0 5px 0 0;
}
.my-mosaic-dialog ul li {
  line-height: 1.5;
  padding: 5px;
  border-top: 1px #888 solid;
}
.my-mosaic-dialog ul li:first-child {
  border-top: 0;
  border-top: initial;
}
.my-mosaic-dialog ul li:hover {
}
.my-mosaic-dialog ul li a,
.my-mosaic-dialog ul li span {
  text-decoration: none;
  color: #fff;
}
.my-mosaic-dialog ul li a i {
  margin-right: 10px;
}
div.my-mosaic-dialog .ui-state-default,
div.my-mosaic-dialog .ui-widget-content .ui-state-default {
  background: #fff;
  color: #333;
  font-size: 12px;
}
.ui-widget[role='dialog'] {
  box-sizing: border-box;
}
.db-skiplink {
  background-color: #fff;
  border: 2px solid #999;
  display: block;
  left: -1000em;
  padding: 0.5em;
  position: absolute;
  z-index: 10;
  text-decoration: none;
  color: #000;
}
.db-skiplink:focus {
  left: 1em;
  top: 1em;
  z-index: 1000;
}
#preview-config-bar {
  height: 70px;
}
#preview-config-bar.studio {
  background-color: #e7c3c3;
}
#preview-config-bar.viewer {
  background-color: #c1f3cd;
}
#preview-config-bar #preview-config-bar-inner {
  max-width: 1140px;
  margin: 0 auto;
}
#preview-config-bar span.date {
  font-size: 0.6em;
}
.sub-section div {
  float: left;
  margin-right: 10px;
}
#preview-config-bar span.date {
  line-height: 15px;
}
#preview-config-bar #studio-publish-section,
#preview-config-bar #studio-publish-section .sub-section,
#preview-config-bar #viewer-publish-section .sub-section {
  float: left;
}
#preview-config-bar #viewer-publish-section {
  float: right;
}
#preview-config-bar #viewer-publish-section,
#studio-publish-section .sub-section .labels-viewer {
  display: none;
}
#preview-config-bar .sub-section {
  padding: 0 5px;
}
#preview-config-bar .preview-configbar-button {
  margin-top: 5px;
  padding: 5px;
  font-size: 11px;
}
#preview-config-bar .preview-configbar-button i {
  padding-right: 5px;
}
#preview-config-bar .preview-configbar-button .green {
  color: green;
}
#preview-config-bar.studio .preview-configbar-button .green {
  display: none;
}
#preview-config-bar.viewer .preview-configbar-button .red {
  display: none;
}
#preview-config-bar .preview-configbar-button .red {
  color: red;
}
#preview-config-bar .preview-configbar-button {
  border-radius: 3px;
}
#preview-config-bar .preview-configbar-button i {
  border-radius: 3px;
}
#preview-config-bar .preview-configbar-button.blue {
  color: #fff;
  border-color: #3379b6;
  background-color: #285f8f;
}
#preview-config-bar .preview-configbar-button.red {
  color: #fff;
  background-color: #c44848;
  border-color: #c16a57;
}
.ui-dialog[role='dialog'] {
  position: fixed !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}
.ui-dialog .ui-dialog-titlebar,
.addressSearchDialog h1 {
  font-size: 20px;
  background: transparent;
  padding: 0;
  border: none;
}
.ui-dialog .ui-dialog-title {
  color: #000;
  display: block;
  width: 100%;
  margin: 0;
}
.ui-widget[role='dialog'] {
  width: 500px;
  max-width: 100vw;
  padding: 36px;
  box-sizing: border-box;
  border-radius: 6px;
  border: transparent;
}
.ui-dialog.confirmdialog .ui-dialog-buttonpane {
  padding: 0;
}
.ui-dialog .ui-dialog-content {
  padding: 10px 0;
  margin: 10px 0;
}
.ui-dialog p {
  margin: 0;
}
#confirmdialog .cfm-dlg-content,
#confirmdialog .cfm-dlg-explanation,
#progressbar .perc,
#progressbar .ui-widget,
#progressFinishedMessage {
  font-family: inherit;
  font-size: 16px;
}
#progressbar .ui-progressbar-value {
  background: #005a96;
  color: #fff;
  height: 10px;
}
#progressbar {
  height: 10px;
  text-indent: -99999px;
  border-radius: 0;
  border: none;
  background: #f9f9f9;
}
.confirmdialog .ui-dialog-titlebar {
  padding: 10px 0;
}
.ui-dialog.confirmdialog .ui-dialog-buttonpane {
  padding: 10px;
  border: none;
}
.confirmdialog button.ui-button.ui-button-text-only,
.progressdialog button.ui-button.ui-button-text-only {
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 13px !important;
  width: auto;
  height: auto;
}
.confirmdialog .ui-button-text {
  padding: 3px 10px;
}
.no-close .ui-dialog-titlebar-close {
  display: none;
}
#progressbar {
  text-align: center;
  height: 10px;
}
#progressbar .ui-progressbar-value {
  background-color: #005a96;
  border-radius: 0;
  margin: -22px 0 0 0;
  height: 10px;
  border: none;
}
#open-live-text {
  display: none;
  font-size: 14px;
}
#breadcrumb-container,
.dashboard-icon-wrapper {
  height: 46px;
  font-size: 18px;
}
.dashboard-icon-wrapper {
  float: right;
}
#breadcrumb-container {
  display: inline-block;
}
#breadcrumb-container button.navbutton {
  border: none;
  width: 46px;
  display: inline-block;
}
div.breadcrumb_util {
  position: relative;
  display: inline-block;
  line-height: 46px;
  vertical-align: middle;
}
.crumb-trail {
  overflow: hidden;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  top: 40px;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 999;
  min-width: 200px;
  background: #fff;
  display: none;
  border: 1px solid #eee;
  border-top: none;
  box-shadow: 0 0 20px -7px #70716c;
}
.crumb-trail:hover {
  display: block;
}
.crumbtrail-menu-btn {
  background: none;
  border: none;
  margin: 0;
  font-size: 18px;
}
.crumbtrail-menu-btn:hover {
  opacity: 0.25;
}
.crumb-item {
  font-family: 'Source Sans Pro', sans-serif;
  list-style-type: none;
  border-top: 1px solid #eee;
  white-space: nowrap;
  padding: 0 10px;
  line-height: 30px;
  font-size: 15px;
  text-align: left;
}
.crumb-item a {
  color: #373737;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 6px 3px;
}
.crumb-item a > span {
  overflow: hidden;
  display: inline-block;
  width: 100%;
  text-overflow: ellipsis;
  vertical-align: middle;
  pointer-events: none;
}
.crumb-item.has-subthemes > .crumb-item-wrapper {
  padding-right: 25px;
}
crumb-item.has-subthemes .crumb-item-wrapper {
  padding-right: 0;
}
.trail-link {
  text-decoration: none;
  vertical-align: middle;
}
.trail-link span {
  margin: 0 5px;
}
.trail-link:hover {
  text-decoration: underline;
}
.trail-link.no-link {
  text-decoration: none;
  cursor: default;
}
.trail-title {
  font-size: inherit;
  margin: inherit;
  display: inherit;
  font-weight: inherit;
  vertical-align: middle;
}
div.no-crumb {
  line-height: 41px;
}
div.no-crumb .trail-title {
  font-size: 32px;
  font-weight: 300;
}
.theme-trail {
  position: relative;
  text-align: center;
  vertical-align: middle;
}
.crumb-item.selected > .crumb-item-wrapper a {
  font-weight: bold;
}
.breadcrumb-seperator {
  display: inline-block;
  vertical-align: bottom;
  padding-bottom: 9px;
}
.breadcrumb-seperator i {
  padding: 1px 6px;
}
.mobile-breadcrumb {
  text-decoration: none;
  margin-left: 12px;
}
.mobile-breadcrumb i {
  vertical-align: middle;
}
.mobile-breadcrumb .trail-title {
  padding-left: 5px;
}
.chart-tooltip {
  min-width: 100px;
  max-width: 250px;
  padding: 15px !important;
}
.chart-tooltip p {
  margin: 0;
}
.tt-title,
.tt-descr {
  font-weight: normal;
  font-size: 11px;
  line-height: 11px;
}
div.chart-tooltip.theme-dark {
  background-color: #2d2d2d;
  color: #fff;
  border: 1px solid #3c3c3c;
}
div.chart-tooltip.theme-light {
  background-color: #fff;
  color: #2d2d2d;
  border: 1px solid #f0f0f0;
}
g.radar.line {
  stroke-opacity: 1 !important;
}
#shareEmbeddedTileDialog > .area-container {
  padding: 0.4em 1em;
}
#shareEmbeddedTileDialog > .area-container > #snippetArea {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  height: 200px;
}
.ui-dialog.share-embedded-dialog {
  font-size: 12px;
}
.ui-dialog.share-embedded-dialog .dialog-title {
  border-bottom: 1px solid #aaa;
}
.ui-dialog.share-embedded-dialog .dialog-title > h2 {
  padding: 10px;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 16px;
  display: inline-block;
  color: #005a96;
}
.ui-dialog.share-embedded-dialog .dialog-title button.js-close-dlg {
  position: absolute;
  right: 0;
  margin-top: 11px;
  margin-right: 9px;
  font-size: 16px;
}
.ui-dialog.share-embedded-dialog .area-container {
  padding: 10px;
}
.ui-dialog.share-embedded-dialog .area-container button.js-copy-link {
  float: right;
}
.ui-dialog #snippetArea {
  width: 100%;
  height: 130px;
  resize: none;
}
.ui-dialog.share-embedded-dialog .button-wrapper {
  border-top: 1px solid #aaa;
}
.ui-dialog.share-embedded-dialog .button-container {
  margin: 5px;
}
.ui-dialog.share-embedded-dialog .button-wrapper .dialog-button {
  padding: 3px;
  border: 1px solid #aaa;
  border-radius: 4px;
  font-size: 13px !important;
  cursor: pointer;
}
.choose_indicator > #indicator-list {
  width: 100%;
}
.search-area {
  font-size: 18px;
  position: absolute;
  z-index: 5;
  width: 100%;
  left: 0;
}
.sidebar-content .search-area {
  position: relative;
}
.search-area.search-area-expanded {
  display: block;
  height: 100%;
  margin-top: -10px;
  z-index: 90;
}
#dashboardwrapper.sidebar .search-area.search-area-expanded {
  position: absolute;
  margin-top: -50px;
  height: auto;
  height: initial;
}
#dashboardwrapper.sidebar .search-area.search-area-expanded .ac-s-container {
  margin-top: -1px;
  height: 40px;
}
#searchArea.search-area.search-area-expanded .ac-s-input-container {
  border: 2px solid #e9e9e9;
}
#dashboardwrapper.sidebar .search-area.search-area-expanded .ac-s-input-container i {
  top: 13px;
}
.search-area .search-wrapper {
  width: 100%;
  max-width: 1140px;
  margin: auto;
  position: relative;
}
.search-area .search-bar {
  display: inline-block;
  position: absolute;
  width: 90%;
  max-width: 600px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 7px;
  background-color: transparent;
  background-color: initial;
  color: #000;
  color: initial;
}
#dashboardSearchCloseButton {
  width: 100%;
  height: 100%;
  margin: 0;
  line-height: 70px;
  padding-left: 20px;
}
.search-area .search-bar .ac-s-container > label {
  background-color: transparent !important;
  top: 1px !important;
  color: #000;
}
.sidebar-content .search-area .search-bar {
  width: 100%;
  margin-bottom: 20px;
}
.ui-dialog input,
.ui-dialog textarea {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 5px;
  border: 1px solid #ddd;
  font-size: 16px;
  margin-bottom: 1em;
}
.input-btn-combo {
  position: relative;
}
.copy-link__text,
div.myThemesEditDialog button.img-btn,
#mytheme-name,
#mytheme-description {
  font-size: 16px;
  font-family: 'Source Sans Pro', sans-serif;
  background: #fff;
}
.copy-link__text,
div.myThemesEditDialog button.img-btn {
  position: relative;
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  padding: 5px;
  border: 1px solid #ddd;
  text-decoration: underline;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height: 30px;
}
button.copy-btn.copy-link__button,
#mytheme-image .far {
  cursor: pointer;
  padding: 5px;
  background-color: #fff;
  color: #005a96;
  font-size: 14px;
  border: none;
  box-sizing: border-box;
  min-width: 30px;
}
.search-area .search-bar.autocomplete-active .searchInput {
  border-bottom-left-radius: 0;
}
.search-area .search-bar.autocomplete-active .search-btn {
  border-bottom-right-radius: 0;
}
.search-area .searchInput {
  width: 90%;
  background: #fff;
  color: #646464;
  border: 0 none;
  box-sizing: border-box;
  height: 30px;
  padding-left: 10px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
div.wrapper-sidebar .search-area .searchInput {
  height: 45px;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
.sidebar-content .search-area .searchInput {
  width: 80%;
}
.search-area .searchInput:focus {
  outline: none;
}
.search-area .search-btn {
  background: #fff;
  border: none;
  box-sizing: border-box;
  line-height: 30px;
  height: 30px;
  padding: 0 10px;
  margin: 0;
  margin-left: -4px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
div.wrapper-sidebar .search-area .search-btn {
  height: 45px;
  width: 20%;
}
.searchAutoComplete {
  width: 95%;
  margin-top: -3px;
  left: 0;
  position: absolute;
  border: 1px solid #cad2d5;
  border-top: none;
  z-index: 1000;
  background-color: #fff;
  box-sizing: border-box;
  text-align: left;
  font: 400 13.3333px Arial;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.searchAutoComplete > .suggestion {
  margin: 0;
  padding: 10px;
  text-decoration: none;
  color: #646464;
  display: block;
}
.searchAutoComplete > .suggestion.-hover {
  background-color: #eee;
}
.blocker {
  width: 100%;
  position: fixed;
  background-color: #000;
  opacity: 0.5;
  z-index: 60;
  top: 0;
  bottom: 0;
}
.blocker.st {
  z-index: 70;
}
.blocker.dv,
.blocker.default,
.blocker.filedownload {
  z-index: 90;
}
.blocker.popup {
  z-index: 100;
}
.blocker.autocomplete {
  z-index: 71;
}
.image-preview-list {
  list-style: none;
  margin-top: 0;
  margin-bottom: 10px;
}
.image-preview-list button.imgSelector {
  cursor: pointer;
  padding: 5px 0 5px 0;
  width: 33%;
  border: none;
  background: none;
  float: left;
  text-align: left;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: 'Source Sans Pro';
}
.image-preview-list button.imgSelector.checked {
  background-color: #999;
}
.btn_imagePicker img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-color: #999;
}
.btn_imagePicker span {
  font-weight: normal !important;
}
.imgSelector img {
  margin-right: 5px;
  width: 25px;
  height: 25px;
  vertical-align: middle;
  background-color: #999;
}
.imgSelector p {
  float: left;
  white-space: nowrap;
}
.imgSelector p.no_image {
  float: left;
  margin-left: 55px;
}
.imgSelector:hover,
.imgSelector.checked:hover {
  background-color: #ccc;
}
.imgSelector.checked p {
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: left center;
}
.dashboardUtils__item {
  color: #373737;
}
.dashboardUtils__item .utils-icon {
  color: inherit;
}
.mosaic-header .mosaic-name {
  text-align: center;
  font-size: 45px;
  padding-bottom: 5px;
}
.current-theme .themename-wrapper {
  position: relative;
  display: inline-block;
  padding: 0 45px;
}
.current-theme .themename {
  font-size: 40px;
  font-weight: 300;
  margin: 0;
}
.current-theme .theme-information-icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -23px;
}
.dashboard-icon-wrapper i {
  font-size: 18px;
  width: 20px;
}
.theme-compare-information h2 {
  margin: 5px;
  font-size: 1.3em;
}
.user-info {
  max-width: 1140px;
  width: 100%;
  margin: auto;
  box-sizing: border-box;
  color: #0067ab;
  text-decoration: none;
  font-size: 18px;
}
.user-menu {
  display: inline-block;
  position: relative;
}
.user-icon {
  padding-right: 5px;
}
.btn-login:hover {
  text-decoration: underline;
}
.user-menu-items {
  min-width: 130px;
  top: 20px;
}
#user-menu-btn {
  padding-bottom: 6px;
}
.user-menu-item {
  list-style: none;
  color: #0067ab;
}
.user-menu-item i {
  width: 18px;
}
.user-menu-item .logout {
  padding-left: 15px;
}
.user-menu-item span {
  color: inherit;
}
.theme-color {
  color: #0067ab;
}
.theme-color-background {
  background: #0067ab;
}
.theme-color-border {
  background: #0067ab;
}
#nextTheme a {
  text-decoration: none;
}
#nextTheme a:hover {
  text-decoration: underline;
}
.scroll-up div.mosaic-header {
  top: 0 !important;
  transition: 0.5s;
  position: fixed;
  width: 100%;
  z-index: 80;
}
.scroll-up.sidenav.navfixed:not(.collapsible) div.wrapper-sidebar,
div.wrapper-sidebar.fixed {
  top: 0 !important;
  position: fixed;
}
.scroll-up.sidenav.navfixed:not(.collapsible) div#dashboardviews,
.scroll-down.sidenav.navfixed:not(.collapsible) div#dashboardviews {
  margin-left: 280px;
}
.scroll-up .search-area-expanded {
  margin-top: -11px;
  height: 69px;
}
.no-searchresult-wrapper {
  margin: 0 5px;
}
.search-list,
.search-list {
  margin: 0;
  list-style-type: none;
}
.search-list > li {
  text-indent: -5px;
}
.search-list > li:before {
  content: '-';
  padding-right: 5px;
}
.search-list a {
  text-decoration: none;
}
#main-container {
  width: 600px;
  height: 600px;
  padding: 20px;
  margin: 0 auto;
  background-color: #fff;
}
#main-container.container {
  background-color: transparent;
}
div.map-copyright {
  position: absolute;
  font-size: 11px;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
div.map-copyright:not(.selection) {
  right: 0;
}
div.map-copyright a {
  color: inherit;
}
.compareselection-ref-container {
  display: flex;
  align-items: center;
}
.sidebar-content .compareselection-ref-container #compareSelText {
  margin-top: 0;
}
.noreference-toggle-container {
  display: none;
}
.noreference-toggle-container.noreference-toggle-container--visible {
  display: flex;
}
#dashboardwrapper.sidebar .noreference-toggle-container {
  margin: 10px 0;
}
.mosaic-header .noreference-toggle-container {
  margin-right: 10px;
  height: 45px;
}
#dashboardwrapper.sidebar .noreference-toggle-container {
  justify-content: center;
}
.mosaic-header .noreference-toggle-container .b__toggle-switch {
  align-self: center;
}
/* @font-face {
  font-family: SwingIcons;
  src: url(../fonts/swing_icons.woff) format('woff');
} */
.detailview-tab {
  display: none;
  padding: 15px;
}
.detailview-tab.-active,
.detailview-chart-tab.-active {
  display: block;
}
.more-info-content .info-header {
  font-weight: bolder;
  font-size: inherit;
  margin-block: 0;
}
.more-info-content a {
  color: #000;
}
.more-info-content p {
  margin: 0;
}
.more-info-content .detailview-description {
  margin: 1em 0;
  display: block;
}
.more-info-content .detailview-description > p {
  white-space: pre-line;
}
.more-info-content .description-indicators,
.more-info-content .footnote-indicators {
  font-style: italic;
}
.more-info-content .description,
.more-info-content .footnote {
  display: block;
}
.detailview-dialog {
  position: fixed;
  left: 50%;
  z-index: 300;
  background-color: #fff;
  margin-top: -380px;
  margin-left: -580px;
  width: 1125px;
  height: 760px;
}
.detailview-dialog .detailview-tabs {
  height: 0;
}
.detailview-dialog .close-btn {
  padding: 7px 7px 19px 27px;
  margin: 0;
  position: absolute;
  right: 0;
  z-index: 2;
}
.detailview-dialog .tab-body {
  background: transparent;
  padding: 18px;
}
.detailview-dialog .tab-body .side-description-tab {
  float: right;
  margin-right: 18px;
}
.detailview-dialog .tab-body .description-content {
  margin-left: 25px;
  background: #e6e6e6;
  padding: 18px;
  display: none;
  overflow-y: auto;
  height: 712px;
  box-sizing: border-box;
}
.detailview-dialog .detailview-tab {
  padding: 0;
  height: 724px;
}
.detailview-dialog .detailview-tab.description-tab {
  overflow-y: auto;
  padding: 18px 0;
}
.detailview-dialog .detailview-tab.description-tab .description-indicators {
  font-style: italic;
}
.detailview-dialog.expanded .tab-body .description-content {
  display: block;
}
.detailview-dialog.expanded:not(.xl) .tab-body .description-wrapper {
  background: #fff;
  position: absolute;
  top: 0;
  right: 0;
  width: 360px;
  box-shadow: -17px 1px 22px -10px rgba(230, 230, 230, 1);
  padding-right: 20px;
  bottom: 0;
  padding-top: 28px;
}
.detailview-dialog iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.detailview-dialog .sidebar-background {
  position: absolute;
  left: 0;
  width: 114px;
  height: 100%;
  background: #e6e6e6;
  z-index: -1;
}
.detailview-dialog.xl .tab-body {
  border: none;
  width: 1126px;
  display: flex;
}
.detailview-dialog.xl .tab-body .data-tab {
  flex-grow: 5;
  width: 66%;
}
.detailview-dialog.xl .tab-body .side-description-tab {
  flex-grow: 1;
  margin-right: 0;
  display: block;
  max-width: 0;
}
.detailview-dialog.xl .tab-body .description-content {
  position: static;
  position: initial;
}
.detailview-dialog.xl.expanded {
  width: 1518px;
}
.detailview-dialog.xl.expanded .tab-body {
  width: 1518px;
}
.detailview-dialog.xl.expanded .description-wrapper {
  padding-top: 6px;
}
.detailview-dialog.xl.expanded .side-description-tab {
  max-width: initial;
  width: 25%;
  padding-right: 4px;
  max-width: none;
}
.detailview-dialog.xl.expanded .description-content {
  display: block;
  height: 712px;
}
.detailview-dialog .extra-info {
  display: block;
  width: 200px;
  line-height: 16px;
  /* background-image: url(images/tab-white-mirror.svg); */
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: bottom center;
  border: 0 none;
  color: #000;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 50%;
  right: -93px;
  transform: rotate(270deg);
  margin-top: -50px;
  z-index: 2;
  padding: 6px 0;
}
.detailview-dialog .extra-info.hidden {
  display: none;
}
.detailview-dialog .extra-info span {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  color: #666;
}
.detailview-dialog.expanded .extra-info {
  right: 261px;
}
.detailview-dialog.xl.expanded .extra-info {
  right: 301px;
}
.detailview-dialog .extra-info i {
  line-height: 16px;
  color: #666;
}
.mosaic-header {
  padding-top: 10px;
  position: relative;
  z-index: 72;
  min-height: 55px;
}
#mainwrapper.themeview .mosaic-header {
  z-index: 70;
}
body.scroll-up .mosaic-header {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.3);
}
.mosaic-header #seltool,
.dialogContainer {
  max-width: 1200px;
  margin: auto;
  display: block;
  padding-left: 30px;
  padding-right: 30px;
}
.dialogContainer {
  height: 100%;
  overflow: hidden;
}
.mosaic-header #seltool,
.mosaic-header #seldialog,
.wrapper-sidebar,
#dashboardwrapper.sidebar #seldialog {
  z-index: 80;
}
.mosaic-header #seldialog,
#dashboardwrapper.sidebar #seldialog {
  width: 100%;
  position: absolute;
  background-color: #fff;
}
.mosaic-header #seldialog {
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  box-shadow: -1px 13px 16px 0 rgba(0, 0, 0, 0.15);
}
.searchresulttheme .selection.selcontainer {
  width: 100%;
}
.selcontainer.compareselection {
  position: relative;
  transition: opacity 125ms;
  -webkit-transition: opacity 125ms;
  -moz-transition: opacity 125ms;
  -ms-transition: opacity 125ms;
  -o-transition: opacity 125ms;
}
.selcontainer.compareselection #compareSel {
  opacity: 0.2;
}
.selcontainer.compareselection.compareselection--active #compareSelText,
.selcontainer.compareselection.compareselection--active #compareSel {
  opacity: 1;
}
.selection-buttons {
  display: flex;
  flex-wrap: wrap;
}
.selcontainer {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
#compareSelText {
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 45px;
}
.sidebar-content #compareSelText {
  margin-top: -10px;
}
.sel-button,
.theme-button,
.close-button,
.close-mobile-button {
  width: 220px;
  height: 45px;
  margin-right: 10px;
  padding: 0;
  cursor: pointer;
}
.close-button {
  margin-right: 0;
}
.sidebar-content .sel-button {
  width: 230px;
  margin-right: 0;
}
.sel-button,
.theme-button {
  margin-bottom: 10px;
}
.sel-button.selected {
  border-width: 2px;
}
.selection .theme-button.expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.col-header {
  color: #646464;
  text-align: left;
  text-transform: uppercase;
  font-size: 12px;
}
.sel-content {
  text-align: left;
  font-size: 18px;
  padding: 10px;
  line-height: 21px;
}
.sel-content span {
  width: 88%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sel-content i {
  line-height: 24px;
}
.sel-header {
  line-height: 50px;
  height: 50px;
  margin: auto;
}
#seldialog.reference .sel-header {
  height: 100px;
}
.sel-footer {
  height: 65px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.dim-ref-types {
  line-height: normal;
  padding-bottom: 15px;
}
button.ref-sel {
  margin: 0;
  margin-right: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}
button.ref-sel.selected {
  font-weight: 700;
}
.reference-msg {
  margin: auto;
  font-style: italic;
  font-size: 12px;
}
.sel-selection {
  position: relative;
  overflow-y: hidden;
  transition: left 1s ease-in-out;
  left: 0;
  width: 300%;
}
.sel-header-title,
.sel-header-controls {
  display: inline-block;
  margin: 0;
  width: 50%;
  font-size: 1.17em;
}
.sel-viewtype {
  display: inline-block;
  margin-top: 10px;
  margin-left: -75px;
}
.sel-viewtype button {
  padding: 5px;
  width: 75px;
  outline: none;
  border: none;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #dcdcdc;
}
.sel-viewtype button.selected {
  font-weight: bold;
}
.sel-viewtype button[data-type='map']:not(.selected) {
  border-left: none;
}
.sel-viewtype button[data-type='list']:not(.selected) {
  border-right: none;
}
.sel-header-controls {
  float: right;
  position: relative;
  height: 100%;
}
.sel-header-controls .close-button {
  background-color: transparent;
  border: none;
  font-size: 20px;
  width: 45px;
  right: 0;
  position: absolute;
  margin-right: -15px;
}
.sel-footer .close-button {
  float: right;
  border: none;
}
.sel-list {
  width: 285px;
  display: inline-block;
  overflow: hidden;
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid #dcdcdc;
  vertical-align: top;
  box-sizing: border-box;
}
#dashboardwrapper.sidebar div.sel-list {
  width: 275px;
}
.sel-map {
  display: inline-block;
  height: 100%;
  width: 555px;
  position: relative;
}
.sel-list.usergeogroup-level,
.sel-list.usergeogroup-items,
div.sel-selection.uggsel.uggedit.mapview .sel-map {
  height: calc(100% - 65px);
}
.sel-map #mapContainer {
  height: 100%;
}
.scrollable {
  padding: 1px;
}
.sel-list-content {
  width: 100%;
  height: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}
.addressResults .sel-list-content {
  padding-right: 0;
}
.sel-list:not(.level) {
  padding-left: 20px;
}
.sel-selection.no-level .sel-list {
  padding-left: 0;
}
.sel-list-header {
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 10px;
}
.sel-list-header h3 {
  font-weight: inherit;
  font-size: 12px;
  margin-block: 0;
}
.sel-listitem {
  display: block;
  width: 100%;
  background-color: #fff;
  border: none;
  text-align: left;
  padding: 6px 5px 3px 0;
  cursor: pointer;
  color: inherit;
}
.sel-listitem button {
  margin: 0;
  padding: 0;
}
.sel-listitem button.item-text {
  max-width: 95%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em;
  border: none;
  background: none;
  cursor: pointer;
}
.sel-listitem button.extra-info {
  border: none;
  background: none;
  cursor: pointer;
}
.sel-listitem button.item-text.extra1 {
  max-width: calc(95% - 20px);
}
.sel-listitem button.item-text.extra2 {
  max-width: calc(95% - 40px);
}
.sel-listitem.selected,
.sel-listitem.selected button {
  font-weight: 700;
}
.sel-listitem i:not(.extra-info) {
  float: right;
  line-height: 20px;
  font-size: 20px;
}
.sel-listitem i.extra-info {
  margin-left: 3px;
  font-size: 15px;
}
.sel-listitem.ugg {
  width: 70%;
  display: inline-block;
}
.sel-listitem button.search-match {
  font-weight: 700;
}
.sel-listitem button.search-match span.match {
  font-weight: normal;
}
.sel-listitem a {
  text-decoration: none;
}
.subtheme-list .sel-list-header {
  margin-bottom: 0;
}
.subtheme-list .sel-list-header.subthemeheader {
  margin-top: 10px;
}
.addresslist button {
  padding: 5px 20px 5px 20px;
  font-size: 14px;
  border-bottom: 1px solid #f5f5f5;
}
.map-tooltip {
  line-height: 1;
  font-weight: bold;
  padding: 4px;
  background-color: #000;
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  border-radius: 2px;
  border: 1px solid #999;
  font-size: 12px;
  position: absolute;
  z-index: 999999;
}
button.edit-ugg,
button.delete-ugg {
  vertical-align: bottom;
  float: right;
  line-height: 25px;
  margin: 0;
  width: 25px;
  border-radius: 4px !important;
}
button.delete-ugg {
  background: #d3d3d3;
  color: #343434;
  margin-left: 5px;
}
.usergeogroup-form-input {
  height: 55px;
  background: #d3d3d3;
  padding: 10px 10px 5px 10px;
  width: 550px;
  position: absolute;
}
#dashboardwrapper.sidebar .usergeogroup-form-input {
  width: 530px;
}
button.save-ugg {
  cursor: pointer;
  width: 110px;
  height: 45px;
  border: 1px solid #dcdcdc;
  float: right;
}
input.ugg-name {
  padding: 5px;
  border: 1px solid #949494;
  width: 65%;
  height: 33px;
  float: left;
}
label.ugg-label {
  float: left;
  line-height: 45px;
  padding-right: 5px;
}
input.ugg-name.error {
  border-color: red;
  border-width: 2px;
}
.sel-list::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
}
.sel-list::-webkit-scrollbar-track {
  background-color: rgba(168, 168, 168, 0.6);
  border-radius: 8px;
}
.sel-list::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(57, 57, 57, 0.6);
}
.searchlabel {
  position: absolute;
  top: 18px;
  left: 8px;
  font-size: 12px;
  transition: all 0.5s ease;
}
.searchlabel.active {
  top: 6px;
  left: 3px;
  font-size: 11px;
  font-weight: bold;
}
.searchinput {
  height: 40px;
  padding: 3px;
  border-radius: 4px;
  margin: 4px 0 10px 0;
  width: 100%;
  border: 1px solid #949494;
  font-size: 14px;
}
.navigation-controls a {
  font-size: 32px;
  width: 45px;
  display: inline-block;
  border: 1px solid #dcdcdc;
  text-align: center;
  margin-right: 5px;
}
.navigation-controls a i {
  padding: 5px;
}
.navigation-controls a.disabled {
  color: #dcdcdc;
  pointer-events: none;
}
.selection .navbutton.mosaic-search {
  margin-right: -12px;
}
.selection .navbutton.disabled {
  cursor: auto;
  cursor: initial;
}
.selection button:disabled {
  pointer-events: none;
  opacity: 0.2;
}
.searchWrapper {
  height: 46px;
  line-height: 46px;
  margin: 0 20px;
}
.addressResults {
  height: calc(100% - 170px);
  overflow-y: auto;
}
.addressButtonWrapper {
  width: 100%;
}
.addressSearchBtn {
  padding: 4px 8px 4px 8px;
  border: 1px solid #dcdcdc;
  position: relative;
  font-size: 15px !important;
  float: right;
  background-color: #fff;
  opacity: 1;
  bottom: 30px;
  cursor: pointer;
}
input.addressSearch {
  border: none;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAIVBMVEUAAAD///+ZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmRAyOfAAAACnRSTlMAAA8vP3+fr8/vVgteEAAAAGxJREFUeF7N0CEKgEAUBNBhwb4KBk1Gz+AFBJPR7BU0GTduMpsERdc5pc3djxfY3x58hmGQyovCOtmeHvicWdIFzjm37L2LG1hW7/IAuj3wCTTev/+ck8jTlqSB6DO4yhuAskYY9SWtxhj2fAE+PC/UtRguVwAAAABJRU5ErkJggg==)
    no-repeat #f9f9f9;
  padding: 2px 4px 1px 31px;
  background-position: -4px center;
  background-repeat: no-repeat;
  line-height: 24px;
  height: 30px;
  width: 100%;
  box-sizing: border-box;
}
.searchWrapper.address .navbutton button.clean {
  font-size: 13px;
}
.addressBlocker {
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
}
.addressSearchDialog {
  width: 80%;
  height: 80%;
  margin-left: 10%;
  position: absolute;
  top: 10%;
  background-color: #fff;
  z-index: 2;
  border: none;
  border-radius: 6px;
}
.addressSearchDialog h1 {
  margin: 20px 20px 10px 20px;
}
.addressSearchDialog .navbutton {
  position: absolute;
  right: 0;
  top: 8px;
}
.addressSearchDialog #areaSearchCloseButton {
  color: #000;
}
ul.geoitemlist {
  list-style: none;
  margin: 0;
  background: #fff;
  padding: 5px 0 5px 5px;
  position: relative;
  font-size: 14px;
}
ul.geoitemlist li {
  margin: 0;
  cursor: pointer;
  background: #fff;
  padding: 5px 5px 5px 0;
  border: 1px solid transparent;
}
ul.geoitemlist label {
  cursor: pointer;
}
ul.geoitemlist input:focus + label {
  background-color: #d1ecff;
}
ul.geoitemlist li:not(.geolistitem--info):hover,
ul.geoitemlist li:not(.geolistitem--info):focus {
  background: #f5f5f5;
}
ul.geoitemlist li:not(.geolistitem--info):before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  margin-right: 0.2rem;
  content: '';
  color: #ccc;
}
ul.geoitemlist li.geolistitem--selected {
  cursor: auto;
  font-weight: bold;
}
ul.geoitemlist li.geolistitem--selected::before {
  font-weight: 900;
  color: inherit;
}
.buttonContainer {
  height: 43px;
  margin: 10px 10px;
  bottom: 0;
  position: absolute;
}
.buttonWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 61px;
  background: #fff;
  border-top: 1px solid #949494;
  border-color: #cad2d5;
}
.geoButton {
  float: left;
  height: 41px;
  padding: 12px 15px;
  border: none;
  margin-left: 10px;
  font-size: 15px;
  cursor: pointer;
}
.geoButton.removeSearchResult {
  margin-left: 0;
}
.geoButton.cancel {
  font-size: 13px !important;
}
.submitSearchBtn:disabled {
  color: #a8a8a8;
  background-color: #e6e6e6;
  cursor: default;
}
.mosaic-scroll-container {
  overflow: hidden;
  position: relative;
  background-color: #fff;
}
.mosaic-scroll-bar {
  position: absolute;
  background-color: #dbd3cb;
  background-color: rgba(219, 211, 203, 0.3);
  opacity: 1;
  z-index: 288;
  cursor: pointer;
}
.j-color-scheme .mosaic-scroll-bar {
  background-color: rgba(219, 211, 203, 0.8);
}
.mosaic-scroll-bar.horizontal {
  left: 0;
  bottom: 0;
  height: 10px;
  width: 50px;
}
.period .mosaic-scroll-bar.horizontal,
.j-workspace-bar .mosaic-scroll-bar.horizontal {
  height: 8px;
}
.mosaic-scroll-bar.vertical {
  right: 0;
  top: 0;
  width: 10px;
  height: 50px;
}
.mosaic-scroll-drag {
  cursor: default;
  position: relative;
  margin: 0;
  padding: 0;
  line-height: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0 none;
}
.mosaic-scroll-drag:active,
.mosaic-scroll-drag:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.j-color-scheme .mosaic-scroll-drag {
  background-color: rgba(0, 0, 0, 0.5);
}
input.mosaic-scroll-drag {
  vertical-align: top;
}
.mosaic-scroll-drag.horizontal {
  height: 100%;
  width: 8px;
  vertical-align: top;
}
.mosaic-scroll-drag.vertical {
  width: 100%;
  height: 8px;
  position: absolute;
}
#dashboardwrapper.sidebar #seldialog {
  position: absolute;
}
.seltool-togglemobile,
.sel-mobile-footer {
  display: none;
}
.seltool-togglemobile button {
  border: 0 none;
  background-color: transparent;
}
.sel-mobile-footer {
  position: relative;
  right: 0;
  padding: 11px 1px;
  height: 50px;
}
.sel-mobile-footer .close-mobile-button {
  float: right;
  border: none;
}
@media only screen and (min-width: 880px) and (max-width: 1680px) {
  #dashboardwrapper.sidebar .sel-selection.uggsel:not(.step5) ~ .sel-footer .navigation-controls a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  #dashboardwrapper.sidebar .sel-selection.step5.mapview {
    left: -300px;
  }
}
@media only screen and (min-width: 880px) and (max-width: 1400px) {
  .sel-selection.uggsel:not(.step5) ~ .sel-footer .navigation-controls a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  .sel-selection.step5.mapview {
    left: -300px;
  }
  #dashboardwrapper.sidebar .sel-selection.step5 {
    left: -150px;
  }
  #dashboardwrapper.sidebar .sel-selection.step5.mapview {
    left: -450px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  .sel-selection.step5 {
    left: -150px;
  }
  .sel-selection.step5.mapview {
    left: -450px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1320px) {
  #dashboardwrapper.sidebar
    .sel-selection:not(.step3):not(.uggsel)
    ~ .sel-footer
    .navigation-controls
    a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  #dashboardwrapper.sidebar .sel-selection.filterstep.step3.mapview {
    left: -170px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1040px) {
  .sel-selection:not(.step3):not(.uggsel) ~ .sel-footer .navigation-controls a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  .sel-selection.filterstep.step3.mapview {
    left: -170px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1240px) {
  #dashboardwrapper.sidebar .sel-selection.step5 {
    left: -350px;
  }
  #dashboardwrapper.sidebar .sel-selection.step5.mapview {
    left: -650px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 960px) {
  .sel-selection.step5 {
    left: -350px;
  }
  .sel-selection.step5.mapview {
    left: -650px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1200px) {
  #dashboardwrapper.sidebar
    .sel-selection.uggsel:not(.step4):not(.step5)
    ~ .sel-footer
    .navigation-controls
    a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  #dashboardwrapper.sidebar .sel-selection.step4 {
    left: -150px;
  }
  #dashboardwrapper.sidebar .sel-selection.step5 {
    left: -500px;
  }
  #dashboardwrapper.sidebar .sel-selection.step5.mapview {
    left: -750px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 880px) {
  .sel-selection.uggsel:not(.step4):not(.step5) ~ .sel-footer .navigation-controls a.sel-selection-prev {
    color: #c8c8c8;
    pointer-events: none;
  }
  .sel-selection.step4 {
    left: -150px;
  }
  .sel-selection.step5 {
    left: -500px;
  }
  .sel-selection.step5.mapview {
    left: -750px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1100px) {
  #dashboardwrapper.sidebar .sel-selection.filterstep.step3 {
    left: -170px;
  }
  #dashboardwrapper.sidebar .sel-selection.filterstep.step3.mapview {
    left: -400px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 840px) {
  .sel-selection.filterstep.step3 {
    left: -170px;
  }
  .sel-selection.step3.mapview {
    left: -100px;
  }
  .sel-selection.filterstep.step3.mapview {
    left: -400px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 940px) {
  #dashboardwrapper.sidebar .sel-selection.filterstep.step3,
  .sel-selection.step4 {
    left: -400px;
  }
  #dashboardwrapper.sidebar .sel-selection.step3.mapview:not(.uggsel) {
    left: -200px;
  }
}
@media only screen and (min-width: 601px) and (max-width: 680px) {
  .sel-selection.filterstep.step3,
  .sel-selection.step4 {
    left: -400px;
  }
  .sel-selection.step3.mapview:not(.uggsel) {
    left: -200px;
  }
}
.usergeogroup-container {
  display: inline-block;
  vertical-align: top;
  height: 100%;
}
@media only screen and (min-width: 1px) and (max-width: 600px) {
}
.dashblock,
.themasummary .column.colRight,
.themasummary .column.colLeft,
.column.colFull {
  list-style: none;
  /* width: 50%; */
  box-sizing: border-box;
  margin: 0;
  min-height: 1px;
}
section.tile.height1 {
  grid-row-end: span 1;
}
section.tile.height2 {
  grid-row-end: span 2;
}
section.tile.height3 {
  grid-row-end: span 3;
}
section.tile.height4 {
  grid-row-end: span 4;
}
section.tile.height5 {
  grid-row-end: span 5;
}
section.tile.height6 {
  grid-row-end: span 6;
}
section.tile.height7 {
  grid-row-end: span 7;
}
section.tile.height8 {
  grid-row-end: span 8;
}
section.tile.height9 {
  grid-row-end: span 9;
}
section.tile.height10 {
  grid-row-end: span 10;
}
section.tile.height11 {
  grid-row-end: span 11;
}
section.tile.height12 {
  grid-row-end: span 12;
}
section.tile.height13 {
  grid-row-end: span 13;
}
section.tile.height14 {
  grid-row-end: span 14;
}
section.tile.height15 {
  grid-row-end: span 15;
}
section.tile.height16 {
  grid-row-end: span 16;
}
section.tile.width1 {
  grid-column-end: span 1;
}
section.tile.width2 {
  grid-column-end: span 2;
}
.tile-wrapper > div {
  height: 100%;
}
.dashblock.colFull,
.column.colFull {
  width: 100% !important;
  padding: 10px 0;
}
.dashblock.colRight,
.dashblock.colLeft,
.dashblock.colFull.single {
  display: grid;
  grid-template-columns: repeat(2, minmax(80px, 1fr));
  grid-auto-rows: 80px;
  /* width: 50%; */
  padding: 10px;
  grid-gap: 20px;
  gap: 20px;
}
div.tile-holder {
  display: grid;
  grid-template-columns: repeat(2, minmax(80px, 1fr));
  grid-auto-rows: 80px;
  width: 100%;
  padding: 0;
  grid-gap: 20px;
  gap: 20px;
}
body#embeddedBody:not(.fullwidth) {
  max-width: 650px;
}
.dashblock.colLeft,
.dashblock.colFull.single {
  padding-left: 0;
}
.dashblock.colRight,
.dashblock.colFull.single {
  padding-right: 0;
}
.column.list.colLeft {
  /* width: 75% !important; */
  grid-template-columns: unset;
  display: block;
}
.column.list.colRight {
  width: 25% !important;
  display: block;
}
div.inner-tile,
.cursor-def {
  cursor: default;
}
.resizing section.tile.nolink,
.resizing div.inner-tile {
  cursor: nw-resize;
}
@-webkit-keyframes createBox {
  0% {
    transform: scale(1);
    z-index: 1000;
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
    z-index: 0;
  }
}
@keyframes createBox {
  0% {
    transform: scale(1);
    z-index: 1000;
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
    z-index: 0;
  }
}
section.tile {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
section.tile.h100p,
section.tile.h100p div.free-tile {
  min-height: 25px;
}
section.tile.h100p:not(:last-child) {
  margin-bottom: 20px;
}
section.tile.h100p .free-table footer {
  margin-top: 16px;
}
section.tile.free {
  cursor: pointer;
}
section.tile.free.nolink {
  cursor: default;
}
section.tile.free div.tile-content header {
  max-height: 42px;
  overflow: visible;
}
section.tile.free div.tile-content.no-content header {
  height: auto;
  max-height: 100%;
  overflow: hidden;
}
section.tile.free div.tile-content.no-content h2.no-content,
section.tile.free div.tile-content.no-content h3.no-content {
  height: auto;
  max-height: none;
  max-height: initial;
}
section.tile.free a.tile-link:focus {
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: auto;
  width: auto;
  padding: 0 4px;
  background-color: #fff;
  color: #00e;
}
section.tile.free h2,
section.tile.free h3.tile-header {
  overflow: visible;
  text-overflow: unset;
  white-space: normal;
}
section.tile.hover {
  cursor: pointer;
}
section.tile.hover.nolink,
section.tile.hover.hover.nolink div.inner-tile.hover {
  cursor: default;
}
section.tile.nolink {
  cursor: default;
}
section.tile.tile--no-shadow.tile.hover:not(.nolink) .inner-tile,
section.tile.tile--no-shadow.tile.hover:not(.nolink) .free-tile {
  box-shadow: none;
}
section.tile.tile--no-shadow .inner-tile,
section.tile.tile--no-shadow .free-tile {
  box-shadow: none;
}
section.tile header {
  background: none no-repeat transparent !important;
}
section.tile header.tile-small {
  height: auto;
}
section.tile header.hidden {
  display: none;
}
section.tile a.tile-link {
  position: absolute;
  height: 0;
  width: 0;
  overflow: hidden;
  color: inherit;
}
section.tile a.tile-link:focus {
  bottom: 5px;
  height: 20px;
  width: 20px;
}
section.tile div.infographic-repetitive-reference div.table-content {
  flex-direction: column;
}
section.tile.dropdown-open .inner-tile,
section.tile.tile.dropdown-open .free-tile {
  border-bottom: none !important;
}
section.tile h2,
section.tile h3.tile-header {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
  max-height: 44px;
  width: 100%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
}
section.tile h2.no-subtitle,
section.tile h3.tile-header.no-subtitle {
  white-space: normal;
  overflow: visible;
}
section.tile p.subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
  margin-top: -2px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
section.tile p.subtitle strong {
  font-weight: 700;
}
section.tile footer {
  box-sizing: border-box;
  text-align: right;
  height: 39px;
  font-size: 12px;
  padding: 2px 0 0 0;
  white-space: nowrap;
  overflow: hidden;
}
section.tile footer.open {
  display: none;
}
section.tile footer data {
  font-weight: 700;
  margin-left: 2px;
}
section.tile footer data,
section.tile footer div.source,
section.tile footer div.special-type {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.5;
}
section.tile footer.height1 p.subtitle:empty {
  margin-top: 2px;
}
section.tile footer span.footer-period {
  font-weight: 700;
}
section.tile .extrainfo {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  z-index: 9;
  padding: 5px 0;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  font-size: 14px;
  border: 0 none;
  cursor: pointer;
}
section.tile .extrainfo i {
  line-height: 16px;
}
section.tile .datavalue {
  display: block;
  margin: 0;
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
}
section.tile .datavalue:not(.labeltile) {
  line-height: 44px;
  white-space: nowrap;
  overflow: hidden;
}
section.tile .datavalue small,
section.tile tspan.small {
  font-size: 70%;
  white-space: nowrap;
}
section.tile .datainfo {
  display: block;
  margin: 0;
  font-weight: normal;
  text-align: left;
  overflow: auto;
}
.col-section.left section .tile.width1 .wQuarter .datavalue,
col-section.right section .tile.width1 .wQuarter .datavalue {
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}
section.tile.width1 div.tile-row {
  flex-direction: column;
}
section.tile.width1.height2 .basic-reference .tile-row {
  flex-direction: row;
}
section.tile .wQuarter .subtitle,
section.tile.width1.height1.Infographics .subtitle,
section.tile.height2.Infographics .subtitle:not(.wFull),
section.tile.height2 .wHalf .wHalf .subtitle,
section.tile.width1.height2 .wHalf .subtitle {
  font-size: 12px;
  line-height: 16px;
  white-space: nowrap;
  font-weight: normal;
}
section.tile div.table,
section.tile div.table-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
section.tile div.table-content.dir-col {
  flex-direction: column;
}
section .tile.height2 div.table.dir-col div.hHalf,
section.tile.height3 div.table.dir-col div.hHalf {
  width: 50%;
}
section.tile div.table.dir-col {
  flex-direction: column;
}
section.tile.height2 div.table.dir-col,
section.tile.height3 div.table.dir-col {
  flex-direction: row;
}
section.tile div.tablerow {
  display: flex;
  width: 100%;
}
section.tile iframe {
  display: block;
  width: 1px;
  min-width: 100%;
  *width: 100%;
  height: 1px;
  min-height: 100%;
  *height: 100%;
  border: none;
}
section.tile .wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
section.tile.reflow {
  height: 100% !important;
}
section.tile p.subtitle.value.noicon {
  white-space: normal;
  text-align: center;
  width: 100%;
  height: auto;
  max-height: 64px;
  margin-top: 0;
}
section.tile p.subtitle.value.nolabel {
  display: none;
}
section.tile div.content,
section.tile div.tile-content {
  overflow: hidden;
  flex: 1 1;
}
section.tile div.content {
  position: relative;
}
section.tile div.content.scroll {
  overflow-y: auto;
}
section.tile.chart header {
  overflow: hidden;
  background: none no-repeat transparent !important;
}
section.tile.chart.height2 g.tick text {
  font-weight: 400;
}
section.tile.chart.height2 tspan.small {
  font-size: 90%;
}
section.tile div.content div.hidden {
  display: none;
}
section.tile table.content {
  width: 100%;
  border-spacing: 2px 0;
  border-collapse: separate;
  table-layout: fixed;
}
section.tile div.freecontent {
  line-height: normal;
  padding: 3px 0 3px 0;
  overflow: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
section.tile.h100p div.freecontent {
  line-height: 1.4;
}
section.tile div.freecontent p {
  margin: 0.5em 0;
}
section.tile div.freecontent ul {
  display: block;
  list-style-type: disc;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  padding-left: 40px;
}
section.tile div.freecontent ul li {
  display: list-item;
}
section.tile .no-header div.freecontent {
  padding: 0;
}
section.tile .datavalue sup {
  top: -0.3em;
}
section.tile .tile-content ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
section.tile .content-tile {
  padding: 10px;
  box-sizing: border-box;
}
section.tile .content-tile ul {
  list-style-type: disc;
}
section.tile table tbody {
  font-size: 12px;
}
section.tile.free h2,
section.tile.free h3.tile-header {
  padding-left: 0;
}
section.tile.free h2.u-t-left,
section.tile.free h3.tile-header.u-t-left {
  text-align: left;
}
section.tile.free span.content {
  padding-left: 0;
}
section.tile.Infographics .svg__icon {
  padding: 0;
}
section.tile.Infographics .svg__icon.pr-10 {
  padding-right: 10px;
}
section.tile.Infographics .svg__icon.label {
  padding-top: 5px;
  padding-bottom: 5px;
}
section.tile.Infographics .svg__icon.label.noicon {
  padding-top: 0;
  padding-bottom: 0;
}
section.tile.Infographics.height2 .svg__icon.label svg,
section.tile.Infographics.height1 .svg__icon.label svg {
  float: left;
  margin-top: -5px;
}
section.tile.Infographics.height2.nolabel .svg__icon.label svg,
section.tile.Infographics.height1.nolabel .svg__icon.label svg {
  float: none;
}
section.tile.Infographics.height2 p.subtitle.value {
  float: left;
  margin-top: 10px;
  width: 75%;
  height: 35px;
  text-align: left;
  white-space: inherit;
}
section.tile.Infographics .value-large {
  font-size: 76px;
  line-height: 76px;
  font-weight: bold;
  display: flex;
}
section.tile.Infographics .value-large svg {
  max-height: 76px !important;
  max-width: 76px !important;
  line-height: 76px;
  vertical-align: bottom;
  margin-left: 10px;
}
section.tile td.wQuarter {
  width: 25%;
  max-width: 25%;
}
section.tile td.w3Quarter {
  width: 75%;
  max-width: 75%;
  padding-left: 10px;
}
section.tile td.wOneTen {
  width: 10%;
  max-width: 10%;
}
section.tile td.wHalf {
  width: 50%;
  max-width: 50%;
}
section.tile div.w20perc {
  width: 20%;
  max-width: 20%;
}
section.tile div.w80perc {
  width: 80%;
  max-width: 80%;
}
section.tile div.w100perc {
  width: 100%;
  max-width: 100%;
}
section.tile div.w60perc {
  width: 60%;
  max-width: 60%;
}
section.tile div.wHalf,
section.tile div.tHalf {
  width: 50%;
  max-width: 50%;
}
section.tile div.w40perc {
  width: 40%;
  max-width: 40%;
}
section.tile div.w3Quarter {
  width: 75%;
  max-width: 75%;
}
section.tile div.noicon {
  width: 0%;
  max-width: 0%;
}
section.tile div.wQuarter {
  width: 25%;
  max-width: 25%;
}
section.tile div.hFull {
  height: 100%;
}
section.tile div.hHalf {
  display: flex;
  width: 100%;
}
section.tile div.h40perc {
  display: flex;
  height: 40%;
  flex: 1 1;
}
section.tile div.h20perc {
  display: flex;
  height: 20%;
  flex: 1 1;
}
section.tile div.h10perc {
  display: flex;
  height: 10%;
  flex: 1 1;
}
section.tile div.height15px {
  height: 15px;
}
section.tile div.wQuarter.u-t-right {
  padding-right: 10px;
}
section.tile div .w3Quarter.pLeft {
  padding-left: 5px;
}
section.tile div .wHalf.pLeft {
  padding-left: 10px;
}
section.tile div .w3Quarter.pRight {
  padding-right: 5px;
}
section.tile.width1 div.h10perc p.subtitle {
  width: 190px;
}
section.tile.width2 div.h10perc p.subtitle {
  width: 230px;
}
section.tile.width2.MultiIcon--double div.h10perc p.subtitle {
  width: 480px;
}
section.tile.height1 a.inner-tile,
section.tile.height1 div.inner-tile,
section.tile.height1 div.free-tile,
section.tile.height1 a.free-tile {
  padding-top: 0;
}
section.tile.height1 h2,
section.tile.height1 h3.tile-header {
  max-height: 38px;
}
section.tile.height1 header.tile-small,
section.tile.height1 header.tile-small h2.no-subtitle {
  max-height: 60px;
}
section.tile.height1 div.content:not(.labeltile) {
  height: 0;
}
section.tile.height1 .datainfo {
  height: 78px;
}
section.tile.height1 div.freecontent {
  overflow: hidden;
}
section.tile.height1 div.free-tile header.no-content {
  max-height: 78px;
}
section.tile.height1 div.freecontent p {
  margin: 0;
  padding: 0;
}
section.tile.height1 img.fittodiv {
  max-height: 78px;
}
section.tile.height1 .datavalue {
  font-size: 20px;
  line-height: 32px;
  white-space: nowrap;
}
section.tile.height1 .datavalue.labeltile {
  font-size: 10px;
  line-height: 10px;
}
section.tile.height1 footer {
  height: 18px;
  line-height: 18px;
}
section.tile.height2 h2,
section.tile.height2 h3.tile-header,
section.tile.height2 header,
section.tile.height2.Infographics.height2 h2.no-subtitle {
  max-height: inherit;
}
section.tile.height2 .free-tile header.no-content,
section.tile.height2 .free-tile h2.no-content,
section.tile.height2 .w3Quarter header,
section.tile.height2 img.fittodiv:not(.imgoverlay) {
  max-height: 104px;
}
section.tile.height2 .datainfo {
  height: 143px;
}
section.tile.height2 .free-tile div.tile-content.no-header,
section.tile.height2 div.tile-content.no-header .wHalf,
section.tile.height2 div.tile-content.no-header .wQuarter,
section.tile.height2 div.fittodiv {
  height: 104px;
}
section.tile.height2 div.tile-content .wHalf {
  height: 60px;
}
section.tile.height2 div.freecontent {
  overflow: auto;
  word-break: break-word;
  overflow-x: hidden;
}
section.tile.height2 .tile-content.no-header div.freecontent {
  overflow: auto;
  word-break: break-word;
  overflow-x: hidden;
}
section.tile.height2 img.fittodiv.small {
  max-height: 56px;
}
section.tile.height2 .datavalue:not(.labeltile) {
  font-size: 32px;
  line-height: 32px;
  white-space: nowrap;
}
section.tile.height2 .datavalue.labeltile {
  font-size: 16px;
  line-height: 16px;
}
section.tile.height2 .tc-hasheader {
  height: 60px;
  overflow: hidden;
}
section.tile.height2 .wHalf .tc-hasheader.wHalf {
  width: 49%;
}
section.tile.height2 div.tile-content.no-header div.tc-hasheader.wHalf {
  height: 56px;
}
section.tile.height2 .tc-hasheader div.v-align {
  display: block;
  width: 100%;
}
section.tile.height2 .free-tile .theme-tile-image {
  height: 142px;
  padding: 10px;
}
section.tile.height4.width1 .free-tile .theme-tile-image {
  margin-top: 36px;
}
section.tile.height4 .free-tile .theme-tile-image {
  height: 342px;
  padding: 20px;
}
section.tile.height4 div.tile-content div.middle.fittodiv {
  height: 302px;
  line-height: 302px;
}
section.tile.height4 div.freecontent {
  overflow: auto;
}
section.tile.height4 .tile-content.no-header div.freecontent {
  overflow: auto;
}
section.tile.height4 header.no-content {
  max-height: 302px;
}
section.tile.height4 .datainfo {
  height: 343px;
}
section.tile.height4 div.tile-content .wHalf,
section.tile.height4 div.tile-content .wQuarter {
  height: 260px;
}
section.tile.height4 div.tile-content .wFull.h4Row.svg,
section.tile.height4.width1 div.tile-content .wHalf.h4Row {
  height: 110px;
  display: block;
  padding-top: 20px;
  width: 100%;
  max-width: 100%;
}
section.tile.height4 .tc-hasheader {
  display: flex;
  flex-direction: row;
  height: 260px;
}
section.tile.height4 .tc-hasheader.hHalf {
  height: 130px;
}
section.tile.Infographics .infographics-content {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}
section.tile.height6 img.fittodiv:not(.imgoverlay) {
  max-height: 460px;
}
section.tile.height6 div.fittodiv {
  height: 460px;
}
section.tile.height6 div.freecontent,
section.tile.height6 div.freecontent.no-header {
  overflow: auto;
}
section.tile.height8 div.freecontent {
  overflow: auto;
}
section.tile.height16 div.freecontent {
  overflow: auto;
}
div.inner-tile.scroll-animation,
div.free-tile.scroll-animation,
a.free-tile.scroll-animation {
  -webkit-animation: createBox 2s;
          animation: createBox 2s;
}
div.inner-tile.hover {
  cursor: pointer;
}
a.inner-tile,
div.inner-tile,
div.free-tile,
a.free-tile {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: relative;
  text-decoration: none;
  padding: 12px 0;
  display: flex;
  flex-direction: column;
}
div.tile-content,
div.free-tile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
div.free-tile.free-image-text {
  flex-direction: row;
}
div.infographic-reference-bar-area-left div.tile-content {
  justify-content: center;
}
.inner-tile header,
inner-tile .wHalf h2.header,
inner-tile .wHalf h3.header,
header.free-table {
  height: 54px;
  width: 100%;
}
body.line-high .inner-tile header,
body.line-high inner-tile .wHalf h2.header,
body.line-high inner-tile .wHalf h3.tile-header.header {
  height: 88px;
}
section.tile.Infographics.height2 h2 {
  max-height: 36px;
}
div.basic-reference div.tile-row {
  height: 100%;
}
div.tile-row {
  display: flex;
}
div.infographic-reference-bar-area-left div.tile-row {
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}
.tile-editing-remarks-opener {
  display: block;
  cursor: pointer;
  width: 35px;
  height: 35px;
  position: absolute;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: #d81010;
  border: none;
  color: #fff;
}
.tile-editing-remarks-opener:hover {
  background: #f84444;
}
.tile-editing-remarks-opener:active {
  background: #960b0b;
}
.tile__editing-remarks-inner {
  max-height: 57vh;
  min-height: 43px;
  overflow-y: auto;
}
.dashblock.list section.tile .content-tile {
  height: auto !important;
  margin-bottom: 20px;
}
.dashblock.list .tile.content {
  height: auto !important;
  margin-bottom: 20px;
}
.include-section .tile.content .inner-tile {
  display: block;
  overflow: auto;
}
.include-section .tile-content ul {
  padding: 10px;
}
div.wHalf .datavalue,
div.wQuarter .datavalue,
section.tile .wHalf header {
  margin-right: 5px;
}
.tile-action-btn.detailview-table {
  display: none;
}
.width2.height2.tile div.wHalf:first-child,
.height1 div.wHalf:first-child {
  padding-right: 4px;
}
.width2.height2.tile div.wHalf:nth-child(2),
.width2.height2.tile div.wQuarter:nth-child(2),
.height1 div.wHalf:nth-child(2),
.height1 div.wQuarter:nth-child(2) {
  padding-left: 4px;
}
.datavalue,
.subtitle {
  width: 100%;
}
a.inner-tile .v-align,
a.inner-tile div.v-align,
div.inner-tile .v-align,
div.inner-tile div.v-align,
div.free-tile .v-align,
div.free-tile div.v-align,
a.free-tile .v-align,
a.free-tile div.v-align {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}
a.inner-tile .v-align.top,
a.inner-tile div.v-align.top,
div.inner-tile .v-align.top,
div.inner-tile div.v-align.top,
div.free-tile .v-align.top,
div.free-tile div.v-align.top,
a.free-tile .v-align.top,
a.free-tile div.v-align.top {
  align-content: start;
}
a.inner-tile .v-align.middle,
a.inner-tile div.v-align.middle,
div.inner-tile .v-align.middle,
div.inner-tile div.v-align.middle,
div.free-tile .v-align.middle,
div.free-tile div.v-align.middle,
a.free-tile .v-align.middle,
a.free-tile div.v-align.middle {
  align-content: center;
}
a.inner-tile .v-align.bottom,
a.inner-tile div.v-align.bottom,
div.inner-tile .v-align.bottom,
div.inner-tile div.v-align.bottom,
div.free-tile .v-align.bottom,
div.free-tile div.v-align.bottom,
a.free-tile .v-align.bottom,
a.free-tile div.v-align.bottom {
  align-content: end;
}
div.free-tile div.v-align {
  flex-direction: column;
}
div.free-tile div.v-align.middle {
  justify-content: center;
  align-content: start;
  flex-wrap: nowrap;
}
div.free-tile div.v-align.top {
  flex-wrap: nowrap;
}
a[data-showdetailview='false'] {
  cursor: default;
}
a[data-showdetailview]:not([data-showdetailview='']) {
  cursor: pointer;
}
img.fittodiv,
object.fittodiv {
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}
img.fittodiv.imgoverlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
object.fittodiv {
  max-width: 100%;
  pointer-events: none;
}
div.overlaycontainer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
div.tile-content.left,
div.overlaycontainer.left {
  width: 50% !important;
}
div.grid-section[data-gridtype='double'] section.tile.free.width1 div.tile-content.left,
div.grid-section[data-gridtype='double'] section.tile.free.width1 div.overlaycontainer.left {
  width: 100% !important;
}
.free-tile[data-href*='http'] div.inner-tile {
  cursor: pointer;
}
.free-tile img.fittodiv,
.free-tile object.fittodiv {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
}
.free-tile .wQuarter {
  padding-right: 10px;
}
.free-tile .theme-tile-name {
  height: 38px;
  color: #fff;
  padding: 0 5px;
  font-size: small;
  background-color: #005a96;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.free-tile .theme-tile-name > .name_wrapper {
  max-height: 38px;
  line-height: 19px;
  overflow: hidden;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}
.free-tile .theme-tile-image {
  text-align: center;
  overflow: hidden;
  box-sizing: border-box;
}
.free-tile .theme-tile-image img {
  max-height: 100%;
  max-width: 100%;
}
.free-tile .theme-tile-image img:hover {
  transform: scale(1.1);
}
div.fittodiv.left {
  left: 0;
}
div.fittodiv.right {
  right: 0;
}
img.imgSmall {
  max-height: 60px;
  display: block;
  margin: 0 auto;
}
img.reficon {
  margin-left: 0.1em;
  max-height: 44px;
  vertical-align: middle;
}
.tile-content .table-wrapper {
  overflow-y: auto;
}
.tile-content .table-wrapper.free {
  overflow: auto;
}
.free-table .table-wrapper.free.content {
  width: 100%;
}
table.tile-presentation-table {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
table.tile-presentation-table th,
table.tile-presentation-table td {
  padding: 6px 8px;
  border: 1px solid #004775;
  font-size: 12px;
  vertical-align: top;
}
table.tile-presentation-table td {
  text-align: right;
}
table.tile-presentation-table td.f {
  font-style: italic;
}
table.tile-presentation-table td.e {
  font-style: italic;
}
table.tile-presentation-table tbody th {
  text-align: left;
  font-weight: 600;
}
table.tile-presentation-table thead th {
  text-align: right;
  font-weight: 600;
}
table.tile-presentation-table thead th .header-value-wrapper {
  width: 100%;
  display: block;
}
table.tile-presentation-table thead tr:first-child th:first-child {
  text-align: left;
}
@media only screen and (min-width: 1px) and (max-width: 1023px) {
  table.tile-presentation-table div.content {
    overflow: auto;
  }
}
.tile-dialog {
  display: block;
  position: absolute;
}
.tile-dialog .social-Utils__items {
  display: block;
  min-width: 190px;
}
.tile-dialog .social-Utils__items button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.tile-dialog .utils-label {
  width: 80%;
}
.tile-dialog .utils-label:focus {
  outline: thin dotted;
}
.tile-dialog .utils-icon {
  font-size: 18px;
}
.listtable {
  box-sizing: border-box;
  width: 100%;
  padding-top: 3px;
  display: flex;
}
.listtable p.subtitle {
  padding: 0;
  margin-right: 10px;
  text-align: right !important;
  font-size: 12px;
  font-weight: normal;
}
div.map-copyright {
  position: absolute;
  font-size: 11px;
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  right: 0;
}
div.map-copyright .accent-color {
  color: inherit;
}
g.axes g.axislines line,
g.axes g.axis.lines line {
  opacity: 0.7;
}
svg g.chart g.y.axis.left.broken {
  opacity: 0.7;
}
rect.bgbar {
  opacity: 0.2;
}
g.bar .label {
  font-weight: bold;
}
g.label circle {
  opacity: 0.6 !important;
}
g.label {
  font-weight: bold;
}
body.touch div.ol-viewport.ol-touch {
  touch-action: auto !important;
}
.iframe_link:not(.tile-action-btn) {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  font-size: 14px;
  display: inline-block;
  box-sizing: border-box;
  width: 24px;
  position: absolute;
  padding: 3px;
  text-decoration: none;
  color: inherit;
}
.iframe_link:not(.tile-action-btn):before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
  content: '';
  text-decoration: none;
  text-align: center;
}
.tooltip-term {
  -webkit-text-decoration: dotted;
          text-decoration: dotted;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.simpletooltip {
  position: absolute;
  width: 250px;
  padding: 15px;
  margin-top: 30px;
  margin-left: -30px;
  z-index: 1001;
  border-radius: 4px;
  text-align: left;
  line-height: 1.3;
  font-size: 15px;
  text-decoration: none;
  font-weight: normal;
  background-color: #2d2d2d;
  color: #fff;
}
.simpletooltip[aria-hidden='true'] {
  display: none;
}
.simpletooltip::before {
  top: -21px;
  z-index: 1002;
  position: absolute;
  content: '';
  display: block;
  border-width: 0 13px 22px 13px;
  border-style: solid;
  border-color: transparent transparent #2d2d2d transparent;
}
.simpletooltip.left {
  margin-left: 0;
}
.simpletooltip.left:before {
  right: 15px;
}
.simpletooltip.middle {
  margin-left: -15px;
}
.simpletooltip.middle:before {
  right: 125px;
}
.simpletooltip.fliptop {
  margin-top: 0;
  margin-bottom: 30px;
}
.simpletooltip.fliptop:before {
  top: unset;
  bottom: -21px;
  z-index: 1002;
  display: block;
  border-width: 22px 13px 0 13px;
  border-style: solid;
  border-color: #2d2d2d transparent transparent transparent;
}
.tile-grid-wrapper {
  position: relative;
  display: flex;
  flex-wrap: wrap;
}
.tile-grid-wrapper .gap .add-tile-wrapper {
  display: none;
  align-items: center;
  justify-content: center;
}
.tile-grid-wrapper .gap .add-tile-wrapper .add-tile-border {
  background: silver;
}
.tile-grid-wrapper .gap .add-tile-wrapper .add-tile {
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  border-radius: 50%;
  background: #427bab;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}
.tile-grid-wrapper .gap.bottom {
  height: 30px;
  width: 100%;
  margin-top: -7px;
  z-index: 1;
}
.tile-grid-wrapper .gap.bottom .add-tile-wrapper .add-tile-border {
  height: 1px;
  width: 75px;
}
.tile-grid-wrapper .gap.bottom .add-tile-wrapper .add-tile {
  margin: 2px 5px;
}
.tile-grid-wrapper .gap:hover .add-tile-wrapper {
  display: flex;
}
.tile-grid-wrapper.height1 .tile-actions,
.tile-grid-wrapper.height2 .tile-actions {
  text-align: left;
  width: 100%;
  height: 43px;
  flex-direction: row;
}
.tile-grid-wrapper .not-visible {
  position: absolute;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.4);
}
.tile-wrapper {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.tile-wrapper.selected {
  outline: 2px solid #000 !important;
}
.tile-wrapper.resizing {
  outline: 2px dotted #000 !important;
}
.tile-wrapper.dragging * {
  pointer-events: none;
}
.tile-wrapper.placeholder {
  border: 1px dashed #427bab;
}
.tile-wrapper .tile__rawdata {
  display: none;
}
.tile-wrapper .tile-actions {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  inset: 0;
  width: 65px;
  opacity: 1;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
}
.tile-wrapper .tile-actions .btn {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 50%;
  color: #fff;
  text-align: center;
}
.tile-wrapper .tile-actions .btn:hover {
  background: rgba(0, 0, 0, 0.9);
}
.tile-wrapper .tile-actions .btn:active {
  transform: scale(0.9);
}
.tile-wrapper .btn.placeholder-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-transform: none;
}
.tile-wrapper .btn.placeholder-tile i {
  color: #427bab;
  font-size: 35px;
  padding-bottom: 10px;
}
.tile-wrapper:hover .tile-actions {
  display: flex;
}
.tile-loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Infographics .tile__section .tile__section__content__data {
  text-align: center;
}
.Infographics .svg__icon {
  box-sizing: border-box;
  padding: 10%;
}
.Infographics .svg__icon svg {
  width: 100%;
  max-height: 80px;
}
.Infographics .svg__icon.vertical svg {
  width: 25px;
  height: 25px !important;
}
.Infographics.BasicIcon--double svg {
  height: 80px;
  max-width: 100%;
}
.Infographics.MultiIcon .svg__icon,
.Infographics.MultiIcon--double .svg__icon,
.svg__icon.multiple {
  text-align: left;
}
.Infographics.MultiIcon .svg__icon svg,
.Infographics.MultiIcon--double .svg__icon svg,
.svg__icon.multiple svg {
  height: 80px;
}
.svg__icon.multiple svg {
  max-width: 90%;
}
.tile.height1.Infographics .svg__icon svg {
  height: 28px;
}
.tile.height1.Infographics.nolabel .svg__icon svg {
  height: 20px;
}
.tile.height1.Infographics.nolabel .svg__icon.noregion svg {
  height: 36px;
}
.tile.height2.Infographics .svg__icon svg {
  height: 60px;
  display: block;
}
.tile.MultiIcon.height2.Infographics .svg__icon svg,
.tile.height2 .svg__icon.multiple svg {
  height: 35px;
}
.tile.height2.Infographics.nolabel .svg__icon svg {
  height: 58px;
}
.tile.height4.Infographics:not(.MultiIcon--multiple) .svg__icon svg {
  height: 80px;
}
.tile.Infographics:not(.MultiIcon--multiple) .svg__icon.small svg {
  height: 40px;
}
.tile.height2.Infographics td.svg__icon svg {
  height: 25px;
}
.tile.Infographics table.labelTable tbody.o-hidden {
  overflow: hidden;
}
.tile.Infographics.height1 table.labelTable tbody.o-hidden {
  height: 15px;
}
.tile.Infographics.height4 table.labelTable tbody.o-hidden {
  height: 190px;
}
.tile.Infographics.height8 table.labelTable tbody.o-hidden {
  height: 472px;
}
.tile.Infographics table.labelTable tbody.o-hidden tr {
  height: auto;
}
.Infographics.MultiIcon--multiple .svg__icon svg,
.Infographics.BasicIcon--multiple svg {
  height: 40px;
  max-width: 100%;
}
.Infographics.MultiIcon .svg__icon,
.Infographics.MultiIcon--double .svg__icon {
  padding: 0 5%;
}
.tile-width--half.tile-height--full > .Infographics .tile__section__content__data {
  vertical-align: middle !important;
}
.Infographics .tile__section__content__title {
  font-size: 14px;
  padding-bottom: 0 !important;
  padding-top: 3px;
  vertical-align: top;
}
.Infographics .tile-unit {
  text-align: center;
  font-weight: normal;
}
.Infographics > .tile__section .tile__section.tile__section--vertical {
  height: 100%;
}
.Infographics .tile__section.tile__section--half.tile__section--horizontal {
  height: 90%;
}
.Infographics .tile__section--3quarter,
.Infographics .tile__section--quarter {
  height: 80%;
}
.Infographics > .tile__section.tile__section--vertical:first-child {
  height: 10%;
}
.tile-width--half.tile-height--full > .Infographics .tile__section.tile__section--vertical:nth-child(3) {
  height: 10%;
}
.Infographics .tile__section--quarter .tile__section__content__data {
  vertical-align: middle !important;
}
.tile-width--full.tile-height--half .MultiIcon .tile__section--horizontal {
  height: 90%;
}
.Infographics.BasicIcon--double .tile__section.tile__section--vertical:nth-child(2),
.Infographics.BasicIcon--double .tile__section.tile__section--vertical:nth-child(3) {
  height: 45% !important;
}
.Infographics.MultiIcon--double > .tile__section.tile__section--vertical:nth-child(2),
.Infographics.MultiIcon--double > .tile__section.tile__section--vertical:nth-child(3) {
  height: 45% !important;
}
.Infographics.MultiIcon--double .tile__section--vertical .tile__section__content__data {
  vertical-align: middle !important;
}
.Infographics.ValueImageIcon .tile__section__content__data.ref-right,
.Infographics.ValueImageIcon .tile__section__content__data.ref-left {
  vertical-align: bottom !important;
}
.Infographics.ValueImageIcon .tile__section__content__data.ref-right data {
  font-size: 76px !important;
  line-height: 76px;
}
.Infographics.ValueImageIcon .tile__section__content__data.ref-left data {
  font-size: 38px;
  line-height: 46px;
}
.Infographics.ValueImageIcon .tile__section--vertical {
  height: 65%;
}
.Infographics.ValueImageIcon .svg__icon.float {
  padding: 0;
}
.Infographics.ValueImageIcon .svg__icon.float svg {
  max-height: 76px !important;
  max-width: 76px !important;
  line-height: 76px;
  vertical-align: bottom;
}
#dashboardView:not(.u-hidden) {
  grid-gap: 20px;
  gap: 20px;
  display: grid;
}
.theme-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: 35px;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
}
.theme.height1 {
  grid-row-end: span 1;
}
.theme.height2 {
  grid-row-end: span 2;
}
.theme.height3 {
  grid-row-end: span 3;
}
.theme.height4 {
  grid-row-end: span 4;
}
.theme.height5 {
  grid-row-end: span 5;
}
.theme.height6 {
  grid-row-end: span 6;
}
.theme.height7 {
  grid-row-end: span 7;
}
.theme.height8 {
  grid-row-end: span 8;
}
.theme.height9 {
  grid-row-end: span 9;
}
.theme.height10 {
  grid-row-end: span 10;
}
.theme.height11 {
  grid-row-end: span 11;
}
.theme.height12 {
  grid-row-end: span 12;
}
.theme.height13 {
  grid-row-end: span 13;
}
.theme.height14 {
  grid-row-end: span 14;
}
.theme.height15 {
  grid-row-end: span 15;
}
.theme.height16 {
  grid-row-end: span 16;
}
.theme.width1 {
  grid-column-end: span 2;
}
.theme.width2 {
  grid-column-end: span 2;
}
.theme.width3 {
  grid-column-end: span 3;
}
.theme.width4 {
  grid-column-end: span 4;
}
.theme.width5 {
  grid-column-end: span 5;
}
.theme.width6 {
  grid-column-end: span 6;
}
.theme.width7 {
  grid-column-end: span 7;
}
.theme.width8 {
  grid-column-end: span 8;
}
.theme.width9 {
  grid-column-end: span 9;
}
.theme.width10 {
  grid-column-end: span 10;
}
.theme.width11 {
  grid-column-end: span 11;
}
.theme.width12 {
  grid-column-end: span 12;
}
.theme {
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  background-clip: padding-box;
}
.theme.usertheme {
  position: relative;
}
.theme.circle {
  width: 100%;
  height: 100%;
}
.theme.circle .theme__link {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
}
.theme.circle div.circle-container,
.dashboard-section-wrapper .circle {
  border-radius: 100vmax;
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 100%;
  background-clip: padding-box;
  overflow: hidden;
}
.theme-wrapper.placeholder.circle {
  width: auto;
}
.theme.circle div.circle-container.w100 {
  width: 100%;
  max-height: 100%;
}
.theme.circle div.circle-container.h100 {
  height: 100%;
}
.theme.number,
.dashboard-section-wrapper .theme-number {
  height: calc(100% - 30px);
  margin-top: 30px;
  margin-left: 30px;
  overflow: visible;
  position: relative;
}
.theme.number .theme__image {
  width: 120px;
  height: 120px;
  padding: 20px;
  border-radius: 50%;
  margin: -30px 0 0 -30px;
}
.theme.number .theme__text-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 17px;
}
.theme.number .theme__text-wrapper .theme__name {
  width: calc(100% - 140px);
  padding-left: 30px;
  text-align: left;
  justify-content: left;
}
.theme.number .theme__text-wrapper .theme__name .name_wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
}
.theme.number .theme__text-wrapper .tile-number {
  position: absolute;
  right: -6px;
  bottom: -6px;
  font-size: 138px;
  line-height: 0.8;
  font-weight: bold;
}
.theme-number .btn:before {
  content: '';
  display: block;
  border: 1px dashed #427bab;
  border-radius: 50%;
  position: absolute;
  top: -30px;
  left: -30px;
  background: #f2f2f2;
}
.theme.number.height3 .theme__image,
.theme-number .btn:before {
  width: 90px;
  height: 90px;
}
.theme.number.height3 .theme__text-wrapper {
  margin-top: 4px;
}
.theme.number.height3 .theme__text-wrapper .theme__name {
  width: calc(100% - 110px);
}
.theme.number.height3 .theme__text-wrapper .tile-number {
  font-size: 108px;
}
.theme.number.height1,
.theme.number.height2 {
  margin: 0;
  height: 100%;
}
.theme.number.height1 .theme__image,
.theme.number.height2 .theme__image {
  display: none;
}
.theme.number.height1 .theme__text-wrapper,
.theme.number.height2 .theme__text-wrapper {
  height: 100%;
  align-content: center;
  margin-top: 0;
}
.theme.number.height1 .theme__text-wrapper .theme__name,
.theme.number.height2 .theme__text-wrapper .theme__name {
  width: calc(100% - 100px);
}
.theme.number.height1 .theme__text-wrapper .tile-number,
.theme.number.height2 .theme__text-wrapper .tile-number {
  right: -6px;
  bottom: -5px;
  font-size: 110px;
}
.theme.number.width1 .theme__text-wrapper {
  margin-top: 7px;
}
.theme.number.width1 .theme__text-wrapper .theme__name {
  width: calc(100% - 1px);
  padding: 0 30px;
}
.theme.number.width1 .theme__text-wrapper .name_wrapper {
  width: 110px;
}
.theme.number.width1 .theme__text-wrapper .tile-number {
  font-size: 125px;
}
.theme.number.height1 .theme__text-wrapper,
.theme.number.height2 .theme__text-wrapper {
  margin-top: 0;
}
.theme.number.height1 .theme__text-wrapper .theme__name {
  width: calc(100% - 20px);
  height: 33px;
}
.theme.number.height1 .theme__text-wrapper .tile-number {
  right: -2px;
  bottom: -2px;
  font-size: 47px;
}
.theme.number.width1.height2 .theme__text-wrapper .tile-number {
  right: -4px;
  bottom: -2px;
  font-size: 67px;
}
.theme.number.width1.height2 .theme__text-wrapper .tile-number {
  right: -2px;
  font-size: 47px;
}
.theme.number.width1.height4 .theme__image {
  width: 90px;
  height: 90px;
}
.theme.number.width1.height3 .theme__link figure,
.theme.number.width1.height2 .theme__link figure,
.theme.number.width1.height4 .theme__link figure {
  position: relative;
  display: block;
}
.theme.number.width1.height3 .theme__text-wrapper .theme__name,
.theme.number.width1.height4 .theme__text-wrapper .theme__name {
  width: calc(100% - 1px);
  padding: 0 15px;
}
.theme.number.width1.height3 .theme__text-wrapper .tile-number,
.theme.number.width1.height4 .theme__text-wrapper .tile-number {
  top: 23px;
  font-size: 68px;
}
.theme.line .theme__image {
  justify-content: left;
  height: calc(100% - 67px);
  padding: 20px 20px 0 20px;
}
.theme.line .graphic-line {
  display: flex;
  justify-content: space-between;
  max-width: 80%;
  width: 88px;
  height: 5px;
  margin: 15px 20px 0;
}
.theme.line .graphic-line .graphic-line-1 {
  width: 80%;
  border-radius: 5px;
  background: #fff;
}
.theme.line .graphic-line .graphic-line-2 {
  width: 14%;
  border-radius: 5px;
  background: #fff;
}
.theme.line .theme__name {
  height: 42px;
  padding: 0 20px;
  justify-content: left;
  text-align: left;
}
.theme.line .theme__name .name_wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: bold;
}
.theme.line.height2 .graphic-line {
  margin-top: 6px;
}
.theme.line.height2 .theme__name {
  height: 52px;
}
.theme.line.height1 .theme__image,
.theme.line.height1 .graphic-line {
  display: none;
}
.theme.line.height1 .theme__name {
  width: calc(100% - 1px);
  height: 33px;
}
.width1 .width2 .name_wrapper {
  width: 173px;
}
.theme.line.width1 .theme__name {
  width: 173px;
}
.height4 .theme.line.height3 .theme__image,
.theme.line.height4 .theme__image {
  height: 76px;
}
.theme.theme-empty {
  height: 0 !important;
  border: none !important;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}
.theme .theme__name {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  color: #fff;
  padding: 0 5px;
  font-size: small;
  text-align: center;
  box-sizing: border-box;
}
.theme:not(.circle) .theme__name {
  background-color: #005a96;
}
.theme.circle .theme__name {
  padding-left: 15%;
  padding-right: 15%;
}
.theme .theme__name > .name_wrapper {
  max-height: 38px;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-word;
}
.theme.caption .theme__link {
  height: 100%;
  display: flex;
}
.theme.caption .theme__link > div {
  width: 100%;
}
.theme.caption .theme__name {
  width: 100%;
  height: 100%;
  text-align: left;
  cursor: default;
  flex-direction: column;
  align-items: start;
  justify-content: initial;
  overflow: auto;
}
.theme.caption .caption-wrapper {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.theme figure.imgleft {
  display: flex;
  flex-direction: row;
}
.theme .theme__image {
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: calc(100% - 38px);
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.theme figure.imgleft .theme__image {
  width: 30%;
  height: 100%;
}
.theme figure.imgleft .theme__name {
  height: 100%;
  width: 70%;
  justify-content: flex-start;
}
.theme.circle .theme__image {
  height: 70%;
}
.theme.circle .theme__image img {
  height: 70%;
}
.theme.circle.height2 .theme__image,
.theme.circle.height1 .theme__image {
  height: 50%;
}
.theme.circle .theme__name {
  height: 30%;
}
.theme.circle.height2 .theme__name,
.theme.circle.height1 .theme__name {
  height: 50%;
}
.theme .theme__image img,
.theme .theme__image object {
  max-width: 100%;
  max-height: 100%;
}
.theme .theme__image.start {
  justify-content: flex-start;
}
.theme .theme__image.end {
  justify-content: flex-end;
}
.theme .theme__image img.start,
.theme .theme__image object.start {
  align-self: flex-start;
}
.theme .theme__image img.end,
.theme .theme__image object.end {
  align-self: flex-end;
}
.theme .theme__image object {
  width: 100%;
  pointer-events: none;
}
.theme.line .theme__image object {
  width: auto;
  height: 100%;
}
.theme a {
  text-decoration: none;
  height: 100%;
}
.theme--animated-icon .theme__link .theme__image img {
  width: 60%;
  margin: 20%;
  transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
}
.theme--animated-icon .theme__link:hover .theme__image img,
.theme--animated-icon .theme__link:focus .theme__image img {
  transform: scale(1.05);
  transition: all 0.35s cubic-bezier(0.24, 0.11, 0.12, 1.69);
  -ms-transition: all 0.35s cubic-bezier(0.24, 0.11, 0.12, 1.69);
}
@media (prefers-reduced-motion: reduce) {
  .theme--animated-icon .theme__link:hover .theme__image img,
  .theme--animated-icon .theme__link:focus .theme__image img,
  .theme--animated-icon .theme__link .theme__image img {
    transition: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
  }
}
.theme:hover {
  cursor: pointer;
}
.theme.active {
  cursor: default;
}
.theme.no-click a {
  cursor: default;
}
body.kbe .theme__link:focus div.name_wrapper {
  border: 2px solid #00f;
  text-decoration: underline !important;
}
figure {
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.themeseparator {
  clear: both;
  width: 100%;
  border-bottom: 2px solid gray;
}
.theme.js-plus-btn {
  border: 1px solid #005a96;
}
div.theme__information__popup__overlay {
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
div.theme__information__popup__content {
  margin-top: 1rem;
  width: 60%;
  top: 5%;
  left: 20%;
  overflow: auto;
  color: #fff;
}
div.theme__information__popup__content {
  position: absolute;
  z-index: 100;
  min-height: 300px;
  background: #fff;
  padding: 10px;
  opacity: 1;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.75);
  text-align: left;
  color: #000;
}
.popupclose {
  position: absolute;
  top: 11px;
  right: 13px;
  cursor: pointer;
  z-index: 999;
  border: none;
  background: transparent;
}
div.extra-info-overlay {
  position: absolute;
  box-sizing: border-box;
  z-index: 10;
  height: 0;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.75);
}
div.extra-info-overlay.info-open {
  overflow: visible;
}
div.extra-info-overlay div.extra-info-content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px;
  margin: 0 20px 20px 20px;
  font-size: 12px;
}
div.extra-info-overlay div.extra-info-content > p {
  margin: 0;
  white-space: pre-line;
}
div.extra-info-overlay div.extra-info-content > ul {
  margin: 0;
  padding-left: 1rem;
}
div.extra-info-overlay div.extra-info-content a {
  word-break: break-word;
}
@-webkit-keyframes horizontal-slide {
  from {
    transform: scale(0, 1);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes horizontal-slide {
  from {
    transform: scale(0, 1);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes vertical-slide {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@keyframes vertical-slide {
  from {
    transform: scale(1, 0);
  }
  to {
    transform: scale(1, 1);
  }
}
@-webkit-keyframes horizontal-collapse {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(0, 1);
  }
}
@keyframes horizontal-collapse {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(0, 1);
  }
}
@-webkit-keyframes vertical-collapse {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1, 0);
  }
}
@keyframes vertical-collapse {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1, 0);
  }
}
.add-text-my-mosaic {
  position: absolute;
  right: 0;
  margin-right: -200px;
  margin-top: 9px;
  border: none;
  color: #fff;
  padding: 15px 10px;
}
.add-text-my-mosaic span {
  margin-left: 5px;
}
.add-text-my-mosaic:hover {
  cursor: pointer;
}
@media only screen and (min-width: 1px) and (max-width: 1830px) {
  .sidebar .add-text-my-mosaic {
    margin-right: -50px;
    padding: 15px;
  }
  .sidebar .add-text-my-mosaic span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1550px) {
  .add-text-my-mosaic {
    margin-right: -50px;
    padding: 15px;
  }
  .add-text-my-mosaic span {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1516px) {
  .sidebar .add-text-my-mosaic {
    margin-top: -50px;
    margin-right: 0;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1400px) {
  body:not(.swing-stories) #dashboardwrapper.sidebar {
    max-width: 1140px;
    display: block;
  }
  body:not(.swing-stories) #dashboardwrapper.sidebar .dashboard-header {
    margin-left: auto;
  }
  body:not(.swing-stories) #dashboardwrapper.sidebar .wrapper-sidebar {
    position: fixed;
    background: transparent;
    width: 30px;
    box-shadow: 4px 0 15px -7px rgba(0, 0, 0, 0.48);
    margin-top: -100px;
    left: 0;
  }
  body:not(.swing-stories) #dashboardwrapper.sidebar .wrapper-sidebar .sidebar-menu-bar {
    display: block;
    z-index: 6;
    position: relative;
  }
  body:not(.swing-stories) #dashboardwrapper.sidebar .sidebar-content {
    display: none;
  }
  body:not(.swing-stories) .wrapper-sidebar .theme-link i.fa-triangle {
    line-height: 12px;
  }
  body:not(.swing-stories) .themeview #dashboardwrapper.sidebar .wrapper-sidebar,
  #dashboardwrapper.sidebar .wrapper-sidebar {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1300px) {
  button.themenavbutton {
    display: none;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1250px) {
  .add-text-my-mosaic {
    margin-top: -50px;
    margin-right: 0;
  }
  .detailview-dialog {
    width: 90%;
    margin-left: -45%;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1200px) {
  #dashboardwrapper.sidebar {
    width: 1140px;
    display: block;
  }
  #dashboardwrapper.sidebar .wrapper-sidebar {
    position: fixed;
    background: transparent;
    width: 30px;
    box-shadow: 4px 0 15px -7px rgba(0, 0, 0, 0.48);
    margin-top: -100px;
    left: 0;
  }
  #dashboardwrapper.sidebar .wrapper-sidebar.expanded {
    max-height: 100vh;
  }
  #dashboardwrapper.sidebar .wrapper-sidebar .sidebar-menu-bar {
    display: block;
    z-index: 6;
    position: relative;
  }
  #dashboardwrapper.sidebar .sidebar-content {
    display: none;
    overflow-y: auto;
    max-height: calc(100vh - 65px);
  }
  .wrapper-sidebar .theme-link i.fa-triangle {
    line-height: 12px;
  }
  .themeview #dashboardwrapper.sidebar .wrapper-sidebar,
  #dashboardwrapper.sidebar .wrapper-sidebar {
    padding-top: 0;
  }
  body.swing-stories #dashboardwrapper.sidebar {
    width: 100%;
  }
  body.swing-stories #dashboardwrapper.sidebar .wrapper-main {
    margin: auto;
  }
  #dashboardwrapper.sidebar .wrapper-sidebar:not(.expanded) .sidebar-content {
    width: 0;
    display: block;
    height: 0;
    padding: 0;
  }
  #dashboardwrapper.sidebar .wrapper-sidebar.expanded .sidebar-content {
    display: block;
    padding: 15px;
    font-size: 15px;
    transition: width 0.1s ease-in-out;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1180px) {
  #dashboard-container {
    margin-bottom: 40px;
  }
  .mosaic-header #seltool {
    padding-left: 6px;
    padding-right: 6px;
  }
  .dialogContainer {
    padding-left: 10px;
    padding-right: 10px;
  }
  #themaView,
  #detailView,
  #dashboardwrapper,
  #dashboardwrapper.sidebar,
  #dashboardwrapper.transparent-background,
  #dashboardviews,
  #dashboardwrapper.sidebar .wrapper-main,
  #dashboardView {
    width: 100%;
    margin-left: 0;
  }
  #dashboardwrapper.transparent-background {
    padding: 0;
  }
  #themaView {
    padding: 6px;
    box-sizing: border-box;
  }
  #UserInfo {
    padding: 6px;
  }
  section.tile div.freecontent {
    overflow-y: auto;
  }
  .close-button {
    margin-right: 8px;
  }
  .sel-header-controls .close-button,
  .selection .navbutton.mosaic-search {
    margin-right: -3px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1156px) {
  section.tile .datavalue,
  section.tile.height2 .datavalue {
    font-size: 28px;
  }
}
@media only screen and (min-width: 1023px) and (max-width: 1099px) {
  section.tile .datavalue,
  section.tile.height2 .datavalue {
    font-size: 24px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1024px) {
  a.hover > .editor-tile-actions,
  div.hover > .editor-tile-actions,
  a:hover > .editor-tile-actions,
  div:hover > .editor-tile-actions {
    display: none;
  }
  #dashboardwrapper,
  #dashboardwrapper.sidebar {
    min-height: 200px;
  }
  .searchArea .searchInput {
    width: 200px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 1023px) {
  .grid-section {
    display: block;
  }
  .dashblock.colRight,
  .dashblock.colLeft,
  .dashblock.colFull.single {
    width: 100%;
    margin: 0;
    border: 0;
  }
  .dashblock.colRight,
  .themasummary .colRight {
    padding-left: 0;
  }
  .dashblock.colLeft,
  .themasummary .colLeft {
    padding-right: 0;
  }
  .tile__meta {
    z-index: 9;
  }
  .tile:hover {
    border-color: transparent !important;
  }
  .title-holder {
    font-size: 1.1rem;
  }
  .tile-action-btn {
    padding: 5px;
    padding-top: 20px;
  }
  section.tile.width1 {
    grid-column-end: span 2;
  }
  section.tile.free div.tile-content header,
  section.tile.free h2,
  section.tile.free h3.tile-header {
    max-height: none;
  }
  .editor-tile-actions.right {
    display: none !important;
  }
  .searchArea .searchInput {
    width: 200px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 960px) {
  div.popup-detailview-container {
    width: 98%;
    margin-left: -49%;
  }
  .popup-detailview-container .header .popup-title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 880px) {
  body.swing-stories #dashboardwrapper.sidebar .wrapper-main,
  body.swing-stories #dashboardwrapper.sidebar {
    width: 100%;
    position: relative;
  }
  body.swing-stories #breadcrumb-container {
    height: 100%;
  }
  body.swing-stories h1.trail-title {
    padding: 0 90px 0 40px;
  }
  body.swing-stories .dashboard-icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
  }
  body.swing-stories #themaView {
    padding: 6px 40px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 850px) {
  #selectorLabel .o-innerCell {
    text-align: left;
  }
  .current-theme .themename {
    font-size: 30px;
  }
  .search-area .search-wrapper {
    padding: 0 7px;
    margin: 0;
    max-width: 100%;
    box-sizing: border-box;
  }
  .detailview-dialog .tab-body {
    padding-left: 0;
    padding-right: 0;
  }
  .detailview-dialog .extra-info {
    margin-left: -31px;
  }
  .detailview-dialog .sidebar-background {
    width: 96px;
  }
  .sel-listitem,
  button.ref-sel {
    font-size: 18px;
    padding: 8px 5px 8px 0;
  }
  #seldialog.reference .sel-header {
    height: auto;
    height: initial;
  }
  .searchinput {
    height: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .tile-action-btn.detailview {
    display: none;
  }
  .tile-action-btn.detailview-table {
    display: block;
  }
}
@media only screen and (min-height: 1px) and (max-height: 750px) {
  .detailview-dialog .detailview-tabs {
    height: 18px;
  }
}
@media only screen and (min-height: 1px) and (max-height: 700px) {
  .tile-detail-wrapper > .tab-body {
    height: 300px;
    min-height: 300px;
  }
  .detailview-chart-tabs,
  .chart-tab-content,
  .detailview-linkbar,
  .detailview-chart-tab {
    height: 270px;
    min-height: 270px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .chart-tab-content section.tile,
  .chart-tab-content section.tile div.inner-tile,
  .chart-tab-content section.tile.chart div.content {
    height: 270px !important;
  }
  .detailview-tab > .tab-content > .more-info-content {
    max-height: 270px;
  }
  div .popup-detailview-container {
    margin-top: -220px;
  }
}
@media screen and (max-width: 1100px) {
  .theme.width2,
  .theme.width3,
  .theme.width4 {
    grid-column-end: span 4;
  }
  .theme.width5,
  .theme.width6,
  .theme.width7,
  .theme.width8 {
    grid-column-end: span 6;
  }
  .theme.width9,
  .theme.width10,
  .theme.width11,
  .theme.width12 {
    grid-column-end: span 12;
  }
}
@media only screen and (min-width: 1px) and (max-width: 593px) {
  #dashboardView .theme {
    margin: 6px;
    margin-top: 10px;
    margin-bottom: 0;
  }
  #dashboardView .theme:not(.caption) {
    grid-row-end: span 2;
    grid-column-end: span 12;
  }
  #dashboardView .theme.number {
    margin-left: 25px;
    grid-row-end: span 3;
  }
  .theme:not(.caption):not(.circle):not(.number) figure {
    flex-direction: row;
  }
  .theme.line figure {
    align-items: center;
  }
  .theme.line .graphic-line {
    display: none;
  }
  .theme:not(.circle):not(.number) .theme__name {
    height: 100%;
    align-items: center;
  }
  .theme:not(.circle):not(.number) .theme__image {
    width: 20%;
    height: 100%;
  }
  .theme.line .theme__image {
    padding: 0 20px;
  }
  section.tile header h2,
  section.tile header h3 {
    white-space: normal;
    text-overflow: unset;
    overflow: hidden;
    height: auto;
    max-height: 66px;
  }
  div.theme__information__popup__content {
    top: 10px;
  }
  .searchArea .searchInput {
    width: 250px;
  }
  .current-theme .themename {
    font-size: 20px;
  }
  .selection-buttons {
    display: block;
  }
  .selcontainer {
    display: inline-block;
  }
  .mosaic-header {
    position: static;
  }
  #seltool {
    position: relative;
  }
  #seltool.slidein {
    box-sizing: border-box;
    width: 100vw;
  }
  .seltool-togglemobile {
    display: block;
    line-height: 58px;
  }
  #dashboardSearchCloseButton {
    line-height: 58px;
  }
  .mosaic-header {
    padding-top: 0;
  }
  .mosaic-header.mobile {
    position: absolute;
  }
  .sel-mobile-footer {
    display: block;
  }
  .close-button,
  .close-mobile-button {
    width: 150px;
  }
  .sel-viewtype {
    display: none;
  }
  .sel-header-controls {
    width: 10%;
  }
  .sel-header-title {
    width: 90%;
  }
  .themeselection {
    display: block;
  }
  #seltool-togglemobile button {
    font-size: 20px;
  }
  .selection-buttons {
    background-color: #fff;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    width: 100vw;
    z-index: 80;
    position: absolute;
    left: -100vw;
    transition-duration: 1s;
    box-shadow: rgba(0, 0, 0, 0) 5px 5px 5px;
    overflow-y: auto;
  }
  .selection-buttons.slidein {
    left: 0;
    transition-duration: 1s;
    box-shadow: rgba(0, 0, 0, 0.5) 5px 5px 5px;
  }
  .selection-buttons #compareSelText:not(.u-hidden) {
    display: inline-flex;
    float: none;
    align-items: center;
    line-height: normal;
    position: relative;
    top: -1px;
    font-size: 16px;
  }
  .noreference-toggle-container.noreference-toggle-container--visible {
    display: inline-flex;
    margin-bottom: 10px;
  }
  .search-area.search-area-expanded {
    position: absolute;
    top: 0;
    height: 58px;
  }
  .search-area.search-area-expanded #searchcontainer {
    margin: 0;
  }
  .search-area.search-area-expanded,
  #searchcontainer-ac-s-container,
  #dashboardSearchCloseButton {
    margin-top: 0 !important;
  }
  .search-area .search-wrapper {
    margin: 2px 0 2px 0;
  }
  .search-area .search-wrapper .search-bar {
    width: calc(100% - 46px);
  }
  .search-area .searchInput {
    width: calc(100% - 37px);
    float: left;
    height: 40px;
  }
  .search-area .search-wrapper .search-btn {
    width: 40px;
    float: left;
    text-align: right;
    padding-left: 0;
    height: 40px;
  }
  .search-area .search-wrapper .navbutton {
    line-height: 54px;
  }
  .search-close-btn,
  #searchToggle,
  button.edit-ugg,
  button.delete-ugg {
    width: 40px;
    height: 40px;
  }
  button.edit-ugg,
  button.delete-ugg {
    font-size: 25px;
  }
  .navbutton.mosaic-search {
    position: absolute;
    right: 0;
    top: 0;
  }
  .searchAutoComplete {
    top: 46px;
  }
  .dashboard-header {
    margin-top: 0;
  }
  .sel-list,
  .sel-selection.no-level .sel-list,
  .sel-list:not(.level),
  #dashboardwrapper.sidebar div.sel-list {
    width: 100vw;
    padding: 0 10px;
    background-color: #fff;
    border-right: none;
    overflow-y: auto;
  }
  .sel-list-content {
    padding-right: 0;
  }
  .usergeogroup-form-input {
    width: 475px;
  }
  .usergeogroup-form-input {
    margin-left: 100vw;
  }
  .usergeogroup-form-input input.ugg-name {
    width: 55%;
  }
  .usergeogroup-form-input button.save-ugg {
    width: 25%;
    margin-right: 20px;
  }
  .sel-selection {
    position: absolute;
    transition-duration: 1s;
    left: 0;
    width: 400vw;
  }
  .u-hidden .sel-selection {
    left: 100vw;
  }
  .sel-selection.step1 {
    left: 0;
  }
  .sel-selection.step2,
  .sel-selection.step3:not(.filterstep) {
    left: -100vw;
  }
  .sel-selection.filterstep.step3 {
    left: -200vw;
  }
  .sel-selection.step4 {
    left: -200vw;
  }
  .sel-selection.step5 {
    left: -300vw;
  }
  .sel-footer {
    bottom: 0;
    position: absolute;
    padding-right: 13px;
    width: 100vw;
    box-sizing: border-box;
    height: 65px;
  }
  #seldialog {
    overflow: hidden;
    width: 100vw;
    box-sizing: border-box;
  }
  #dashboardwrapper.sidebar #seldialog.sidenav {
    z-index: 100;
  }
  .sel-button,
  .theme-button {
    width: 100%;
  }
  .selcontainer {
    display: block;
  }
  #breadcrumb-container,
  .dashboard-icon-wrapper,
  div.breadcrumb_util,
  .dashboard-icon-wrapper .navbutton,
  .social-utils.navbutton {
    height: 32px;
    font-size: 14px;
    line-height: 32px;
  }
  .dashboard-icon-wrapper i {
    font-size: 14px;
    line-height: 32px;
  }
  .trail-title {
    margin: 0;
  }
  .mosaic-scroll-bar.vertical {
    width: 20px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 593px) and (max-height: 320px) {
  .sel-footer {
    height: 45px;
    padding-top: 0;
    padding-bottom: 0;
  }
  #seldialog.reference .sel-header {
    height: 60px;
    line-height: 20px;
  }
}
@media only screen and (min-width: 1px) and (max-width: 550px) {
  .searchArea .searchInput {
    width: 250px;
  }
  .add-text-my-mosaic {
    display: none;
  }
}
@media only screen and (min-width: 1px) and (max-width: 550px) {
  .submitSearchBtn:not([disabled]) {
    max-width: 65%;
  }
}
.u-f-l {
  float: left;
}
.u-f-r {
  float: right;
}
.u-clearfix,
.clear {
  clear: both;
}
.u-hidden {
  display: none;
}
.u-d-ib {
  display: inline-block;
}
.u-d-b {
  display: block;
}
.u-hiddenbutvisible,
.tile__rawdata {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 200px;
  height: 100px;
  overflow: hidden;
}
.u-o-hidden {
  overflow: hidden;
}
.u-t-left {
  text-align: left !important;
}
.u-t-center {
  text-align: center !important;
}
.u-t-right {
  text-align: right !important;
}
.u-p-r-10 {
  padding-right: 10px;
}
.u-p-l-10 {
  padding-left: 10px;
}
.u-r-0 {
  right: 0;
}
.ui-dialog.no-title div.ui-dialog-titlebar {
  display: none;
}
.fontdef {
  font-family: 'Source Sans Pro', sans-serif;
}
.fontbold {
  font-weight: bold;
}
.fontbold span {
  font-weight: normal;
}
.fontlight {
  font-weight: 300;
}
.underline {
  text-decoration: underline;
}
.u-warning {
  color: #dc143c;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-20p {
  margin-top: 20%;
}
.mt-30p {
  margin-top: 30%;
}
.ml-10 {
  margin-left: 10px;
}
.width100perc {
  width: 100% !important;
}
.width30perc {
  width: 30% !important;
}
.width70perc {
  width: 70% !important;
}
.width40perc {
  width: 40% !important;
}
.width60perc {
  width: 60% !important;
}
.height100perc {
  height: 100% !important;
}
.height15px {
  height: 15px;
}
.u-o-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.u-relative {
  position: relative;
}
i .shadow {
  text-shadow: 0 0 7px #000;
}
a.nolink {
  text-decoration: none;
  color: inherit;
}
.noscroll {
  overflow: hidden;
}
.noscroll.fixed {
  position: fixed;
  overflow-y: scroll;
  width: 100%;
}
ul.listclean {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sizechecker {
  line-height: 16px;
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 1;
}
.pop {
  -webkit-animation: pop 0.3s linear 1;
          animation: pop 0.3s linear 1;
}
@-webkit-keyframes pop {
  50% {
    transform: scale(1.05);
  }
}
@keyframes pop {
  50% {
    transform: scale(1.05);
  }
}
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8);
}
@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  -webkit-filter: none;
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
.fa-500px:before {
  content: '';
}
.fa-abacus:before {
  content: '';
}
.fa-accessible-icon:before {
  content: '';
}
.fa-accusoft:before {
  content: '';
}
.fa-acorn:before {
  content: '';
}
.fa-acquisitions-incorporated:before {
  content: '';
}
.fa-ad:before {
  content: '';
}
.fa-address-book:before {
  content: '';
}
.fa-address-card:before {
  content: '';
}
.fa-adjust:before {
  content: '';
}
.fa-adn:before {
  content: '';
}
.fa-adobe:before {
  content: '';
}
.fa-adversal:before {
  content: '';
}
.fa-affiliatetheme:before {
  content: '';
}
.fa-air-freshener:before {
  content: '';
}
.fa-airbnb:before {
  content: '';
}
.fa-alarm-clock:before {
  content: '';
}
.fa-algolia:before {
  content: '';
}
.fa-alicorn:before {
  content: '';
}
.fa-align-center:before {
  content: '';
}
.fa-align-justify:before {
  content: '';
}
.fa-align-left:before {
  content: '';
}
.fa-align-right:before {
  content: '';
}
.fa-alipay:before {
  content: '';
}
.fa-allergies:before {
  content: '';
}
.fa-amazon:before {
  content: '';
}
.fa-amazon-pay:before {
  content: '';
}
.fa-ambulance:before {
  content: '';
}
.fa-american-sign-language-interpreting:before {
  content: '';
}
.fa-amilia:before {
  content: '';
}
.fa-analytics:before {
  content: '';
}
.fa-anchor:before {
  content: '';
}
.fa-android:before {
  content: '';
}
.fa-angel:before {
  content: '';
}
.fa-angellist:before {
  content: '';
}
.fa-angle-double-down:before {
  content: '';
}
.fa-angle-double-left:before {
  content: '';
}
.fa-angle-double-right:before {
  content: '';
}
.fa-angle-double-up:before {
  content: '';
}
.fa-angle-down:before {
  content: '';
}
.fa-angle-left:before {
  content: '';
}
.fa-angle-right:before {
  content: '';
}
.fa-angle-up:before {
  content: '';
}
.fa-angry:before {
  content: '';
}
.fa-angrycreative:before {
  content: '';
}
.fa-angular:before {
  content: '';
}
.fa-ankh:before {
  content: '';
}
.fa-app-store:before {
  content: '';
}
.fa-app-store-ios:before {
  content: '';
}
.fa-apper:before {
  content: '';
}
.fa-apple:before {
  content: '';
}
.fa-apple-alt:before {
  content: '';
}
.fa-apple-crate:before {
  content: '';
}
.fa-apple-pay:before {
  content: '';
}
.fa-archive:before {
  content: '';
}
.fa-archway:before {
  content: '';
}
.fa-arrow-alt-circle-down:before {
  content: '';
}
.fa-arrow-alt-circle-left:before {
  content: '';
}
.fa-arrow-alt-circle-right:before {
  content: '';
}
.fa-arrow-alt-circle-up:before {
  content: '';
}
.fa-arrow-alt-down:before {
  content: '';
}
.fa-arrow-alt-from-bottom:before {
  content: '';
}
.fa-arrow-alt-from-left:before {
  content: '';
}
.fa-arrow-alt-from-right:before {
  content: '';
}
.fa-arrow-alt-from-top:before {
  content: '';
}
.fa-arrow-alt-left:before {
  content: '';
}
.fa-arrow-alt-right:before {
  content: '';
}
.fa-arrow-alt-square-down:before {
  content: '';
}
.fa-arrow-alt-square-left:before {
  content: '';
}
.fa-arrow-alt-square-right:before {
  content: '';
}
.fa-arrow-alt-square-up:before {
  content: '';
}
.fa-arrow-alt-to-bottom:before {
  content: '';
}
.fa-arrow-alt-to-left:before {
  content: '';
}
.fa-arrow-alt-to-right:before {
  content: '';
}
.fa-arrow-alt-to-top:before {
  content: '';
}
.fa-arrow-alt-up:before {
  content: '';
}
.fa-arrow-circle-down:before {
  content: '';
}
.fa-arrow-circle-left:before {
  content: '';
}
.fa-arrow-circle-right:before {
  content: '';
}
.fa-arrow-circle-up:before {
  content: '';
}
.fa-arrow-down:before {
  content: '';
}
.fa-arrow-from-bottom:before {
  content: '';
}
.fa-arrow-from-left:before {
  content: '';
}
.fa-arrow-from-right:before {
  content: '';
}
.fa-arrow-from-top:before {
  content: '';
}
.fa-arrow-left:before {
  content: '';
}
.fa-arrow-right:before {
  content: '';
}
.fa-arrow-square-down:before {
  content: '';
}
.fa-arrow-square-left:before {
  content: '';
}
.fa-arrow-square-right:before {
  content: '';
}
.fa-arrow-square-up:before {
  content: '';
}
.fa-arrow-to-bottom:before {
  content: '';
}
.fa-arrow-to-left:before {
  content: '';
}
.fa-arrow-to-right:before {
  content: '';
}
.fa-arrow-to-top:before {
  content: '';
}
.fa-arrow-up:before {
  content: '';
}
.fa-arrows:before {
  content: '';
}
.fa-arrows-alt:before {
  content: '';
}
.fa-arrows-alt-h:before {
  content: '';
}
.fa-arrows-alt-v:before {
  content: '';
}
.fa-arrows-h:before {
  content: '';
}
.fa-arrows-v:before {
  content: '';
}
.fa-artstation:before {
  content: '';
}
.fa-assistive-listening-systems:before {
  content: '';
}
.fa-asterisk:before {
  content: '';
}
.fa-asymmetrik:before {
  content: '';
}
.fa-at:before {
  content: '';
}
.fa-atlas:before {
  content: '';
}
.fa-atlassian:before {
  content: '';
}
.fa-atom:before {
  content: '';
}
.fa-atom-alt:before {
  content: '';
}
.fa-audible:before {
  content: '';
}
.fa-audio-description:before {
  content: '';
}
.fa-autoprefixer:before {
  content: '';
}
.fa-avianex:before {
  content: '';
}
.fa-aviato:before {
  content: '';
}
.fa-award:before {
  content: '';
}
.fa-aws:before {
  content: '';
}
.fa-axe:before {
  content: '';
}
.fa-axe-battle:before {
  content: '';
}
.fa-baby:before {
  content: '';
}
.fa-baby-carriage:before {
  content: '';
}
.fa-backpack:before {
  content: '';
}
.fa-backspace:before {
  content: '';
}
.fa-backward:before {
  content: '';
}
.fa-bacon:before {
  content: '';
}
.fa-badge:before {
  content: '';
}
.fa-badge-check:before {
  content: '';
}
.fa-badge-dollar:before {
  content: '';
}
.fa-badge-percent:before {
  content: '';
}
.fa-badger-honey:before {
  content: '';
}
.fa-balance-scale:before {
  content: '';
}
.fa-balance-scale-left:before {
  content: '';
}
.fa-balance-scale-right:before {
  content: '';
}
.fa-ball-pile:before {
  content: '';
}
.fa-ballot:before {
  content: '';
}
.fa-ballot-check:before {
  content: '';
}
.fa-ban:before {
  content: '';
}
.fa-band-aid:before {
  content: '';
}
.fa-bandcamp:before {
  content: '';
}
.fa-barcode:before {
  content: '';
}
.fa-barcode-alt:before {
  content: '';
}
.fa-barcode-read:before {
  content: '';
}
.fa-barcode-scan:before {
  content: '';
}
.fa-bars:before {
  content: '';
}
.fa-baseball:before {
  content: '';
}
.fa-baseball-ball:before {
  content: '';
}
.fa-basketball-ball:before {
  content: '';
}
.fa-basketball-hoop:before {
  content: '';
}
.fa-bat:before {
  content: '';
}
.fa-bath:before {
  content: '';
}
.fa-battery-bolt:before {
  content: '';
}
.fa-battery-empty:before {
  content: '';
}
.fa-battery-full:before {
  content: '';
}
.fa-battery-half:before {
  content: '';
}
.fa-battery-quarter:before {
  content: '';
}
.fa-battery-slash:before {
  content: '';
}
.fa-battery-three-quarters:before {
  content: '';
}
.fa-battle-net:before {
  content: '';
}
.fa-bed:before {
  content: '';
}
.fa-beer:before {
  content: '';
}
.fa-behance:before {
  content: '';
}
.fa-behance-square:before {
  content: '';
}
.fa-bell:before {
  content: '';
}
.fa-bell-school:before {
  content: '';
}
.fa-bell-school-slash:before {
  content: '';
}
.fa-bell-slash:before {
  content: '';
}
.fa-bells:before {
  content: '';
}
.fa-bezier-curve:before {
  content: '';
}
.fa-bible:before {
  content: '';
}
.fa-bicycle:before {
  content: '';
}
.fa-bimobject:before {
  content: '';
}
.fa-binoculars:before {
  content: '';
}
.fa-biohazard:before {
  content: '';
}
.fa-birthday-cake:before {
  content: '';
}
.fa-bitbucket:before {
  content: '';
}
.fa-bitcoin:before {
  content: '';
}
.fa-bity:before {
  content: '';
}
.fa-black-tie:before {
  content: '';
}
.fa-blackberry:before {
  content: '';
}
.fa-blanket:before {
  content: '';
}
.fa-blender:before {
  content: '';
}
.fa-blender-phone:before {
  content: '';
}
.fa-blind:before {
  content: '';
}
.fa-blog:before {
  content: '';
}
.fa-blogger:before {
  content: '';
}
.fa-blogger-b:before {
  content: '';
}
.fa-bluetooth:before {
  content: '';
}
.fa-bluetooth-b:before {
  content: '';
}
.fa-bold:before {
  content: '';
}
.fa-bolt:before {
  content: '';
}
.fa-bomb:before {
  content: '';
}
.fa-bone:before {
  content: '';
}
.fa-bone-break:before {
  content: '';
}
.fa-bong:before {
  content: '';
}
.fa-book:before {
  content: '';
}
.fa-book-alt:before {
  content: '';
}
.fa-book-dead:before {
  content: '';
}
.fa-book-heart:before {
  content: '';
}
.fa-book-medical:before {
  content: '';
}
.fa-book-open:before {
  content: '';
}
.fa-book-reader:before {
  content: '';
}
.fa-book-spells:before {
  content: '';
}
.fa-book-user:before {
  content: '';
}
.fa-bookmark:before {
  content: '';
}
.fa-books:before {
  content: '';
}
.fa-books-medical:before {
  content: '';
}
.fa-boot:before {
  content: '';
}
.fa-booth-curtain:before {
  content: '';
}
.fa-bootstrap:before {
  content: '';
}
.fa-bow-arrow:before {
  content: '';
}
.fa-bowling-ball:before {
  content: '';
}
.fa-bowling-pins:before {
  content: '';
}
.fa-box:before {
  content: '';
}
.fa-box-alt:before {
  content: '';
}
.fa-box-ballot:before {
  content: '';
}
.fa-box-check:before {
  content: '';
}
.fa-box-fragile:before {
  content: '';
}
.fa-box-full:before {
  content: '';
}
.fa-box-heart:before {
  content: '';
}
.fa-box-open:before {
  content: '';
}
.fa-box-up:before {
  content: '';
}
.fa-box-usd:before {
  content: '';
}
.fa-boxes:before {
  content: '';
}
.fa-boxes-alt:before {
  content: '';
}
.fa-boxing-glove:before {
  content: '';
}
.fa-brackets:before {
  content: '';
}
.fa-brackets-curly:before {
  content: '';
}
.fa-braille:before {
  content: '';
}
.fa-brain:before {
  content: '';
}
.fa-bread-loaf:before {
  content: '';
}
.fa-bread-slice:before {
  content: '';
}
.fa-briefcase:before {
  content: '';
}
.fa-briefcase-medical:before {
  content: '';
}
.fa-broadcast-tower:before {
  content: '';
}
.fa-broom:before {
  content: '';
}
.fa-browser:before {
  content: '';
}
.fa-brush:before {
  content: '';
}
.fa-btc:before {
  content: '';
}
.fa-buffer:before {
  content: '';
}
.fa-bug:before {
  content: '';
}
.fa-building:before {
  content: '';
}
.fa-bullhorn:before {
  content: '';
}
.fa-bullseye:before {
  content: '';
}
.fa-bullseye-arrow:before {
  content: '';
}
.fa-bullseye-pointer:before {
  content: '';
}
.fa-burn:before {
  content: '';
}
.fa-buromobelexperte:before {
  content: '';
}
.fa-burrito:before {
  content: '';
}
.fa-bus:before {
  content: '';
}
.fa-bus-alt:before {
  content: '';
}
.fa-bus-school:before {
  content: '';
}
.fa-business-time:before {
  content: '';
}
.fa-buysellads:before {
  content: '';
}
.fa-cabinet-filing:before {
  content: '';
}
.fa-calculator:before {
  content: '';
}
.fa-calculator-alt:before {
  content: '';
}
.fa-calendar:before {
  content: '';
}
.fa-calendar-alt:before {
  content: '';
}
.fa-calendar-check:before {
  content: '';
}
.fa-calendar-day:before {
  content: '';
}
.fa-calendar-edit:before {
  content: '';
}
.fa-calendar-exclamation:before {
  content: '';
}
.fa-calendar-minus:before {
  content: '';
}
.fa-calendar-plus:before {
  content: '';
}
.fa-calendar-star:before {
  content: '';
}
.fa-calendar-times:before {
  content: '';
}
.fa-calendar-week:before {
  content: '';
}
.fa-camera:before {
  content: '';
}
.fa-camera-alt:before {
  content: '';
}
.fa-camera-retro:before {
  content: '';
}
.fa-campfire:before {
  content: '';
}
.fa-campground:before {
  content: '';
}
.fa-canadian-maple-leaf:before {
  content: '';
}
.fa-candle-holder:before {
  content: '';
}
.fa-candy-cane:before {
  content: '';
}
.fa-candy-corn:before {
  content: '';
}
.fa-cannabis:before {
  content: '';
}
.fa-capsules:before {
  content: '';
}
.fa-car:before {
  content: '';
}
.fa-car-alt:before {
  content: '';
}
.fa-car-battery:before {
  content: '';
}
.fa-car-bump:before {
  content: '';
}
.fa-car-crash:before {
  content: '';
}
.fa-car-garage:before {
  content: '';
}
.fa-car-mechanic:before {
  content: '';
}
.fa-car-side:before {
  content: '';
}
.fa-car-tilt:before {
  content: '';
}
.fa-car-wash:before {
  content: '';
}
.fa-caret-circle-down:before {
  content: '';
}
.fa-caret-circle-left:before {
  content: '';
}
.fa-caret-circle-right:before {
  content: '';
}
.fa-caret-circle-up:before {
  content: '';
}
.fa-caret-down:before {
  content: '';
}
.fa-caret-left:before {
  content: '';
}
.fa-caret-right:before {
  content: '';
}
.fa-caret-square-down:before {
  content: '';
}
.fa-caret-square-left:before {
  content: '';
}
.fa-caret-square-right:before {
  content: '';
}
.fa-caret-square-up:before {
  content: '';
}
.fa-caret-up:before {
  content: '';
}
.fa-carrot:before {
  content: '';
}
.fa-cart-arrow-down:before {
  content: '';
}
.fa-cart-plus:before {
  content: '';
}
.fa-cash-register:before {
  content: '';
}
.fa-cat:before {
  content: '';
}
.fa-cauldron:before {
  content: '';
}
.fa-cc-amazon-pay:before {
  content: '';
}
.fa-cc-amex:before {
  content: '';
}
.fa-cc-apple-pay:before {
  content: '';
}
.fa-cc-diners-club:before {
  content: '';
}
.fa-cc-discover:before {
  content: '';
}
.fa-cc-jcb:before {
  content: '';
}
.fa-cc-mastercard:before {
  content: '';
}
.fa-cc-paypal:before {
  content: '';
}
.fa-cc-stripe:before {
  content: '';
}
.fa-cc-visa:before {
  content: '';
}
.fa-centercode:before {
  content: '';
}
.fa-centos:before {
  content: '';
}
.fa-certificate:before {
  content: '';
}
.fa-chair:before {
  content: '';
}
.fa-chair-office:before {
  content: '';
}
.fa-chalkboard:before {
  content: '';
}
.fa-chalkboard-teacher:before {
  content: '';
}
.fa-charging-station:before {
  content: '';
}
.fa-chart-area:before {
  content: '';
}
.fa-chart-bar:before {
  content: '';
}
.fa-chart-line:before {
  content: '';
}
.fa-chart-line-down:before {
  content: '';
}
.fa-chart-network:before {
  content: '';
}
.fa-chart-pie:before {
  content: '';
}
.fa-chart-pie-alt:before {
  content: '';
}
.fa-chart-scatter:before {
  content: '';
}
.fa-check:before {
  content: '';
}
.fa-check-circle:before {
  content: '';
}
.fa-check-double:before {
  content: '';
}
.fa-check-square:before {
  content: '';
}
.fa-cheese:before {
  content: '';
}
.fa-cheese-swiss:before {
  content: '';
}
.fa-cheeseburger:before {
  content: '';
}
.fa-chess:before {
  content: '';
}
.fa-chess-bishop:before {
  content: '';
}
.fa-chess-bishop-alt:before {
  content: '';
}
.fa-chess-board:before {
  content: '';
}
.fa-chess-clock:before {
  content: '';
}
.fa-chess-clock-alt:before {
  content: '';
}
.fa-chess-king:before {
  content: '';
}
.fa-chess-king-alt:before {
  content: '';
}
.fa-chess-knight:before {
  content: '';
}
.fa-chess-knight-alt:before {
  content: '';
}
.fa-chess-pawn:before {
  content: '';
}
.fa-chess-pawn-alt:before {
  content: '';
}
.fa-chess-queen:before {
  content: '';
}
.fa-chess-queen-alt:before {
  content: '';
}
.fa-chess-rook:before {
  content: '';
}
.fa-chess-rook-alt:before {
  content: '';
}
.fa-chevron-circle-down:before {
  content: '';
}
.fa-chevron-circle-left:before {
  content: '';
}
.fa-chevron-circle-right:before {
  content: '';
}
.fa-chevron-circle-up:before {
  content: '';
}
.fa-chevron-double-down:before {
  content: '';
}
.fa-chevron-double-left:before {
  content: '';
}
.fa-chevron-double-right:before {
  content: '';
}
.fa-chevron-double-up:before {
  content: '';
}
.fa-chevron-down:before {
  content: '';
}
.fa-chevron-left:before {
  content: '';
}
.fa-chevron-right:before {
  content: '';
}
.fa-chevron-square-down:before {
  content: '';
}
.fa-chevron-square-left:before {
  content: '';
}
.fa-chevron-square-right:before {
  content: '';
}
.fa-chevron-square-up:before {
  content: '';
}
.fa-chevron-up:before {
  content: '';
}
.fa-child:before {
  content: '';
}
.fa-chimney:before {
  content: '';
}
.fa-chrome:before {
  content: '';
}
.fa-chromecast:before {
  content: '';
}
.fa-church:before {
  content: '';
}
.fa-circle:before {
  content: '';
}
.fa-circle-notch:before {
  content: '';
}
.fa-city:before {
  content: '';
}
.fa-claw-marks:before {
  content: '';
}
.fa-clinic-medical:before {
  content: '';
}
.fa-clipboard:before {
  content: '';
}
.fa-clipboard-check:before {
  content: '';
}
.fa-clipboard-list:before {
  content: '';
}
.fa-clipboard-list-check:before {
  content: '';
}
.fa-clipboard-prescription:before {
  content: '';
}
.fa-clipboard-user:before {
  content: '';
}
.fa-clock:before {
  content: '';
}
.fa-clone:before {
  content: '';
}
.fa-closed-captioning:before {
  content: '';
}
.fa-cloud:before {
  content: '';
}
.fa-cloud-download:before {
  content: '';
}
.fa-cloud-download-alt:before {
  content: '';
}
.fa-cloud-drizzle:before {
  content: '';
}
.fa-cloud-hail:before {
  content: '';
}
.fa-cloud-hail-mixed:before {
  content: '';
}
.fa-cloud-meatball:before {
  content: '';
}
.fa-cloud-moon:before {
  content: '';
}
.fa-cloud-moon-rain:before {
  content: '';
}
.fa-cloud-rain:before {
  content: '';
}
.fa-cloud-rainbow:before {
  content: '';
}
.fa-cloud-showers:before {
  content: '';
}
.fa-cloud-showers-heavy:before {
  content: '';
}
.fa-cloud-sleet:before {
  content: '';
}
.fa-cloud-snow:before {
  content: '';
}
.fa-cloud-sun:before {
  content: '';
}
.fa-cloud-sun-rain:before {
  content: '';
}
.fa-cloud-upload:before {
  content: '';
}
.fa-cloud-upload-alt:before {
  content: '';
}
.fa-clouds:before {
  content: '';
}
.fa-clouds-moon:before {
  content: '';
}
.fa-clouds-sun:before {
  content: '';
}
.fa-cloudscale:before {
  content: '';
}
.fa-cloudsmith:before {
  content: '';
}
.fa-cloudversify:before {
  content: '';
}
.fa-club:before {
  content: '';
}
.fa-cocktail:before {
  content: '';
}
.fa-code:before {
  content: '';
}
.fa-code-branch:before {
  content: '';
}
.fa-code-commit:before {
  content: '';
}
.fa-code-merge:before {
  content: '';
}
.fa-codepen:before {
  content: '';
}
.fa-codiepie:before {
  content: '';
}
.fa-coffee:before {
  content: '';
}
.fa-coffee-togo:before {
  content: '';
}
.fa-coffin:before {
  content: '';
}
.fa-cog:before {
  content: '';
}
.fa-cogs:before {
  content: '';
}
.fa-coins:before {
  content: '';
}
.fa-columns:before {
  content: '';
}
.fa-comment:before {
  content: '';
}
.fa-comment-alt:before {
  content: '';
}
.fa-comment-alt-check:before {
  content: '';
}
.fa-comment-alt-dollar:before {
  content: '';
}
.fa-comment-alt-dots:before {
  content: '';
}
.fa-comment-alt-edit:before {
  content: '';
}
.fa-comment-alt-exclamation:before {
  content: '';
}
.fa-comment-alt-lines:before {
  content: '';
}
.fa-comment-alt-medical:before {
  content: '';
}
.fa-comment-alt-minus:before {
  content: '';
}
.fa-comment-alt-plus:before {
  content: '';
}
.fa-comment-alt-slash:before {
  content: '';
}
.fa-comment-alt-smile:before {
  content: '';
}
.fa-comment-alt-times:before {
  content: '';
}
.fa-comment-check:before {
  content: '';
}
.fa-comment-dollar:before {
  content: '';
}
.fa-comment-dots:before {
  content: '';
}
.fa-comment-edit:before {
  content: '';
}
.fa-comment-exclamation:before {
  content: '';
}
.fa-comment-lines:before {
  content: '';
}
.fa-comment-medical:before {
  content: '';
}
.fa-comment-minus:before {
  content: '';
}
.fa-comment-plus:before {
  content: '';
}
.fa-comment-slash:before {
  content: '';
}
.fa-comment-smile:before {
  content: '';
}
.fa-comment-times:before {
  content: '';
}
.fa-comments:before {
  content: '';
}
.fa-comments-alt:before {
  content: '';
}
.fa-comments-alt-dollar:before {
  content: '';
}
.fa-comments-dollar:before {
  content: '';
}
.fa-compact-disc:before {
  content: '';
}
.fa-compass:before {
  content: '';
}
.fa-compass-slash:before {
  content: '';
}
.fa-compress:before {
  content: '';
}
.fa-compress-alt:before {
  content: '';
}
.fa-compress-arrows-alt:before {
  content: '';
}
.fa-compress-wide:before {
  content: '';
}
.fa-concierge-bell:before {
  content: '';
}
.fa-confluence:before {
  content: '';
}
.fa-connectdevelop:before {
  content: '';
}
.fa-container-storage:before {
  content: '';
}
.fa-contao:before {
  content: '';
}
.fa-conveyor-belt:before {
  content: '';
}
.fa-conveyor-belt-alt:before {
  content: '';
}
.fa-cookie:before {
  content: '';
}
.fa-cookie-bite:before {
  content: '';
}
.fa-copy:before {
  content: '';
}
.fa-copyright:before {
  content: '';
}
.fa-corn:before {
  content: '';
}
.fa-couch:before {
  content: '';
}
.fa-cow:before {
  content: '';
}
.fa-cpanel:before {
  content: '';
}
.fa-creative-commons:before {
  content: '';
}
.fa-creative-commons-by:before {
  content: '';
}
.fa-creative-commons-nc:before {
  content: '';
}
.fa-creative-commons-nc-eu:before {
  content: '';
}
.fa-creative-commons-nc-jp:before {
  content: '';
}
.fa-creative-commons-nd:before {
  content: '';
}
.fa-creative-commons-pd:before {
  content: '';
}
.fa-creative-commons-pd-alt:before {
  content: '';
}
.fa-creative-commons-remix:before {
  content: '';
}
.fa-creative-commons-sa:before {
  content: '';
}
.fa-creative-commons-sampling:before {
  content: '';
}
.fa-creative-commons-sampling-plus:before {
  content: '';
}
.fa-creative-commons-share:before {
  content: '';
}
.fa-creative-commons-zero:before {
  content: '';
}
.fa-credit-card:before {
  content: '';
}
.fa-credit-card-blank:before {
  content: '';
}
.fa-credit-card-front:before {
  content: '';
}
.fa-cricket:before {
  content: '';
}
.fa-critical-role:before {
  content: '';
}
.fa-croissant:before {
  content: '';
}
.fa-crop:before {
  content: '';
}
.fa-crop-alt:before {
  content: '';
}
.fa-cross:before {
  content: '';
}
.fa-crosshairs:before {
  content: '';
}
.fa-crow:before {
  content: '';
}
.fa-crown:before {
  content: '';
}
.fa-crutch:before {
  content: '';
}
.fa-crutches:before {
  content: '';
}
.fa-css3:before {
  content: '';
}
.fa-css3-alt:before {
  content: '';
}
.fa-cube:before {
  content: '';
}
.fa-cubes:before {
  content: '';
}
.fa-curling:before {
  content: '';
}
.fa-cut:before {
  content: '';
}
.fa-cuttlefish:before {
  content: '';
}
.fa-d-and-d:before {
  content: '';
}
.fa-d-and-d-beyond:before {
  content: '';
}
.fa-dagger:before {
  content: '';
}
.fa-dashcube:before {
  content: '';
}
.fa-database:before {
  content: '';
}
.fa-deaf:before {
  content: '';
}
.fa-debug:before {
  content: '';
}
.fa-deer:before {
  content: '';
}
.fa-deer-rudolph:before {
  content: '';
}
.fa-delicious:before {
  content: '';
}
.fa-democrat:before {
  content: '';
}
.fa-deploydog:before {
  content: '';
}
.fa-deskpro:before {
  content: '';
}
.fa-desktop:before {
  content: '';
}
.fa-desktop-alt:before {
  content: '';
}
.fa-dev:before {
  content: '';
}
.fa-deviantart:before {
  content: '';
}
.fa-dewpoint:before {
  content: '';
}
.fa-dharmachakra:before {
  content: '';
}
.fa-dhl:before {
  content: '';
}
.fa-diagnoses:before {
  content: '';
}
.fa-diamond:before {
  content: '';
}
.fa-diaspora:before {
  content: '';
}
.fa-dice:before {
  content: '';
}
.fa-dice-d10:before {
  content: '';
}
.fa-dice-d12:before {
  content: '';
}
.fa-dice-d20:before {
  content: '';
}
.fa-dice-d4:before {
  content: '';
}
.fa-dice-d6:before {
  content: '';
}
.fa-dice-d8:before {
  content: '';
}
.fa-dice-five:before {
  content: '';
}
.fa-dice-four:before {
  content: '';
}
.fa-dice-one:before {
  content: '';
}
.fa-dice-six:before {
  content: '';
}
.fa-dice-three:before {
  content: '';
}
.fa-dice-two:before {
  content: '';
}
.fa-digg:before {
  content: '';
}
.fa-digital-ocean:before {
  content: '';
}
.fa-digital-tachograph:before {
  content: '';
}
.fa-diploma:before {
  content: '';
}
.fa-directions:before {
  content: '';
}
.fa-discord:before {
  content: '';
}
.fa-discourse:before {
  content: '';
}
.fa-disease:before {
  content: '';
}
.fa-divide:before {
  content: '';
}
.fa-dizzy:before {
  content: '';
}
.fa-dna:before {
  content: '';
}
.fa-do-not-enter:before {
  content: '';
}
.fa-dochub:before {
  content: '';
}
.fa-docker:before {
  content: '';
}
.fa-dog:before {
  content: '';
}
.fa-dog-leashed:before {
  content: '';
}
.fa-dollar-sign:before {
  content: '';
}
.fa-dolly:before {
  content: '';
}
.fa-dolly-empty:before {
  content: '';
}
.fa-dolly-flatbed:before {
  content: '';
}
.fa-dolly-flatbed-alt:before {
  content: '';
}
.fa-dolly-flatbed-empty:before {
  content: '';
}
.fa-donate:before {
  content: '';
}
.fa-door-closed:before {
  content: '';
}
.fa-door-open:before {
  content: '';
}
.fa-dot-circle:before {
  content: '';
}
.fa-dove:before {
  content: '';
}
.fa-download:before {
  content: '';
}
.fa-draft2digital:before {
  content: '';
}
.fa-drafting-compass:before {
  content: '';
}
.fa-dragon:before {
  content: '';
}
.fa-draw-circle:before {
  content: '';
}
.fa-draw-polygon:before {
  content: '';
}
.fa-draw-square:before {
  content: '';
}
.fa-dreidel:before {
  content: '';
}
.fa-dribbble:before {
  content: '';
}
.fa-dribbble-square:before {
  content: '';
}
.fa-dropbox:before {
  content: '';
}
.fa-drum:before {
  content: '';
}
.fa-drum-steelpan:before {
  content: '';
}
.fa-drumstick:before {
  content: '';
}
.fa-drumstick-bite:before {
  content: '';
}
.fa-drupal:before {
  content: '';
}
.fa-duck:before {
  content: '';
}
.fa-dumbbell:before {
  content: '';
}
.fa-dumpster:before {
  content: '';
}
.fa-dumpster-fire:before {
  content: '';
}
.fa-dungeon:before {
  content: '';
}
.fa-dyalog:before {
  content: '';
}
.fa-ear:before {
  content: '';
}
.fa-ear-muffs:before {
  content: '';
}
.fa-earlybirds:before {
  content: '';
}
.fa-ebay:before {
  content: '';
}
.fa-eclipse:before {
  content: '';
}
.fa-eclipse-alt:before {
  content: '';
}
.fa-edge:before {
  content: '';
}
.fa-edit:before {
  content: '';
}
.fa-egg:before {
  content: '';
}
.fa-egg-fried:before {
  content: '';
}
.fa-eject:before {
  content: '';
}
.fa-elementor:before {
  content: '';
}
.fa-elephant:before {
  content: '';
}
.fa-ellipsis-h:before {
  content: '';
}
.fa-ellipsis-h-alt:before {
  content: '';
}
.fa-ellipsis-v:before {
  content: '';
}
.fa-ellipsis-v-alt:before {
  content: '';
}
.fa-ello:before {
  content: '';
}
.fa-ember:before {
  content: '';
}
.fa-empire:before {
  content: '';
}
.fa-empty-set:before {
  content: '';
}
.fa-engine-warning:before {
  content: '';
}
.fa-envelope:before {
  content: '';
}
.fa-envelope-open:before {
  content: '';
}
.fa-envelope-open-dollar:before {
  content: '';
}
.fa-envelope-open-text:before {
  content: '';
}
.fa-envelope-square:before {
  content: '';
}
.fa-envira:before {
  content: '';
}
.fa-equals:before {
  content: '';
}
.fa-eraser:before {
  content: '';
}
.fa-erlang:before {
  content: '';
}
.fa-ethereum:before {
  content: '';
}
.fa-ethernet:before {
  content: '';
}
.fa-etsy:before {
  content: '';
}
.fa-euro-sign:before {
  content: '';
}
.fa-evernote:before {
  content: '';
}
.fa-exchange:before {
  content: '';
}
.fa-exchange-alt:before {
  content: '';
}
.fa-exclamation:before {
  content: '';
}
.fa-exclamation-circle:before {
  content: '';
}
.fa-exclamation-square:before {
  content: '';
}
.fa-exclamation-triangle:before {
  content: '';
}
.fa-expand:before {
  content: '';
}
.fa-expand-alt:before {
  content: '';
}
.fa-expand-arrows:before {
  content: '';
}
.fa-expand-arrows-alt:before {
  content: '';
}
.fa-expand-wide:before {
  content: '';
}
.fa-expeditedssl:before {
  content: '';
}
.fa-external-link:before {
  content: '';
}
.fa-external-link-alt:before {
  content: '';
}
.fa-external-link-square:before {
  content: '';
}
.fa-external-link-square-alt:before {
  content: '';
}
.fa-eye:before {
  content: '';
}
.fa-eye-dropper:before {
  content: '';
}
.fa-eye-evil:before {
  content: '';
}
.fa-eye-slash:before {
  content: '';
}
.fa-facebook:before {
  content: '';
}
.fa-facebook-f:before {
  content: '';
}
.fa-facebook-messenger:before {
  content: '';
}
.fa-facebook-square:before {
  content: '';
}
.fa-fantasy-flight-games:before {
  content: '';
}
.fa-fast-backward:before {
  content: '';
}
.fa-fast-forward:before {
  content: '';
}
.fa-fax:before {
  content: '';
}
.fa-feather:before {
  content: '';
}
.fa-feather-alt:before {
  content: '';
}
.fa-fedex:before {
  content: '';
}
.fa-fedora:before {
  content: '';
}
.fa-female:before {
  content: '';
}
.fa-field-hockey:before {
  content: '';
}
.fa-fighter-jet:before {
  content: '';
}
.fa-figma:before {
  content: '';
}
.fa-file:before {
  content: '';
}
.fa-file-alt:before {
  content: '';
}
.fa-file-archive:before {
  content: '';
}
.fa-file-audio:before {
  content: '';
}
.fa-file-certificate:before {
  content: '';
}
.fa-file-chart-line:before {
  content: '';
}
.fa-file-chart-pie:before {
  content: '';
}
.fa-file-check:before {
  content: '';
}
.fa-file-code:before {
  content: '';
}
.fa-file-contract:before {
  content: '';
}
.fa-file-csv:before {
  content: '';
}
.fa-file-download:before {
  content: '';
}
.fa-file-edit:before {
  content: '';
}
.fa-file-excel:before {
  content: '';
}
.fa-file-exclamation:before {
  content: '';
}
.fa-file-export:before {
  content: '';
}
.fa-file-image:before {
  content: '';
}
.fa-file-import:before {
  content: '';
}
.fa-file-invoice:before {
  content: '';
}
.fa-file-invoice-dollar:before {
  content: '';
}
.fa-file-medical:before {
  content: '';
}
.fa-file-medical-alt:before {
  content: '';
}
.fa-file-minus:before {
  content: '';
}
.fa-file-pdf:before {
  content: '';
}
.fa-file-plus:before {
  content: '';
}
.fa-file-powerpoint:before {
  content: '';
}
.fa-file-prescription:before {
  content: '';
}
.fa-file-signature:before {
  content: '';
}
.fa-file-spreadsheet:before {
  content: '';
}
.fa-file-times:before {
  content: '';
}
.fa-file-upload:before {
  content: '';
}
.fa-file-user:before {
  content: '';
}
.fa-file-video:before {
  content: '';
}
.fa-file-word:before {
  content: '';
}
.fa-files-medical:before {
  content: '';
}
.fa-fill:before {
  content: '';
}
.fa-fill-drip:before {
  content: '';
}
.fa-film:before {
  content: '';
}
.fa-film-alt:before {
  content: '';
}
.fa-filter:before {
  content: '';
}
.fa-fingerprint:before {
  content: '';
}
.fa-fire:before {
  content: '';
}
.fa-fire-alt:before {
  content: '';
}
.fa-fire-extinguisher:before {
  content: '';
}
.fa-fire-smoke:before {
  content: '';
}
.fa-firefox:before {
  content: '';
}
.fa-fireplace:before {
  content: '';
}
.fa-first-aid:before {
  content: '';
}
.fa-first-order:before {
  content: '';
}
.fa-first-order-alt:before {
  content: '';
}
.fa-firstdraft:before {
  content: '';
}
.fa-fish:before {
  content: '';
}
.fa-fish-cooked:before {
  content: '';
}
.fa-fist-raised:before {
  content: '';
}
.fa-flag:before {
  content: '';
}
.fa-flag-alt:before {
  content: '';
}
.fa-flag-checkered:before {
  content: '';
}
.fa-flag-usa:before {
  content: '';
}
.fa-flame:before {
  content: '';
}
.fa-flask:before {
  content: '';
}
.fa-flask-poison:before {
  content: '';
}
.fa-flask-potion:before {
  content: '';
}
.fa-flickr:before {
  content: '';
}
.fa-flipboard:before {
  content: '';
}
.fa-flower:before {
  content: '';
}
.fa-flower-daffodil:before {
  content: '';
}
.fa-flower-tulip:before {
  content: '';
}
.fa-flushed:before {
  content: '';
}
.fa-fly:before {
  content: '';
}
.fa-fog:before {
  content: '';
}
.fa-folder:before {
  content: '';
}
.fa-folder-minus:before {
  content: '';
}
.fa-folder-open:before {
  content: '';
}
.fa-folder-plus:before {
  content: '';
}
.fa-folder-times:before {
  content: '';
}
.fa-folder-tree:before {
  content: '';
}
.fa-folders:before {
  content: '';
}
.fa-font:before {
  content: '';
}
.fa-font-awesome:before {
  content: '';
}
.fa-font-awesome-alt:before {
  content: '';
}
.fa-font-awesome-flag:before {
  content: '';
}
.fa-font-awesome-logo-full:before {
  content: '';
}
.fa-fonticons:before {
  content: '';
}
.fa-fonticons-fi:before {
  content: '';
}
.fa-football-ball:before {
  content: '';
}
.fa-football-helmet:before {
  content: '';
}
.fa-forklift:before {
  content: '';
}
.fa-fort-awesome:before {
  content: '';
}
.fa-fort-awesome-alt:before {
  content: '';
}
.fa-forumbee:before {
  content: '';
}
.fa-forward:before {
  content: '';
}
.fa-foursquare:before {
  content: '';
}
.fa-fragile:before {
  content: '';
}
.fa-free-code-camp:before {
  content: '';
}
.fa-freebsd:before {
  content: '';
}
.fa-french-fries:before {
  content: '';
}
.fa-frog:before {
  content: '';
}
.fa-frosty-head:before {
  content: '';
}
.fa-frown:before {
  content: '';
}
.fa-frown-open:before {
  content: '';
}
.fa-fulcrum:before {
  content: '';
}
.fa-function:before {
  content: '';
}
.fa-funnel-dollar:before {
  content: '';
}
.fa-futbol:before {
  content: '';
}
.fa-galactic-republic:before {
  content: '';
}
.fa-galactic-senate:before {
  content: '';
}
.fa-gamepad:before {
  content: '';
}
.fa-gas-pump:before {
  content: '';
}
.fa-gas-pump-slash:before {
  content: '';
}
.fa-gavel:before {
  content: '';
}
.fa-gem:before {
  content: '';
}
.fa-genderless:before {
  content: '';
}
.fa-get-pocket:before {
  content: '';
}
.fa-gg:before {
  content: '';
}
.fa-gg-circle:before {
  content: '';
}
.fa-ghost:before {
  content: '';
}
.fa-gift:before {
  content: '';
}
.fa-gift-card:before {
  content: '';
}
.fa-gifts:before {
  content: '';
}
.fa-gingerbread-man:before {
  content: '';
}
.fa-git:before {
  content: '';
}
.fa-git-square:before {
  content: '';
}
.fa-github:before {
  content: '';
}
.fa-github-alt:before {
  content: '';
}
.fa-github-square:before {
  content: '';
}
.fa-gitkraken:before {
  content: '';
}
.fa-gitlab:before {
  content: '';
}
.fa-gitter:before {
  content: '';
}
.fa-glass:before {
  content: '';
}
.fa-glass-champagne:before {
  content: '';
}
.fa-glass-cheers:before {
  content: '';
}
.fa-glass-martini:before {
  content: '';
}
.fa-glass-martini-alt:before {
  content: '';
}
.fa-glass-whiskey:before {
  content: '';
}
.fa-glass-whiskey-rocks:before {
  content: '';
}
.fa-glasses:before {
  content: '';
}
.fa-glasses-alt:before {
  content: '';
}
.fa-glide:before {
  content: '';
}
.fa-glide-g:before {
  content: '';
}
.fa-globe:before {
  content: '';
}
.fa-globe-africa:before {
  content: '';
}
.fa-globe-americas:before {
  content: '';
}
.fa-globe-asia:before {
  content: '';
}
.fa-globe-europe:before {
  content: '';
}
.fa-globe-snow:before {
  content: '';
}
.fa-globe-stand:before {
  content: '';
}
.fa-gofore:before {
  content: '';
}
.fa-golf-ball:before {
  content: '';
}
.fa-golf-club:before {
  content: '';
}
.fa-goodreads:before {
  content: '';
}
.fa-goodreads-g:before {
  content: '';
}
.fa-google:before {
  content: '';
}
.fa-google-drive:before {
  content: '';
}
.fa-google-play:before {
  content: '';
}
.fa-google-plus:before {
  content: '';
}
.fa-google-plus-g:before {
  content: '';
}
.fa-google-plus-square:before {
  content: '';
}
.fa-google-wallet:before {
  content: '';
}
.fa-gopuram:before {
  content: '';
}
.fa-graduation-cap:before {
  content: '';
}
.fa-gratipay:before {
  content: '';
}
.fa-grav:before {
  content: '';
}
.fa-greater-than:before {
  content: '';
}
.fa-greater-than-equal:before {
  content: '';
}
.fa-grimace:before {
  content: '';
}
.fa-grin:before {
  content: '';
}
.fa-grin-alt:before {
  content: '';
}
.fa-grin-beam:before {
  content: '';
}
.fa-grin-beam-sweat:before {
  content: '';
}
.fa-grin-hearts:before {
  content: '';
}
.fa-grin-squint:before {
  content: '';
}
.fa-grin-squint-tears:before {
  content: '';
}
.fa-grin-stars:before {
  content: '';
}
.fa-grin-tears:before {
  content: '';
}
.fa-grin-tongue:before {
  content: '';
}
.fa-grin-tongue-squint:before {
  content: '';
}
.fa-grin-tongue-wink:before {
  content: '';
}
.fa-grin-wink:before {
  content: '';
}
.fa-grip-horizontal:before {
  content: '';
}
.fa-grip-lines:before {
  content: '';
}
.fa-grip-lines-vertical:before {
  content: '';
}
.fa-grip-vertical:before {
  content: '';
}
.fa-gripfire:before {
  content: '';
}
.fa-grunt:before {
  content: '';
}
.fa-guitar:before {
  content: '';
}
.fa-gulp:before {
  content: '';
}
.fa-h-square:before {
  content: '';
}
.fa-h1:before {
  content: '';
}
.fa-h2:before {
  content: '';
}
.fa-h3:before {
  content: '';
}
.fa-hacker-news:before {
  content: '';
}
.fa-hacker-news-square:before {
  content: '';
}
.fa-hackerrank:before {
  content: '';
}
.fa-hamburger:before {
  content: '';
}
.fa-hammer:before {
  content: '';
}
.fa-hammer-war:before {
  content: '';
}
.fa-hamsa:before {
  content: '';
}
.fa-hand-heart:before {
  content: '';
}
.fa-hand-holding:before {
  content: '';
}
.fa-hand-holding-box:before {
  content: '';
}
.fa-hand-holding-heart:before {
  content: '';
}
.fa-hand-holding-magic:before {
  content: '';
}
.fa-hand-holding-seedling:before {
  content: '';
}
.fa-hand-holding-usd:before {
  content: '';
}
.fa-hand-holding-water:before {
  content: '';
}
.fa-hand-lizard:before {
  content: '';
}
.fa-hand-middle-finger:before {
  content: '';
}
.fa-hand-paper:before {
  content: '';
}
.fa-hand-peace:before {
  content: '';
}
.fa-hand-point-down:before {
  content: '';
}
.fa-hand-point-left:before {
  content: '';
}
.fa-hand-point-right:before {
  content: '';
}
.fa-hand-point-up:before {
  content: '';
}
.fa-hand-pointer:before {
  content: '';
}
.fa-hand-receiving:before {
  content: '';
}
.fa-hand-rock:before {
  content: '';
}
.fa-hand-scissors:before {
  content: '';
}
.fa-hand-spock:before {
  content: '';
}
.fa-hands:before {
  content: '';
}
.fa-hands-heart:before {
  content: '';
}
.fa-hands-helping:before {
  content: '';
}
.fa-hands-usd:before {
  content: '';
}
.fa-handshake:before {
  content: '';
}
.fa-handshake-alt:before {
  content: '';
}
.fa-hanukiah:before {
  content: '';
}
.fa-hard-hat:before {
  content: '';
}
.fa-hashtag:before {
  content: '';
}
.fa-hat-santa:before {
  content: '';
}
.fa-hat-winter:before {
  content: '';
}
.fa-hat-witch:before {
  content: '';
}
.fa-hat-wizard:before {
  content: '';
}
.fa-haykal:before {
  content: '';
}
.fa-hdd:before {
  content: '';
}
.fa-head-side:before {
  content: '';
}
.fa-head-side-brain:before {
  content: '';
}
.fa-head-side-medical:before {
  content: '';
}
.fa-head-vr:before {
  content: '';
}
.fa-heading:before {
  content: '';
}
.fa-headphones:before {
  content: '';
}
.fa-headphones-alt:before {
  content: '';
}
.fa-headset:before {
  content: '';
}
.fa-heart:before {
  content: '';
}
.fa-heart-broken:before {
  content: '';
}
.fa-heart-circle:before {
  content: '';
}
.fa-heart-rate:before {
  content: '';
}
.fa-heart-square:before {
  content: '';
}
.fa-heartbeat:before {
  content: '';
}
.fa-helicopter:before {
  content: '';
}
.fa-helmet-battle:before {
  content: '';
}
.fa-hexagon:before {
  content: '';
}
.fa-highlighter:before {
  content: '';
}
.fa-hiking:before {
  content: '';
}
.fa-hippo:before {
  content: '';
}
.fa-hips:before {
  content: '';
}
.fa-hire-a-helper:before {
  content: '';
}
.fa-history:before {
  content: '';
}
.fa-hockey-mask:before {
  content: '';
}
.fa-hockey-puck:before {
  content: '';
}
.fa-hockey-sticks:before {
  content: '';
}
.fa-holly-berry:before {
  content: '';
}
.fa-home:before {
  content: '';
}
.fa-home-alt:before {
  content: '';
}
.fa-home-heart:before {
  content: '';
}
.fa-home-lg:before {
  content: '';
}
.fa-home-lg-alt:before {
  content: '';
}
.fa-hood-cloak:before {
  content: '';
}
.fa-hooli:before {
  content: '';
}
.fa-hornbill:before {
  content: '';
}
.fa-horse:before {
  content: '';
}
.fa-horse-head:before {
  content: '';
}
.fa-hospital:before {
  content: '';
}
.fa-hospital-alt:before {
  content: '';
}
.fa-hospital-symbol:before {
  content: '';
}
.fa-hospital-user:before {
  content: '';
}
.fa-hospitals:before {
  content: '';
}
.fa-hot-tub:before {
  content: '';
}
.fa-hotdog:before {
  content: '';
}
.fa-hotel:before {
  content: '';
}
.fa-hotjar:before {
  content: '';
}
.fa-hourglass:before {
  content: '';
}
.fa-hourglass-end:before {
  content: '';
}
.fa-hourglass-half:before {
  content: '';
}
.fa-hourglass-start:before {
  content: '';
}
.fa-house-damage:before {
  content: '';
}
.fa-house-flood:before {
  content: '';
}
.fa-houzz:before {
  content: '';
}
.fa-hryvnia:before {
  content: '';
}
.fa-html5:before {
  content: '';
}
.fa-hubspot:before {
  content: '';
}
.fa-humidity:before {
  content: '';
}
.fa-hurricane:before {
  content: '';
}
.fa-i-cursor:before {
  content: '';
}
.fa-ice-cream:before {
  content: '';
}
.fa-ice-skate:before {
  content: '';
}
.fa-icicles:before {
  content: '';
}
.fa-id-badge:before {
  content: '';
}
.fa-id-card:before {
  content: '';
}
.fa-id-card-alt:before {
  content: '';
}
.fa-igloo:before {
  content: '';
}
.fa-image:before {
  content: '';
}
.fa-images:before {
  content: '';
}
.fa-imdb:before {
  content: '';
}
.fa-inbox:before {
  content: '';
}
.fa-inbox-in:before {
  content: '';
}
.fa-inbox-out:before {
  content: '';
}
.fa-indent:before {
  content: '';
}
.fa-industry:before {
  content: '';
}
.fa-industry-alt:before {
  content: '';
}
.fa-infinity:before {
  content: '';
}
.fa-info:before {
  content: '';
}
.fa-info-circle:before {
  content: '';
}
.fa-info-square:before {
  content: '';
}
.fa-inhaler:before {
  content: '';
}
.fa-instagram:before {
  content: '';
}
.fa-integral:before {
  content: '';
}
.fa-intercom:before {
  content: '';
}
.fa-internet-explorer:before {
  content: '';
}
.fa-intersection:before {
  content: '';
}
.fa-inventory:before {
  content: '';
}
.fa-invision:before {
  content: '';
}
.fa-ioxhost:before {
  content: '';
}
.fa-island-tropical:before {
  content: '';
}
.fa-italic:before {
  content: '';
}
.fa-itch-io:before {
  content: '';
}
.fa-itunes:before {
  content: '';
}
.fa-itunes-note:before {
  content: '';
}
.fa-jack-o-lantern:before {
  content: '';
}
.fa-java:before {
  content: '';
}
.fa-jedi:before {
  content: '';
}
.fa-jedi-order:before {
  content: '';
}
.fa-jenkins:before {
  content: '';
}
.fa-jira:before {
  content: '';
}
.fa-joget:before {
  content: '';
}
.fa-joint:before {
  content: '';
}
.fa-joomla:before {
  content: '';
}
.fa-journal-whills:before {
  content: '';
}
.fa-js:before {
  content: '';
}
.fa-js-square:before {
  content: '';
}
.fa-jsfiddle:before {
  content: '';
}
.fa-kaaba:before {
  content: '';
}
.fa-kaggle:before {
  content: '';
}
.fa-key:before {
  content: '';
}
.fa-key-skeleton:before {
  content: '';
}
.fa-keybase:before {
  content: '';
}
.fa-keyboard:before {
  content: '';
}
.fa-keycdn:before {
  content: '';
}
.fa-keynote:before {
  content: '';
}
.fa-khanda:before {
  content: '';
}
.fa-kickstarter:before {
  content: '';
}
.fa-kickstarter-k:before {
  content: '';
}
.fa-kidneys:before {
  content: '';
}
.fa-kiss:before {
  content: '';
}
.fa-kiss-beam:before {
  content: '';
}
.fa-kiss-wink-heart:before {
  content: '';
}
.fa-kite:before {
  content: '';
}
.fa-kiwi-bird:before {
  content: '';
}
.fa-knife-kitchen:before {
  content: '';
}
.fa-korvue:before {
  content: '';
}
.fa-lambda:before {
  content: '';
}
.fa-lamp:before {
  content: '';
}
.fa-landmark:before {
  content: '';
}
.fa-landmark-alt:before {
  content: '';
}
.fa-language:before {
  content: '';
}
.fa-laptop:before {
  content: '';
}
.fa-laptop-code:before {
  content: '';
}
.fa-laptop-medical:before {
  content: '';
}
.fa-laravel:before {
  content: '';
}
.fa-lastfm:before {
  content: '';
}
.fa-lastfm-square:before {
  content: '';
}
.fa-laugh:before {
  content: '';
}
.fa-laugh-beam:before {
  content: '';
}
.fa-laugh-squint:before {
  content: '';
}
.fa-laugh-wink:before {
  content: '';
}
.fa-layer-group:before {
  content: '';
}
.fa-layer-minus:before {
  content: '';
}
.fa-layer-plus:before {
  content: '';
}
.fa-leaf:before {
  content: '';
}
.fa-leaf-heart:before {
  content: '';
}
.fa-leaf-maple:before {
  content: '';
}
.fa-leaf-oak:before {
  content: '';
}
.fa-leanpub:before {
  content: '';
}
.fa-lemon:before {
  content: '';
}
.fa-less:before {
  content: '';
}
.fa-less-than:before {
  content: '';
}
.fa-less-than-equal:before {
  content: '';
}
.fa-level-down:before {
  content: '';
}
.fa-level-down-alt:before {
  content: '';
}
.fa-level-up:before {
  content: '';
}
.fa-level-up-alt:before {
  content: '';
}
.fa-life-ring:before {
  content: '';
}
.fa-lightbulb:before {
  content: '';
}
.fa-lightbulb-dollar:before {
  content: '';
}
.fa-lightbulb-exclamation:before {
  content: '';
}
.fa-lightbulb-on:before {
  content: '';
}
.fa-lightbulb-slash:before {
  content: '';
}
.fa-lights-holiday:before {
  content: '';
}
.fa-line:before {
  content: '';
}
.fa-link:before {
  content: '';
}
.fa-linkedin:before {
  content: '';
}
.fa-linkedin-in:before {
  content: '';
}
.fa-linode:before {
  content: '';
}
.fa-linux:before {
  content: '';
}
.fa-lips:before {
  content: '';
}
.fa-lira-sign:before {
  content: '';
}
.fa-list:before {
  content: '';
}
.fa-list-alt:before {
  content: '';
}
.fa-list-ol:before {
  content: '';
}
.fa-list-ul:before {
  content: '';
}
.fa-location:before {
  content: '';
}
.fa-location-arrow:before {
  content: '';
}
.fa-location-circle:before {
  content: '';
}
.fa-location-slash:before {
  content: '';
}
.fa-lock:before {
  content: '';
}
.fa-lock-alt:before {
  content: '';
}
.fa-lock-open:before {
  content: '';
}
.fa-lock-open-alt:before {
  content: '';
}
.fa-long-arrow-alt-down:before {
  content: '';
}
.fa-long-arrow-alt-left:before {
  content: '';
}
.fa-long-arrow-alt-right:before {
  content: '';
}
.fa-long-arrow-alt-up:before {
  content: '';
}
.fa-long-arrow-down:before {
  content: '';
}
.fa-long-arrow-left:before {
  content: '';
}
.fa-long-arrow-right:before {
  content: '';
}
.fa-long-arrow-up:before {
  content: '';
}
.fa-loveseat:before {
  content: '';
}
.fa-low-vision:before {
  content: '';
}
.fa-luchador:before {
  content: '';
}
.fa-luggage-cart:before {
  content: '';
}
.fa-lungs:before {
  content: '';
}
.fa-lyft:before {
  content: '';
}
.fa-mace:before {
  content: '';
}
.fa-magento:before {
  content: '';
}
.fa-magic:before {
  content: '';
}
.fa-magnet:before {
  content: '';
}
.fa-mail-bulk:before {
  content: '';
}
.fa-mailbox:before {
  content: '';
}
.fa-mailchimp:before {
  content: '';
}
.fa-male:before {
  content: '';
}
.fa-mandalorian:before {
  content: '';
}
.fa-mandolin:before {
  content: '';
}
.fa-map:before {
  content: '';
}
.fa-map-marked:before {
  content: '';
}
.fa-map-marked-alt:before {
  content: '';
}
.fa-map-marker:before {
  content: '';
}
.fa-map-marker-alt:before {
  content: '';
}
.fa-map-marker-alt-slash:before {
  content: '';
}
.fa-map-marker-check:before {
  content: '';
}
.fa-map-marker-edit:before {
  content: '';
}
.fa-map-marker-exclamation:before {
  content: '';
}
.fa-map-marker-minus:before {
  content: '';
}
.fa-map-marker-plus:before {
  content: '';
}
.fa-map-marker-question:before {
  content: '';
}
.fa-map-marker-slash:before {
  content: '';
}
.fa-map-marker-smile:before {
  content: '';
}
.fa-map-marker-times:before {
  content: '';
}
.fa-map-pin:before {
  content: '';
}
.fa-map-signs:before {
  content: '';
}
.fa-markdown:before {
  content: '';
}
.fa-marker:before {
  content: '';
}
.fa-mars:before {
  content: '';
}
.fa-mars-double:before {
  content: '';
}
.fa-mars-stroke:before {
  content: '';
}
.fa-mars-stroke-h:before {
  content: '';
}
.fa-mars-stroke-v:before {
  content: '';
}
.fa-mask:before {
  content: '';
}
.fa-mastodon:before {
  content: '';
}
.fa-maxcdn:before {
  content: '';
}
.fa-meat:before {
  content: '';
}
.fa-medal:before {
  content: '';
}
.fa-medapps:before {
  content: '';
}
.fa-medium:before {
  content: '';
}
.fa-medium-m:before {
  content: '';
}
.fa-medkit:before {
  content: '';
}
.fa-medrt:before {
  content: '';
}
.fa-meetup:before {
  content: '';
}
.fa-megaphone:before {
  content: '';
}
.fa-megaport:before {
  content: '';
}
.fa-meh:before {
  content: '';
}
.fa-meh-blank:before {
  content: '';
}
.fa-meh-rolling-eyes:before {
  content: '';
}
.fa-memory:before {
  content: '';
}
.fa-mendeley:before {
  content: '';
}
.fa-menorah:before {
  content: '';
}
.fa-mercury:before {
  content: '';
}
.fa-meteor:before {
  content: '';
}
.fa-microchip:before {
  content: '';
}
.fa-microphone:before {
  content: '';
}
.fa-microphone-alt:before {
  content: '';
}
.fa-microphone-alt-slash:before {
  content: '';
}
.fa-microphone-slash:before {
  content: '';
}
.fa-microscope:before {
  content: '';
}
.fa-microsoft:before {
  content: '';
}
.fa-mind-share:before {
  content: '';
}
.fa-minus:before {
  content: '';
}
.fa-minus-circle:before {
  content: '';
}
.fa-minus-hexagon:before {
  content: '';
}
.fa-minus-octagon:before {
  content: '';
}
.fa-minus-square:before {
  content: '';
}
.fa-mistletoe:before {
  content: '';
}
.fa-mitten:before {
  content: '';
}
.fa-mix:before {
  content: '';
}
.fa-mixcloud:before {
  content: '';
}
.fa-mizuni:before {
  content: '';
}
.fa-mobile:before {
  content: '';
}
.fa-mobile-alt:before {
  content: '';
}
.fa-mobile-android:before {
  content: '';
}
.fa-mobile-android-alt:before {
  content: '';
}
.fa-modx:before {
  content: '';
}
.fa-monero:before {
  content: '';
}
.fa-money-bill:before {
  content: '';
}
.fa-money-bill-alt:before {
  content: '';
}
.fa-money-bill-wave:before {
  content: '';
}
.fa-money-bill-wave-alt:before {
  content: '';
}
.fa-money-check:before {
  content: '';
}
.fa-money-check-alt:before {
  content: '';
}
.fa-monitor-heart-rate:before {
  content: '';
}
.fa-monkey:before {
  content: '';
}
.fa-monument:before {
  content: '';
}
.fa-moon:before {
  content: '';
}
.fa-moon-cloud:before {
  content: '';
}
.fa-moon-stars:before {
  content: '';
}
.fa-mortar-pestle:before {
  content: '';
}
.fa-mosque:before {
  content: '';
}
.fa-motorcycle:before {
  content: '';
}
.fa-mountain:before {
  content: '';
}
.fa-mountains:before {
  content: '';
}
.fa-mouse-pointer:before {
  content: '';
}
.fa-mug-hot:before {
  content: '';
}
.fa-mug-marshmallows:before {
  content: '';
}
.fa-music:before {
  content: '';
}
.fa-napster:before {
  content: '';
}
.fa-narwhal:before {
  content: '';
}
.fa-neos:before {
  content: '';
}
.fa-network-wired:before {
  content: '';
}
.fa-neuter:before {
  content: '';
}
.fa-newspaper:before {
  content: '';
}
.fa-nimblr:before {
  content: '';
}
.fa-nintendo-switch:before {
  content: '';
}
.fa-node:before {
  content: '';
}
.fa-node-js:before {
  content: '';
}
.fa-not-equal:before {
  content: '';
}
.fa-notes-medical:before {
  content: '';
}
.fa-npm:before {
  content: '';
}
.fa-ns8:before {
  content: '';
}
.fa-nutritionix:before {
  content: '';
}
.fa-object-group:before {
  content: '';
}
.fa-object-ungroup:before {
  content: '';
}
.fa-octagon:before {
  content: '';
}
.fa-odnoklassniki:before {
  content: '';
}
.fa-odnoklassniki-square:before {
  content: '';
}
.fa-oil-can:before {
  content: '';
}
.fa-oil-temp:before {
  content: '';
}
.fa-old-republic:before {
  content: '';
}
.fa-om:before {
  content: '';
}
.fa-omega:before {
  content: '';
}
.fa-opencart:before {
  content: '';
}
.fa-openid:before {
  content: '';
}
.fa-opera:before {
  content: '';
}
.fa-optin-monster:before {
  content: '';
}
.fa-ornament:before {
  content: '';
}
.fa-osi:before {
  content: '';
}
.fa-otter:before {
  content: '';
}
.fa-outdent:before {
  content: '';
}
.fa-page4:before {
  content: '';
}
.fa-pagelines:before {
  content: '';
}
.fa-pager:before {
  content: '';
}
.fa-paint-brush:before {
  content: '';
}
.fa-paint-brush-alt:before {
  content: '';
}
.fa-paint-roller:before {
  content: '';
}
.fa-palette:before {
  content: '';
}
.fa-palfed:before {
  content: '';
}
.fa-pallet:before {
  content: '';
}
.fa-pallet-alt:before {
  content: '';
}
.fa-paper-plane:before {
  content: '';
}
.fa-paperclip:before {
  content: '';
}
.fa-parachute-box:before {
  content: '';
}
.fa-paragraph:before {
  content: '';
}
.fa-parking:before {
  content: '';
}
.fa-parking-circle:before {
  content: '';
}
.fa-parking-circle-slash:before {
  content: '';
}
.fa-parking-slash:before {
  content: '';
}
.fa-passport:before {
  content: '';
}
.fa-pastafarianism:before {
  content: '';
}
.fa-paste:before {
  content: '';
}
.fa-patreon:before {
  content: '';
}
.fa-pause:before {
  content: '';
}
.fa-pause-circle:before {
  content: '';
}
.fa-paw:before {
  content: '';
}
.fa-paw-alt:before {
  content: '';
}
.fa-paw-claws:before {
  content: '';
}
.fa-paypal:before {
  content: '';
}
.fa-peace:before {
  content: '';
}
.fa-pegasus:before {
  content: '';
}
.fa-pen:before {
  content: '';
}
.fa-pen-alt:before {
  content: '';
}
.fa-pen-fancy:before {
  content: '';
}
.fa-pen-nib:before {
  content: '';
}
.fa-pen-square:before {
  content: '';
}
.fa-pencil:before {
  content: '';
}
.fa-pencil-alt:before {
  content: '';
}
.fa-pencil-paintbrush:before {
  content: '';
}
.fa-pencil-ruler:before {
  content: '';
}
.fa-pennant:before {
  content: '';
}
.fa-penny-arcade:before {
  content: '';
}
.fa-people-carry:before {
  content: '';
}
.fa-pepper-hot:before {
  content: '';
}
.fa-percent:before {
  content: '';
}
.fa-percentage:before {
  content: '';
}
.fa-periscope:before {
  content: '';
}
.fa-person-booth:before {
  content: '';
}
.fa-person-carry:before {
  content: '';
}
.fa-person-dolly:before {
  content: '';
}
.fa-person-dolly-empty:before {
  content: '';
}
.fa-person-sign:before {
  content: '';
}
.fa-phabricator:before {
  content: '';
}
.fa-phoenix-framework:before {
  content: '';
}
.fa-phoenix-squadron:before {
  content: '';
}
.fa-phone:before {
  content: '';
}
.fa-phone-office:before {
  content: '';
}
.fa-phone-plus:before {
  content: '';
}
.fa-phone-slash:before {
  content: '';
}
.fa-phone-square:before {
  content: '';
}
.fa-phone-volume:before {
  content: '';
}
.fa-php:before {
  content: '';
}
.fa-pi:before {
  content: '';
}
.fa-pie:before {
  content: '';
}
.fa-pied-piper:before {
  content: '';
}
.fa-pied-piper-alt:before {
  content: '';
}
.fa-pied-piper-hat:before {
  content: '';
}
.fa-pied-piper-pp:before {
  content: '';
}
.fa-pig:before {
  content: '';
}
.fa-piggy-bank:before {
  content: '';
}
.fa-pills:before {
  content: '';
}
.fa-pinterest:before {
  content: '';
}
.fa-pinterest-p:before {
  content: '';
}
.fa-pinterest-square:before {
  content: '';
}
.fa-pizza:before {
  content: '';
}
.fa-pizza-slice:before {
  content: '';
}
.fa-place-of-worship:before {
  content: '';
}
.fa-plane:before {
  content: '';
}
.fa-plane-alt:before {
  content: '';
}
.fa-plane-arrival:before {
  content: '';
}
.fa-plane-departure:before {
  content: '';
}
.fa-play:before {
  content: '';
}
.fa-play-circle:before {
  content: '';
}
.fa-playstation:before {
  content: '';
}
.fa-plug:before {
  content: '';
}
.fa-plus:before {
  content: '';
}
.fa-plus-circle:before {
  content: '';
}
.fa-plus-hexagon:before {
  content: '';
}
.fa-plus-octagon:before {
  content: '';
}
.fa-plus-square:before {
  content: '';
}
.fa-podcast:before {
  content: '';
}
.fa-podium:before {
  content: '';
}
.fa-podium-star:before {
  content: '';
}
.fa-poll:before {
  content: '';
}
.fa-poll-h:before {
  content: '';
}
.fa-poll-people:before {
  content: '';
}
.fa-poo:before {
  content: '';
}
.fa-poo-storm:before {
  content: '';
}
.fa-poop:before {
  content: '';
}
.fa-popcorn:before {
  content: '';
}
.fa-portrait:before {
  content: '';
}
.fa-pound-sign:before {
  content: '';
}
.fa-power-off:before {
  content: '';
}
.fa-pray:before {
  content: '';
}
.fa-praying-hands:before {
  content: '';
}
.fa-prescription:before {
  content: '';
}
.fa-prescription-bottle:before {
  content: '';
}
.fa-prescription-bottle-alt:before {
  content: '';
}
.fa-presentation:before {
  content: '';
}
.fa-print:before {
  content: '';
}
.fa-print-search:before {
  content: '';
}
.fa-print-slash:before {
  content: '';
}
.fa-procedures:before {
  content: '';
}
.fa-product-hunt:before {
  content: '';
}
.fa-project-diagram:before {
  content: '';
}
.fa-pumpkin:before {
  content: '';
}
.fa-pushed:before {
  content: '';
}
.fa-puzzle-piece:before {
  content: '';
}
.fa-python:before {
  content: '';
}
.fa-qq:before {
  content: '';
}
.fa-qrcode:before {
  content: '';
}
.fa-question:before {
  content: '';
}
.fa-question-circle:before {
  content: '';
}
.fa-question-square:before {
  content: '';
}
.fa-quidditch:before {
  content: '';
}
.fa-quinscape:before {
  content: '';
}
.fa-quora:before {
  content: '';
}
.fa-quote-left:before {
  content: '';
}
.fa-quote-right:before {
  content: '';
}
.fa-quran:before {
  content: '';
}
.fa-r-project:before {
  content: '';
}
.fa-rabbit:before {
  content: '';
}
.fa-rabbit-fast:before {
  content: '';
}
.fa-racquet:before {
  content: '';
}
.fa-radiation:before {
  content: '';
}
.fa-radiation-alt:before {
  content: '';
}
.fa-rainbow:before {
  content: '';
}
.fa-raindrops:before {
  content: '';
}
.fa-ram:before {
  content: '';
}
.fa-ramp-loading:before {
  content: '';
}
.fa-random:before {
  content: '';
}
.fa-raspberry-pi:before {
  content: '';
}
.fa-ravelry:before {
  content: '';
}
.fa-react:before {
  content: '';
}
.fa-reacteurope:before {
  content: '';
}
.fa-readme:before {
  content: '';
}
.fa-rebel:before {
  content: '';
}
.fa-receipt:before {
  content: '';
}
.fa-rectangle-landscape:before {
  content: '';
}
.fa-rectangle-portrait:before {
  content: '';
}
.fa-rectangle-wide:before {
  content: '';
}
.fa-recycle:before {
  content: '';
}
.fa-red-river:before {
  content: '';
}
.fa-reddit:before {
  content: '';
}
.fa-reddit-alien:before {
  content: '';
}
.fa-reddit-square:before {
  content: '';
}
.fa-redhat:before {
  content: '';
}
.fa-redo:before {
  content: '';
}
.fa-redo-alt:before {
  content: '';
}
.fa-registered:before {
  content: '';
}
.fa-renren:before {
  content: '';
}
.fa-repeat:before {
  content: '';
}
.fa-repeat-1:before {
  content: '';
}
.fa-repeat-1-alt:before {
  content: '';
}
.fa-repeat-alt:before {
  content: '';
}
.fa-reply:before {
  content: '';
}
.fa-reply-all:before {
  content: '';
}
.fa-replyd:before {
  content: '';
}
.fa-republican:before {
  content: '';
}
.fa-researchgate:before {
  content: '';
}
.fa-resolving:before {
  content: '';
}
.fa-restroom:before {
  content: '';
}
.fa-retweet:before {
  content: '';
}
.fa-retweet-alt:before {
  content: '';
}
.fa-rev:before {
  content: '';
}
.fa-ribbon:before {
  content: '';
}
.fa-ring:before {
  content: '';
}
.fa-rings-wedding:before {
  content: '';
}
.fa-road:before {
  content: '';
}
.fa-robot:before {
  content: '';
}
.fa-rocket:before {
  content: '';
}
.fa-rocketchat:before {
  content: '';
}
.fa-rockrms:before {
  content: '';
}
.fa-route:before {
  content: '';
}
.fa-route-highway:before {
  content: '';
}
.fa-route-interstate:before {
  content: '';
}
.fa-rss:before {
  content: '';
}
.fa-rss-square:before {
  content: '';
}
.fa-ruble-sign:before {
  content: '';
}
.fa-ruler:before {
  content: '';
}
.fa-ruler-combined:before {
  content: '';
}
.fa-ruler-horizontal:before {
  content: '';
}
.fa-ruler-triangle:before {
  content: '';
}
.fa-ruler-vertical:before {
  content: '';
}
.fa-running:before {
  content: '';
}
.fa-rupee-sign:before {
  content: '';
}
.fa-rv:before {
  content: '';
}
.fa-sack:before {
  content: '';
}
.fa-sack-dollar:before {
  content: '';
}
.fa-sad-cry:before {
  content: '';
}
.fa-sad-tear:before {
  content: '';
}
.fa-safari:before {
  content: '';
}
.fa-salad:before {
  content: '';
}
.fa-salesforce:before {
  content: '';
}
.fa-sandwich:before {
  content: '';
}
.fa-sass:before {
  content: '';
}
.fa-satellite:before {
  content: '';
}
.fa-satellite-dish:before {
  content: '';
}
.fa-sausage:before {
  content: '';
}
.fa-save:before {
  content: '';
}
.fa-scalpel:before {
  content: '';
}
.fa-scalpel-path:before {
  content: '';
}
.fa-scanner:before {
  content: '';
}
.fa-scanner-keyboard:before {
  content: '';
}
.fa-scanner-touchscreen:before {
  content: '';
}
.fa-scarecrow:before {
  content: '';
}
.fa-scarf:before {
  content: '';
}
.fa-schlix:before {
  content: '';
}
.fa-school:before {
  content: '';
}
.fa-screwdriver:before {
  content: '';
}
.fa-scribd:before {
  content: '';
}
.fa-scroll:before {
  content: '';
}
.fa-scroll-old:before {
  content: '';
}
.fa-scrubber:before {
  content: '';
}
.fa-scythe:before {
  content: '';
}
.fa-sd-card:before {
  content: '';
}
.fa-search:before {
  content: '';
}
.fa-search-dollar:before {
  content: '';
}
.fa-search-location:before {
  content: '';
}
.fa-search-minus:before {
  content: '';
}
.fa-search-plus:before {
  content: '';
}
.fa-searchengin:before {
  content: '';
}
.fa-seedling:before {
  content: '';
}
.fa-sellcast:before {
  content: '';
}
.fa-sellsy:before {
  content: '';
}
.fa-server:before {
  content: '';
}
.fa-servicestack:before {
  content: '';
}
.fa-shapes:before {
  content: '';
}
.fa-share:before {
  content: '';
}
.fa-share-all:before {
  content: '';
}
.fa-share-alt:before {
  content: '';
}
.fa-share-alt-square:before {
  content: '';
}
.fa-share-square:before {
  content: '';
}
.fa-sheep:before {
  content: '';
}
.fa-shekel-sign:before {
  content: '';
}
.fa-shield:before {
  content: '';
}
.fa-shield-alt:before {
  content: '';
}
.fa-shield-check:before {
  content: '';
}
.fa-shield-cross:before {
  content: '';
}
.fa-ship:before {
  content: '';
}
.fa-shipping-fast:before {
  content: '';
}
.fa-shipping-timed:before {
  content: '';
}
.fa-shirtsinbulk:before {
  content: '';
}
.fa-shish-kebab:before {
  content: '';
}
.fa-shoe-prints:before {
  content: '';
}
.fa-shopping-bag:before {
  content: '';
}
.fa-shopping-basket:before {
  content: '';
}
.fa-shopping-cart:before {
  content: '';
}
.fa-shopware:before {
  content: '';
}
.fa-shovel:before {
  content: '';
}
.fa-shovel-snow:before {
  content: '';
}
.fa-shower:before {
  content: '';
}
.fa-shredder:before {
  content: '';
}
.fa-shuttle-van:before {
  content: '';
}
.fa-shuttlecock:before {
  content: '';
}
.fa-sickle:before {
  content: '';
}
.fa-sigma:before {
  content: '';
}
.fa-sign:before {
  content: '';
}
.fa-sign-in:before {
  content: '';
}
.fa-sign-in-alt:before {
  content: '';
}
.fa-sign-language:before {
  content: '';
}
.fa-sign-out:before {
  content: '';
}
.fa-sign-out-alt:before {
  content: '';
}
.fa-signal:before {
  content: '';
}
.fa-signal-1:before {
  content: '';
}
.fa-signal-2:before {
  content: '';
}
.fa-signal-3:before {
  content: '';
}
.fa-signal-4:before {
  content: '';
}
.fa-signal-alt:before {
  content: '';
}
.fa-signal-alt-1:before {
  content: '';
}
.fa-signal-alt-2:before {
  content: '';
}
.fa-signal-alt-3:before {
  content: '';
}
.fa-signal-alt-slash:before {
  content: '';
}
.fa-signal-slash:before {
  content: '';
}
.fa-signature:before {
  content: '';
}
.fa-sim-card:before {
  content: '';
}
.fa-simplybuilt:before {
  content: '';
}
.fa-sistrix:before {
  content: '';
}
.fa-sitemap:before {
  content: '';
}
.fa-sith:before {
  content: '';
}
.fa-skating:before {
  content: '';
}
.fa-skeleton:before {
  content: '';
}
.fa-sketch:before {
  content: '';
}
.fa-ski-jump:before {
  content: '';
}
.fa-ski-lift:before {
  content: '';
}
.fa-skiing:before {
  content: '';
}
.fa-skiing-nordic:before {
  content: '';
}
.fa-skull:before {
  content: '';
}
.fa-skull-crossbones:before {
  content: '';
}
.fa-skyatlas:before {
  content: '';
}
.fa-skype:before {
  content: '';
}
.fa-slack:before {
  content: '';
}
.fa-slack-hash:before {
  content: '';
}
.fa-slash:before {
  content: '';
}
.fa-sledding:before {
  content: '';
}
.fa-sleigh:before {
  content: '';
}
.fa-sliders-h:before {
  content: '';
}
.fa-sliders-h-square:before {
  content: '';
}
.fa-sliders-v:before {
  content: '';
}
.fa-sliders-v-square:before {
  content: '';
}
.fa-slideshare:before {
  content: '';
}
.fa-smile:before {
  content: '';
}
.fa-smile-beam:before {
  content: '';
}
.fa-smile-plus:before {
  content: '';
}
.fa-smile-wink:before {
  content: '';
}
.fa-smog:before {
  content: '';
}
.fa-smoke:before {
  content: '';
}
.fa-smoking:before {
  content: '';
}
.fa-smoking-ban:before {
  content: '';
}
.fa-sms:before {
  content: '';
}
.fa-snake:before {
  content: '';
}
.fa-snapchat:before {
  content: '';
}
.fa-snapchat-ghost:before {
  content: '';
}
.fa-snapchat-square:before {
  content: '';
}
.fa-snow-blowing:before {
  content: '';
}
.fa-snowboarding:before {
  content: '';
}
.fa-snowflake:before {
  content: '';
}
.fa-snowflakes:before {
  content: '';
}
.fa-snowman:before {
  content: '';
}
.fa-snowmobile:before {
  content: '';
}
.fa-snowplow:before {
  content: '';
}
.fa-socks:before {
  content: '';
}
.fa-solar-panel:before {
  content: '';
}
.fa-sort:before {
  content: '';
}
.fa-sort-alpha-down:before {
  content: '';
}
.fa-sort-alpha-up:before {
  content: '';
}
.fa-sort-amount-down:before {
  content: '';
}
.fa-sort-amount-up:before {
  content: '';
}
.fa-sort-down:before {
  content: '';
}
.fa-sort-numeric-down:before {
  content: '';
}
.fa-sort-numeric-up:before {
  content: '';
}
.fa-sort-up:before {
  content: '';
}
.fa-soundcloud:before {
  content: '';
}
.fa-soup:before {
  content: '';
}
.fa-sourcetree:before {
  content: '';
}
.fa-spa:before {
  content: '';
}
.fa-space-shuttle:before {
  content: '';
}
.fa-spade:before {
  content: '';
}
.fa-speakap:before {
  content: '';
}
.fa-speaker-deck:before {
  content: '';
}
.fa-spider:before {
  content: '';
}
.fa-spider-black-widow:before {
  content: '';
}
.fa-spider-web:before {
  content: '';
}
.fa-spinner:before {
  content: '';
}
.fa-spinner-third:before {
  content: '';
}
.fa-splotch:before {
  content: '';
}
.fa-spotify:before {
  content: '';
}
.fa-spray-can:before {
  content: '';
}
.fa-square:before {
  content: '';
}
.fa-square-full:before {
  content: '';
}
.fa-square-root:before {
  content: '';
}
.fa-square-root-alt:before {
  content: '';
}
.fa-squarespace:before {
  content: '';
}
.fa-squirrel:before {
  content: '';
}
.fa-stack-exchange:before {
  content: '';
}
.fa-stack-overflow:before {
  content: '';
}
.fa-staff:before {
  content: '';
}
.fa-stamp:before {
  content: '';
}
.fa-star:before {
  content: '';
}
.fa-star-and-crescent:before {
  content: '';
}
.fa-star-christmas:before {
  content: '';
}
.fa-star-exclamation:before {
  content: '';
}
.fa-star-half:before {
  content: '';
}
.fa-star-half-alt:before {
  content: '';
}
.fa-star-of-david:before {
  content: '';
}
.fa-star-of-life:before {
  content: '';
}
.fa-stars:before {
  content: '';
}
.fa-staylinked:before {
  content: '';
}
.fa-steak:before {
  content: '';
}
.fa-steam:before {
  content: '';
}
.fa-steam-square:before {
  content: '';
}
.fa-steam-symbol:before {
  content: '';
}
.fa-steering-wheel:before {
  content: '';
}
.fa-step-backward:before {
  content: '';
}
.fa-step-forward:before {
  content: '';
}
.fa-stethoscope:before {
  content: '';
}
.fa-sticker-mule:before {
  content: '';
}
.fa-sticky-note:before {
  content: '';
}
.fa-stocking:before {
  content: '';
}
.fa-stomach:before {
  content: '';
}
.fa-stop:before {
  content: '';
}
.fa-stop-circle:before {
  content: '';
}
.fa-stopwatch:before {
  content: '';
}
.fa-store:before {
  content: '';
}
.fa-store-alt:before {
  content: '';
}
.fa-strava:before {
  content: '';
}
.fa-stream:before {
  content: '';
}
.fa-street-view:before {
  content: '';
}
.fa-stretcher:before {
  content: '';
}
.fa-strikethrough:before {
  content: '';
}
.fa-stripe:before {
  content: '';
}
.fa-stripe-s:before {
  content: '';
}
.fa-stroopwafel:before {
  content: '';
}
.fa-studiovinari:before {
  content: '';
}
.fa-stumbleupon:before {
  content: '';
}
.fa-stumbleupon-circle:before {
  content: '';
}
.fa-subscript:before {
  content: '';
}
.fa-subway:before {
  content: '';
}
.fa-suitcase:before {
  content: '';
}
.fa-suitcase-rolling:before {
  content: '';
}
.fa-sun:before {
  content: '';
}
.fa-sun-cloud:before {
  content: '';
}
.fa-sun-dust:before {
  content: '';
}
.fa-sun-haze:before {
  content: '';
}
.fa-sunrise:before {
  content: '';
}
.fa-sunset:before {
  content: '';
}
.fa-superpowers:before {
  content: '';
}
.fa-superscript:before {
  content: '';
}
.fa-supple:before {
  content: '';
}
.fa-surprise:before {
  content: '';
}
.fa-suse:before {
  content: '';
}
.fa-swatchbook:before {
  content: '';
}
.fa-swimmer:before {
  content: '';
}
.fa-swimming-pool:before {
  content: '';
}
.fa-sword:before {
  content: '';
}
.fa-swords:before {
  content: '';
}
.fa-symfony:before {
  content: '';
}
.fa-synagogue:before {
  content: '';
}
.fa-sync:before {
  content: '';
}
.fa-sync-alt:before {
  content: '';
}
.fa-syringe:before {
  content: '';
}
.fa-table:before {
  content: '';
}
.fa-table-tennis:before {
  content: '';
}
.fa-tablet:before {
  content: '';
}
.fa-tablet-alt:before {
  content: '';
}
.fa-tablet-android:before {
  content: '';
}
.fa-tablet-android-alt:before {
  content: '';
}
.fa-tablet-rugged:before {
  content: '';
}
.fa-tablets:before {
  content: '';
}
.fa-tachometer:before {
  content: '';
}
.fa-tachometer-alt:before {
  content: '';
}
.fa-tachometer-alt-average:before {
  content: '';
}
.fa-tachometer-alt-fast:before {
  content: '';
}
.fa-tachometer-alt-fastest:before {
  content: '';
}
.fa-tachometer-alt-slow:before {
  content: '';
}
.fa-tachometer-alt-slowest:before {
  content: '';
}
.fa-tachometer-average:before {
  content: '';
}
.fa-tachometer-fast:before {
  content: '';
}
.fa-tachometer-fastest:before {
  content: '';
}
.fa-tachometer-slow:before {
  content: '';
}
.fa-tachometer-slowest:before {
  content: '';
}
.fa-taco:before {
  content: '';
}
.fa-tag:before {
  content: '';
}
.fa-tags:before {
  content: '';
}
.fa-tally:before {
  content: '';
}
.fa-tanakh:before {
  content: '';
}
.fa-tape:before {
  content: '';
}
.fa-tasks:before {
  content: '';
}
.fa-tasks-alt:before {
  content: '';
}
.fa-taxi:before {
  content: '';
}
.fa-teamspeak:before {
  content: '';
}
.fa-teeth:before {
  content: '';
}
.fa-teeth-open:before {
  content: '';
}
.fa-telegram:before {
  content: '';
}
.fa-telegram-plane:before {
  content: '';
}
.fa-temperature-frigid:before {
  content: '';
}
.fa-temperature-high:before {
  content: '';
}
.fa-temperature-hot:before {
  content: '';
}
.fa-temperature-low:before {
  content: '';
}
.fa-tencent-weibo:before {
  content: '';
}
.fa-tenge:before {
  content: '';
}
.fa-tennis-ball:before {
  content: '';
}
.fa-terminal:before {
  content: '';
}
.fa-text-height:before {
  content: '';
}
.fa-text-width:before {
  content: '';
}
.fa-th:before {
  content: '';
}
.fa-th-large:before {
  content: '';
}
.fa-th-list:before {
  content: '';
}
.fa-the-red-yeti:before {
  content: '';
}
.fa-theater-masks:before {
  content: '';
}
.fa-themeco:before {
  content: '';
}
.fa-themeisle:before {
  content: '';
}
.fa-thermometer:before {
  content: '';
}
.fa-thermometer-empty:before {
  content: '';
}
.fa-thermometer-full:before {
  content: '';
}
.fa-thermometer-half:before {
  content: '';
}
.fa-thermometer-quarter:before {
  content: '';
}
.fa-thermometer-three-quarters:before {
  content: '';
}
.fa-theta:before {
  content: '';
}
.fa-think-peaks:before {
  content: '';
}
.fa-thumbs-down:before {
  content: '';
}
.fa-thumbs-up:before {
  content: '';
}
.fa-thumbtack:before {
  content: '';
}
.fa-thunderstorm:before {
  content: '';
}
.fa-thunderstorm-moon:before {
  content: '';
}
.fa-thunderstorm-sun:before {
  content: '';
}
.fa-ticket:before {
  content: '';
}
.fa-ticket-alt:before {
  content: '';
}
.fa-tilde:before {
  content: '';
}
.fa-times:before {
  content: '';
}
.fa-times-circle:before {
  content: '';
}
.fa-times-hexagon:before {
  content: '';
}
.fa-times-octagon:before {
  content: '';
}
.fa-times-square:before {
  content: '';
}
.fa-tint:before {
  content: '';
}
.fa-tint-slash:before {
  content: '';
}
.fa-tire:before {
  content: '';
}
.fa-tire-flat:before {
  content: '';
}
.fa-tire-pressure-warning:before {
  content: '';
}
.fa-tire-rugged:before {
  content: '';
}
.fa-tired:before {
  content: '';
}
.fa-toggle-off:before {
  content: '';
}
.fa-toggle-on:before {
  content: '';
}
.fa-toilet:before {
  content: '';
}
.fa-toilet-paper:before {
  content: '';
}
.fa-toilet-paper-alt:before {
  content: '';
}
.fa-tombstone:before {
  content: '';
}
.fa-tombstone-alt:before {
  content: '';
}
.fa-toolbox:before {
  content: '';
}
.fa-tools:before {
  content: '';
}
.fa-tooth:before {
  content: '';
}
.fa-toothbrush:before {
  content: '';
}
.fa-torah:before {
  content: '';
}
.fa-torii-gate:before {
  content: '';
}
.fa-tornado:before {
  content: '';
}
.fa-tractor:before {
  content: '';
}
.fa-trade-federation:before {
  content: '';
}
.fa-trademark:before {
  content: '';
}
.fa-traffic-cone:before {
  content: '';
}
.fa-traffic-light:before {
  content: '';
}
.fa-traffic-light-go:before {
  content: '';
}
.fa-traffic-light-slow:before {
  content: '';
}
.fa-traffic-light-stop:before {
  content: '';
}
.fa-train:before {
  content: '';
}
.fa-tram:before {
  content: '';
}
.fa-transgender:before {
  content: '';
}
.fa-transgender-alt:before {
  content: '';
}
.fa-trash:before {
  content: '';
}
.fa-trash-alt:before {
  content: '';
}
.fa-trash-restore:before {
  content: '';
}
.fa-trash-restore-alt:before {
  content: '';
}
.fa-treasure-chest:before {
  content: '';
}
.fa-tree:before {
  content: '';
}
.fa-tree-alt:before {
  content: '';
}
.fa-tree-christmas:before {
  content: '';
}
.fa-tree-decorated:before {
  content: '';
}
.fa-tree-large:before {
  content: '';
}
.fa-tree-palm:before {
  content: '';
}
.fa-trees:before {
  content: '';
}
.fa-trello:before {
  content: '';
}
.fa-triangle:before {
  content: '';
}
.fa-tripadvisor:before {
  content: '';
}
.fa-trophy:before {
  content: '';
}
.fa-trophy-alt:before {
  content: '';
}
.fa-truck:before {
  content: '';
}
.fa-truck-container:before {
  content: '';
}
.fa-truck-couch:before {
  content: '';
}
.fa-truck-loading:before {
  content: '';
}
.fa-truck-monster:before {
  content: '';
}
.fa-truck-moving:before {
  content: '';
}
.fa-truck-pickup:before {
  content: '';
}
.fa-truck-plow:before {
  content: '';
}
.fa-truck-ramp:before {
  content: '';
}
.fa-tshirt:before {
  content: '';
}
.fa-tty:before {
  content: '';
}
.fa-tumblr:before {
  content: '';
}
.fa-tumblr-square:before {
  content: '';
}
.fa-turkey:before {
  content: '';
}
.fa-turtle:before {
  content: '';
}
.fa-tv:before {
  content: '';
}
.fa-tv-retro:before {
  content: '';
}
.fa-twitch:before {
  content: '';
}
.fa-twitter:before {
  content: '';
}
.fa-twitter-square:before {
  content: '';
}
.fa-typo3:before {
  content: '';
}
.fa-uber:before {
  content: '';
}
.fa-ubuntu:before {
  content: '';
}
.fa-uikit:before {
  content: '';
}
.fa-umbrella:before {
  content: '';
}
.fa-umbrella-beach:before {
  content: '';
}
.fa-underline:before {
  content: '';
}
.fa-undo:before {
  content: '';
}
.fa-undo-alt:before {
  content: '';
}
.fa-unicorn:before {
  content: '';
}
.fa-union:before {
  content: '';
}
.fa-uniregistry:before {
  content: '';
}
.fa-universal-access:before {
  content: '';
}
.fa-university:before {
  content: '';
}
.fa-unlink:before {
  content: '';
}
.fa-unlock:before {
  content: '';
}
.fa-unlock-alt:before {
  content: '';
}
.fa-untappd:before {
  content: '';
}
.fa-upload:before {
  content: '';
}
.fa-ups:before {
  content: '';
}
.fa-usb:before {
  content: '';
}
.fa-usd-circle:before {
  content: '';
}
.fa-usd-square:before {
  content: '';
}
.fa-user:before {
  content: '';
}
.fa-user-alt:before {
  content: '';
}
.fa-user-alt-slash:before {
  content: '';
}
.fa-user-astronaut:before {
  content: '';
}
.fa-user-chart:before {
  content: '';
}
.fa-user-check:before {
  content: '';
}
.fa-user-circle:before {
  content: '';
}
.fa-user-clock:before {
  content: '';
}
.fa-user-cog:before {
  content: '';
}
.fa-user-crown:before {
  content: '';
}
.fa-user-edit:before {
  content: '';
}
.fa-user-friends:before {
  content: '';
}
.fa-user-graduate:before {
  content: '';
}
.fa-user-hard-hat:before {
  content: '';
}
.fa-user-headset:before {
  content: '';
}
.fa-user-injured:before {
  content: '';
}
.fa-user-lock:before {
  content: '';
}
.fa-user-md:before {
  content: '';
}
.fa-user-md-chat:before {
  content: '';
}
.fa-user-minus:before {
  content: '';
}
.fa-user-ninja:before {
  content: '';
}
.fa-user-nurse:before {
  content: '';
}
.fa-user-plus:before {
  content: '';
}
.fa-user-secret:before {
  content: '';
}
.fa-user-shield:before {
  content: '';
}
.fa-user-slash:before {
  content: '';
}
.fa-user-tag:before {
  content: '';
}
.fa-user-tie:before {
  content: '';
}
.fa-user-times:before {
  content: '';
}
.fa-users:before {
  content: '';
}
.fa-users-class:before {
  content: '';
}
.fa-users-cog:before {
  content: '';
}
.fa-users-crown:before {
  content: '';
}
.fa-users-medical:before {
  content: '';
}
.fa-usps:before {
  content: '';
}
.fa-ussunnah:before {
  content: '';
}
.fa-utensil-fork:before {
  content: '';
}
.fa-utensil-knife:before {
  content: '';
}
.fa-utensil-spoon:before {
  content: '';
}
.fa-utensils:before {
  content: '';
}
.fa-utensils-alt:before {
  content: '';
}
.fa-vaadin:before {
  content: '';
}
.fa-value-absolute:before {
  content: '';
}
.fa-vector-square:before {
  content: '';
}
.fa-venus:before {
  content: '';
}
.fa-venus-double:before {
  content: '';
}
.fa-venus-mars:before {
  content: '';
}
.fa-viacoin:before {
  content: '';
}
.fa-viadeo:before {
  content: '';
}
.fa-viadeo-square:before {
  content: '';
}
.fa-vial:before {
  content: '';
}
.fa-vials:before {
  content: '';
}
.fa-viber:before {
  content: '';
}
.fa-video:before {
  content: '';
}
.fa-video-plus:before {
  content: '';
}
.fa-video-slash:before {
  content: '';
}
.fa-vihara:before {
  content: '';
}
.fa-vimeo:before {
  content: '';
}
.fa-vimeo-square:before {
  content: '';
}
.fa-vimeo-v:before {
  content: '';
}
.fa-vine:before {
  content: '';
}
.fa-vk:before {
  content: '';
}
.fa-vnv:before {
  content: '';
}
.fa-volcano:before {
  content: '';
}
.fa-volleyball-ball:before {
  content: '';
}
.fa-volume:before {
  content: '';
}
.fa-volume-down:before {
  content: '';
}
.fa-volume-mute:before {
  content: '';
}
.fa-volume-off:before {
  content: '';
}
.fa-volume-slash:before {
  content: '';
}
.fa-volume-up:before {
  content: '';
}
.fa-vote-nay:before {
  content: '';
}
.fa-vote-yea:before {
  content: '';
}
.fa-vr-cardboard:before {
  content: '';
}
.fa-vuejs:before {
  content: '';
}
.fa-walker:before {
  content: '';
}
.fa-walking:before {
  content: '';
}
.fa-wallet:before {
  content: '';
}
.fa-wand:before {
  content: '';
}
.fa-wand-magic:before {
  content: '';
}
.fa-warehouse:before {
  content: '';
}
.fa-warehouse-alt:before {
  content: '';
}
.fa-watch:before {
  content: '';
}
.fa-watch-fitness:before {
  content: '';
}
.fa-water:before {
  content: '';
}
.fa-water-lower:before {
  content: '';
}
.fa-water-rise:before {
  content: '';
}
.fa-wave-square:before {
  content: '';
}
.fa-waze:before {
  content: '';
}
.fa-webcam:before {
  content: '';
}
.fa-webcam-slash:before {
  content: '';
}
.fa-weebly:before {
  content: '';
}
.fa-weibo:before {
  content: '';
}
.fa-weight:before {
  content: '';
}
.fa-weight-hanging:before {
  content: '';
}
.fa-weixin:before {
  content: '';
}
.fa-whale:before {
  content: '';
}
.fa-whatsapp:before {
  content: '';
}
.fa-whatsapp-square:before {
  content: '';
}
.fa-wheat:before {
  content: '';
}
.fa-wheelchair:before {
  content: '';
}
.fa-whistle:before {
  content: '';
}
.fa-whmcs:before {
  content: '';
}
.fa-wifi:before {
  content: '';
}
.fa-wifi-1:before {
  content: '';
}
.fa-wifi-2:before {
  content: '';
}
.fa-wifi-slash:before {
  content: '';
}
.fa-wikipedia-w:before {
  content: '';
}
.fa-wind:before {
  content: '';
}
.fa-wind-warning:before {
  content: '';
}
.fa-window:before {
  content: '';
}
.fa-window-alt:before {
  content: '';
}
.fa-window-close:before {
  content: '';
}
.fa-window-maximize:before {
  content: '';
}
.fa-window-minimize:before {
  content: '';
}
.fa-window-restore:before {
  content: '';
}
.fa-windows:before {
  content: '';
}
.fa-windsock:before {
  content: '';
}
.fa-wine-bottle:before {
  content: '';
}
.fa-wine-glass:before {
  content: '';
}
.fa-wine-glass-alt:before {
  content: '';
}
.fa-wix:before {
  content: '';
}
.fa-wizards-of-the-coast:before {
  content: '';
}
.fa-wolf-pack-battalion:before {
  content: '';
}
.fa-won-sign:before {
  content: '';
}
.fa-wordpress:before {
  content: '';
}
.fa-wordpress-simple:before {
  content: '';
}
.fa-wpbeginner:before {
  content: '';
}
.fa-wpexplorer:before {
  content: '';
}
.fa-wpforms:before {
  content: '';
}
.fa-wpressr:before {
  content: '';
}
.fa-wreath:before {
  content: '';
}
.fa-wrench:before {
  content: '';
}
.fa-x-ray:before {
  content: '';
}
.fa-xbox:before {
  content: '';
}
.fa-xing:before {
  content: '';
}
.fa-xing-square:before {
  content: '';
}
.fa-y-combinator:before {
  content: '';
}
.fa-yahoo:before {
  content: '';
}
.fa-yammer:before {
  content: '';
}
.fa-yandex:before {
  content: '';
}
.fa-yandex-international:before {
  content: '';
}
.fa-yarn:before {
  content: '';
}
.fa-yelp:before {
  content: '';
}
.fa-yen-sign:before {
  content: '';
}
.fa-yin-yang:before {
  content: '';
}
.fa-yoast:before {
  content: '';
}
.fa-youtube:before {
  content: '';
}
.fa-youtube-square:before {
  content: '';
}
.fa-zhihu:before {
  content: '';
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/* @font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url('fa-pro/fa-brands-400.eot');
  src: url('fa-pro/fa-brands-400.eot?#iefix') format('embedded-opentype'),
    url('fa-pro/fa-brands-400.woff2') format('woff2'), url('fa-pro/fa-brands-400.woff') format('woff'),
    url('fa-pro/fa-brands-400.ttf') format('truetype'), url('fa-pro/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('fa-pro/fa-regular-400.eot');
  src: url('fa-pro/fa-regular-400.eot?#iefix') format('embedded-opentype'),
    url('fa-pro/fa-regular-400.woff2') format('woff2'), url('fa-pro/fa-regular-400.woff') format('woff'),
    url('fa-pro/fa-regular-400.ttf') format('truetype'), url('fa-pro/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('fa-pro/fa-solid-900.eot');
  src: url('fa-pro/fa-solid-900.eot?#iefix') format('embedded-opentype'),
    url('fa-pro/fa-solid-900.woff2') format('woff2'), url('fa-pro/fa-solid-900.woff') format('woff'),
    url('fa-pro/fa-solid-900.ttf') format('truetype'), url('fa-pro/fa-solid-900.svg#fontawesome') format('svg');
} */
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
.ui-dialog.share-embedded-dialog,
.ui-dialog.myAccountDialog,
.ui-dialog.myMosaicDialog {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
}
.ui-dialog.myAccountDialog label,
.ui-dialog.myMosaicDialog label,
.ui-dialog.myMosaicDialog .label {
  display: block;
  min-width: 150px;
  font-size: 14px;
  margin: 5px 0;
}
.ui-dialog.myAccountDialog label {
  padding-left: 5px;
}
.ui-dialog.myMosaicDialog .label {
  margin: 0;
}
.ui-dialog.myMosaicDialog .label.mytheme-title {
  display: inline-block;
  width: 20%;
}
.ui-dialog.myMosaicDialog .label.mytheme-subtitle {
  font-size: 12px;
  font-weight: 400;
  float: right;
  display: inline-block;
  text-align: right;
}
.ui-dialog-titlebar-close {
  background-color: #fff !important;
  background-image: none;
}
.ui-icon-closethick {
  background: none !important;
  text-indent: 0 !important;
  text-indent: initial !important;
  width: 30px;
  height: 30px;
}
.ui-icon-closethick:before {
  content: '';
  font-family: 'Font Awesome 5 Pro';
  font-weight: 100;
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 30px;
  height: 30px;
  color: #000;
  font-size: 21px;
}
.ui-dialog .ui-button {
  border: none;
  background: transparent;
}
.ui-dialog.myAccountDialog .control--disabled {
  border: none;
  background-color: #fff;
}
.ui-dialog.myAccountDialog button[disabled='disabled'] {
  color: #999;
}
#myMosaicFreeTextDialog .cke_top {
  background: #fff;
  border-bottom: none;
}
#myMosaicFreeTextDialog .cke_bottom {
  display: none;
}
.sp-container {
  border-radius: 3px;
  background: #fff;
  border: none;
  box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: -1px 3px 8px 2px rgba(0, 0, 0, 0.07);
}
.sp-palette-container {
  padding-left: 13px;
  padding-top: 13px;
  padding-right: 13px;
}
div.myThemesEditDialog button.ui-button {
  width: 100px;
  height: 40px;
  border: none;
  font-size: 10px;
  margin-top: 5px;
}
div.myThemesEditDialog button.img-btn .underline {
  text-decoration: underline;
}
div.myThemesEditDialog button.img-btn .fa {
  background-color: #ccc !important;
  border: 1px solid #666 !important;
  color: #000 !important;
  padding: 3px 4px;
}
div.imageDialog .image-menu {
  padding-top: 5px;
  margin-top: 10px;
  height: 350px;
  overflow-y: scroll;
}
div.imageDialog .image-menu .image-preview-list {
  padding: 10px;
}
.mytheme-section.half {
  width: 50%;
  float: left;
}
.mytheme-section.half.right {
  float: right;
}
.mytheme-title {
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
}
#mytheme-name {
  margin-bottom: 0;
}
#mytheme-image {
  width: 100%;
  padding: 0;
  border: 1px solid #ddd;
}
#myMosaicImg {
  float: left;
  padding: 0 5px;
}
#mytheme-image .far,
.input-btn-combo button {
  position: absolute;
  right: 1px;
  top: 50%;
  transform: translateY(-50%);
}
#mytheme-description {
  min-height: 100px;
  resize: vertical;
}
.ui-dialog .ui-dialog-buttonpane {
  text-align: left;
  border: none;
  background-image: none;
  margin-top: 0;
  padding: 20px 0 0 0;
}
.ui-dialog .ui-dialog-buttonpane button {
  margin: 0 0 0 5px;
  cursor: pointer;
  background: #8e24aa;
  text-transform: inherit;
  box-shadow: none;
}
.ui-dialog .ui-dialog-buttonpane button:last-child:not(:last-child) {
  background: #f5f5f5;
  color: #1a237e;
}
.ui-button-text-only .ui-button-text {
  font-size: 12px;
}
#popup {
  z-index: 10;
}
#popup h3:before {
  display: none;
}
#popup h3 {
  padding-top: 10px;
  margin-bottom: 20px;
}
.ui-dialog .ui-dialog-buttonpane button,
.sp-container.mosaic-color-picker button,
.sp-container.mosaic-color-picker a.sp-cancel {
  margin: 0;
  margin: initial;
  cursor: pointer;
  height: auto;
  padding: 12px 15px;
  border: none;
  margin-left: 10px;
  background: #005a96;
  color: #fff;
  cursor: pointer;
  background-color: #005a96;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  box-sizing: border-box;
  text-shadow: none;
}
.sp-container.mosaic-color-picker a.sp-cancel {
  color: #fff !important;
  font-family: 'Source Sans Pro';
  display: inline-block;
}
.sp-container.mosaic-color-picker .sp-palette-toggle {
  margin-top: 13px;
}
.ui-dialog .ui-dialog-buttonpane button.cancel {
  background: #f5f5f5;
  color: #000;
}
.ui-button-text-only .ui-button-text {
  padding: 0;
  padding: initial;
}
.ui-dialog.myMosaicDialog .ui-dialog-titlebar-close {
  display: none;
}
.ui-dialog-buttonset button {
  min-width: 100px;
  height: 40px;
  border: none;
  font-size: 12px;
}
.error {
  color: red;
}
#editor {
  resize: vertical;
  overflow: auto;
  border: 1px solid silver;
  border-radius: 5px;
  min-height: 250px;
  box-shadow: inset 0 0 10px silver;
  padding: 1em;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}
.ui-dialog.myMosaicDialog label.radio-btn {
  display: inline-block;
  min-width: 0;
  min-width: initial;
}
.size-section {
  margin-bottom: 0;
}
.size-section input[type='radio'] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.size-section input[type='radio'] + img {
  cursor: pointer;
  outline: 1px solid transparent;
  width: 90%;
}
.size-section input[type='radio']:checked + img {
  outline: 1px solid gray;
}
#myMosaicFreeTextDialog .sp-container.background-color {
  margin-left: -130px;
  left: auto !important;
}
.myThemesEditDialog fieldset {
  border: 0 none;
  padding: 0;
}
body.swing-stories div.no-crumb .trail-title {
  font-weight: 600;
}
body.swing-stories section.tile h2,
body.swing-stories section.tile h3.tile-header {
  font-weight: 600;
}
body.swing-stories .free-tile.title {
  margin: 27px 0 10px 0;
}
body.swing-stories .free-tile.title h2 {
  font-size: 24px;
  line-height: 28px;
}
body.swing-stories section.tile.Infographics.height2 h3.tile-header.no-subtitle {
  max-height: 44px;
}
body.swing-stories section.tile.height2 .free-tile h3.tile-header.no-content {
  max-height: 104px;
}
body.swing-stories section.tile.height1 header.tile-small h3.tile-header.no-subtitle {
  max-height: 60px;
}
body.swing-stories section.tile.Infographics.height2 h3.tile-header {
  max-height: 36px;
}
body.swing-stories.screenshot #dashboardheader,
body.swing-stories.screenshot #footer,
body.swing-stories.screenshot .o-adds {
  display: none !important;
}
a.next-theme-btn {
  display: inline-block;
  padding: 10px 10px 10px 20px;
  line-height: 20px;
  box-shadow: 2px 4px 6px 0 #e5e5e5;
}
a.next-theme-btn:hover {
  text-decoration: none;
}
a.next-theme-btn:hover i {
  margin-right: 5px;
  margin-left: 15px;
  transition: all 0.3s ease;
}
a.next-theme-btn i {
  margin: 0 10px 0 10px;
  vertical-align: middle;
  line-height: 20px;
  transition: all 0.3s ease-out;
}

.App_App__16ZpL {
  max-width:1500px;
  margin: 0 auto;
  /* padding: 0 12px; */
  background: #f0f0f0
}

.App_AppContainer__381b2{
  padding: 12px;
  max-width:1280px;
  margin: 0 auto 180px;
}

.App_bottomBar__2fmt_{
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  margin: 0 auto;
  max-width: 1500px;
}

.App_AppContainer__381b2{
  padding: 12px;
  max-width:1000px;
  margin: 0 auto;
}


.Header_main__3KT-Y{
  padding: 6px 0;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: right;
  align-items: center;

  width: 100%;
   /* height: 64px ; */
   background: white ;
   color: white;
}

.Header_main__3KT-Y img{
  padding: 0 12px;
}

.Dashboard_inputContainer__1bnhh {
  padding: 24px;
}

.Dashboard_bedrijf__9-Hh3 {
  padding: 12px;
  border: 1px solid lightgrey;
  margin: 24px;
  border-radius: 3px;
  background-color: white;
}

.Dashboard_content__3SavW{
  display: flex;
  margin:   20px;
}

.Dashboard_content__3SavW > div{
  width: 33.33%;
  padding: 24px;
  margin: 5px;
  background: white;
  border: 1px solid lightgrey;
}

.Dashboard_colHeader__1n5WJ{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.Dashboard_regels__iItSz{
  /* padding: 24px 0; */
}

.Dashboard_disclaimer__1MgQN{
  font-size: 0.9em;
  opacity: 0.7;
  line-height: 1.1em;
  padding: 18px 0 0;
  color: #de0000;
  font-style: italic;
  line-height: 1.2em;
}

.Dashboard_logo__3xYvA{
  height: 40px;
  float:right;
  padding: 0 8px 0 16px;
}

.Dashboard_button__27Not{
  background: rgb(239, 239, 239);
  border: 1px solid lightgray;
  padding: 3px 10px;
  border-radius: 5px;
  margin: -5px;
  cursor: pointer;
}

.Dashboard_regelHeader__2CsHN{
  /* padding: 0 12px */
}
.Dashboard_categoryContainer__w0Qkh{
  padding: 24px 0;
}

.Dashboard_searchTypeContainer__3BTjS{
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.Dashboard_searchTypeContainer__3BTjS > *{
  padding: 0 10px;
}

label{
  margin-bottom: 0 !important;
  margin-top: 3px;
}

.Dashboard_source__1RyTu{
  text-align: right;
  font-size: 0.85em;
  padding: 12px;
}

.Dashboard_logos__1HL0F{
  padding: 6px 0;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */

  display: flex;
  justify-content: right;
  align-items: center;

  width: 100%;
   /* height: 64px ; */
   /* background: white ; */
   /* color: white; */
}

.Dashboard_logos__1HL0F img{
  padding: 0 12px;
}

.Category_line__3bolf{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: rgb(237, 237, 237); */
  margin: 4px 0;
  /* padding: 12px; */
  border: 1px solid rgba(0,0,0,0);

}

.Category_title__3TavT{
  font-weight: 500;
  /* opacity: 0.7; */
  font-size: 1.2em;
  padding: 12px 0;
}

.Category_line__3bolf:hover {
  /* background-color: rgb(230, 230, 230); */
  border-bottom: 1px solid rgba(230, 230, 230, 0.5);
  border-top: 1px solid rgba(230, 230, 230, 0.5);
}

/* .line:hover .title {
  opacity: 1;
} */

.Category_regelContainer__rcgIc{
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.Category_regelContainer__rcgIc > div{
  /* width: 50%; */
}
.Regel_main__vmIs7{
  padding: 18px 0;
}

.Regel_header__1F1vz{
  font-size: 1em;
  font-weight: 600;
  /* padding: 6px; */
}

.Regel_content__1zYJp{
  padding: 6px 0
}
.SearchAutocomplete_main__1N-sy{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /* padding: 8px; */
  /* position: relative; */
}

.SearchAutocomplete_main__1N-sy input{
  border-radius: 4px;
  min-width: 280px;
  padding-right: 32px;
}

.SearchAutocomplete_inputContainer__1qQZU{
  display: flex;
  align-items: center;
  position: relative;
}

.SearchAutocomplete_inputContainer__1qQZU img{
  position: absolute;
  right: 9px;
  width: 22px;
  margin-left: 12px;
}

.SearchAutocomplete_items__xlNJH{
  position: absolute;
  /* left: 0; */
  background-color: white;
  /* padding: 6px 0; */
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  z-index: 100;
  max-height: 400px;
  overflow-y: auto;
  margin: 44px 0 4px 0;
  box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.2);
  min-width: 232px;
}

.SearchAutocomplete_item__3Jinc{
  padding: 4px 9px;
  cursor: pointer;
}

.SearchAutocomplete_selected__2a3Ci{
  /* color: #007bff; */
  background-color: rgb(233, 233, 233);
}

.SearchAutocomplete_item__3Jinc:hover{
  /* text-decoration: underline; */
  /* color: #007bff; */
  background-color: rgb(233, 233, 233);
}

.SearchAutocomplete_noResults__3YEjY{
  position: absolute;
  background-color: white;
  /* padding: 6px 0; */
  border: 1px solid #d8d8d8;
  border-radius: 7px;
  z-index: 10;
  max-height: 400px;
  overflow-y: auto;
  margin-top: 44px;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.2);
  min-width: 232px;
  padding: 4px 9px;
  color: lightgrey;
  font-size: 0.9em;
  font-style: italic;
}

.Search_input__3_v_j {
  display: flex;
}

.Search_input__3_v_j input {
  width: 200px;
  margin-right: 12px;
}

.Search_error__1RoTq {
  font-size: 0.9em;
  opacity: 0.5;
  font-style: italic;
}

.Button_main__mMsqi {
  background-color: #aa418c;
  box-shadow: 0 4px 6px hsla(0, 0%, 74.5%, 0.2), 0 1px 3px hsla(0, 0%, 74.5%, 0.2);
  border-radius: 0.25rem;
  border: 0.0625rem solid #aa418c;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1rem;
  min-width: 5rem;
  padding: 0.7rem 1.5rem;
  text-decoration: none;
  transition: all 0.15s ease;
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

/* .main h2{
  line-height: 3em !important;
} */
