.main {
  background-color: #aa418c;
  box-shadow: 0 4px 6px hsla(0, 0%, 74.5%, 0.2), 0 1px 3px hsla(0, 0%, 74.5%, 0.2);
  border-radius: 0.25rem;
  border: 0.0625rem solid #aa418c;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  line-height: 1rem;
  min-width: 5rem;
  padding: 0.7rem 1.5rem;
  text-decoration: none;
  transition: all 0.15s ease;
  white-space: nowrap;
  -webkit-user-select: none;
  user-select: none;
}
