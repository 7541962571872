.line{
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* background-color: rgb(237, 237, 237); */
  margin: 4px 0;
  /* padding: 12px; */
  border: 1px solid rgba(0,0,0,0);

}

.title{
  font-weight: 500;
  /* opacity: 0.7; */
  font-size: 1.2em;
  padding: 12px 0;
}

.line:hover {
  /* background-color: rgb(230, 230, 230); */
  border-bottom: 1px solid rgba(230, 230, 230, 0.5);
  border-top: 1px solid rgba(230, 230, 230, 0.5);
}

/* .line:hover .title {
  opacity: 1;
} */

.regelContainer{
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
}

.regelContainer > div{
  /* width: 50%; */
}