.App {
  max-width:1500px;
  margin: 0 auto;
  /* padding: 0 12px; */
  background: #f0f0f0
}

.AppContainer{
  padding: 12px;
  max-width:1280px;
  margin: 0 auto 180px;
}

.bottomBar{
  position: fixed;
  bottom: 0;
  left:0;
  right:0;
  margin: 0 auto;
  max-width: 1500px;
}

.AppContainer{
  padding: 12px;
  max-width:1000px;
  margin: 0 auto;
}

