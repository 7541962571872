.inputContainer {
  padding: 24px;
}

.bedrijf {
  padding: 12px;
  border: 1px solid lightgrey;
  margin: 24px;
  border-radius: 3px;
  background-color: white;
}

.content{
  display: flex;
  margin:   20px;
}

.content > div{
  width: 33.33%;
  padding: 24px;
  margin: 5px;
  background: white;
  border: 1px solid lightgrey;
}

.colHeader{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.regels{
  /* padding: 24px 0; */
}

.disclaimer{
  font-size: 0.9em;
  opacity: 0.7;
  line-height: 1.1em;
  padding: 18px 0 0;
  color: #de0000;
  font-style: italic;
  line-height: 1.2em;
}

.logo{
  height: 40px;
  float:right;
  padding: 0 8px 0 16px;
}

.button{
  background: rgb(239, 239, 239);
  border: 1px solid lightgray;
  padding: 3px 10px;
  border-radius: 5px;
  margin: -5px;
  cursor: pointer;
}

.regelHeader{
  /* padding: 0 12px */
}
.categoryContainer{
  padding: 24px 0;
}

.searchTypeContainer{
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.searchTypeContainer > *{
  padding: 0 10px;
}

label{
  margin-bottom: 0 !important;
  margin-top: 3px;
}

.source{
  text-align: right;
  font-size: 0.85em;
  padding: 12px;
}

.logos{
  padding: 6px 0;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */

  display: flex;
  justify-content: right;
  align-items: center;

  width: 100%;
   /* height: 64px ; */
   /* background: white ; */
   /* color: white; */
}

.logos img{
  padding: 0 12px;
}
