.main{
  padding: 18px 0;
}

.header{
  font-size: 1em;
  font-weight: 600;
  /* padding: 6px; */
}

.content{
  padding: 6px 0
}