.main{
  padding: 6px 0;
  /* box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); */
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: right;
  align-items: center;

  width: 100%;
   /* height: 64px ; */
   background: white ;
   color: white;
}

.main img{
  padding: 0 12px;
}
